import './Documents.scss';
import { Container, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { useConnectedPage, useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { useDispatch, useSelector } from '@/libs/redux';
import { useCallback, useEffect, useState } from 'react';
import { findDocumentsPublic } from '@/stores/document';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import { Document, FileSlotPeriodicity, hDocument, hUser } from '@/models';
import { useTranslation } from 'react-i18next';
import CrudDocumentFilters from '@/components/crud/document/Filters/Filters';
import { model } from '@/libs/candy';
import IconVisibility from '@mui/icons-material/Visibility';
import { toDate } from '@/libs/utils';

export interface BaseDocumentsItemProps {
	item: Document;
}

export function BaseDocumentsItem({ item }: BaseDocumentsItemProps) {
	const { t } = useTranslation();
	const token = useSelector(state => state.token.token);
	const document = hDocument(item);
	const User = hUser(useSelector(state => state.user.me));
	const userSyndicats = useSelector((state) => User.syndicats);
	// Check if the file belongs to the user's syndicate
	const isUserSyndicatFile = userSyndicats.includes(item.syndicat);

	//Solution temporaire pour faire fonctionner les téléchargements de fichiers
	const baseHref = document.file.media.getHref({ token });
	const parts = baseHref.split('/');
	parts.pop();
	const newId = document.id;
	const newUrl = token
		? `${parts.join('/')}/${newId}?_token=${token.id}`
		: null;

	return (
		<TableRow>
			<TableCell style={{ width: '230px' }}>
				{t(`models.user_syndicat.values.${item.syndicat}`)}
			</TableCell>
			<TableCell style={{ maxWidth: '100px' }}>
				{t(`models.document_type_type.values.${item.documentType.type}`)}
			</TableCell>
			<TableCell style={{ maxWidth: '250px' }}>
				{item.documentType.name}
			</TableCell>
			<TableCell style={{ maxWidth: '120px' }}>
				{item.periodicity === FileSlotPeriodicity.ANNUAL && (
					<>{item.year}</>
				)}
				{item.periodicity === FileSlotPeriodicity.QUARTERLY && (
					<>Trimestre {item.quarter} ({item.year})</>
				)}
			</TableCell>
			<TableCell style={{ width: '130px' }}>
				{toDate(new Date(item.createdAt))}
			</TableCell>
			<TableCell>
				{isUserSyndicatFile ? (
					<a target="_blank" href={newUrl || undefined}>{document.fileName}</a>
				) : (
					<Tooltip title="Vous ne pouvez pas voir/télécharger les documents qui ne sont pas de votre syndicat">
						<span>{"Document non autorisé"}</span>
					</Tooltip>
				)}
			</TableCell>
			<TableCell style={{ width: '50px' }}>
				{document.isPDF && isUserSyndicatFile && (
					<IconButton
						color="primary"
						target="_blank"
						href={newUrl + '&view=1'}
					>
						<IconVisibility />
					</IconButton>
				)}
			</TableCell>
		</TableRow>
	);
}

export default function BaseDocuments() {

	useConnectedPage();

	const User = hUser(useSelector(state => state.user.me));
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const documents = useSelector(state => state.document.documents);
	const pageable = usePageable();

	const [filters, setFilters] = useState<any>({
		syndicat: User?.roles.includes('ROLE_SYNDIC', 'ROLE_ADMIN') ? User.syndicats.flat() : [],
		year: (new Date()).getFullYear(),
		documentTypeType: [],
		documentType: [],
	});

	const refresh = useCallback(async () => {
		try {
			await dispatch(findDocumentsPublic(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur est survenue.', 'error');
		}
	}, [filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage])

	useEffectOnChange(() => {
		refresh();
	}, [refresh]);

	return (
		<Container className="p-base-Documents">

			<CrudDocumentFilters
				{...model(filters, setFilters)}
			/>

			<SortableTable
				heads={[
					{ name: 'Syndicat', sort: 'syndicat' },
					{ name: 'Type' },
					{ name: 'Document', sort: 'documentType', style: { maxWidth: '250px' } },
					{ name: 'Période' },
					{ name: 'Date de dépôt', sort: 'createdAt' },
					{ name: 'Fichier' },
					{ name: 'Action' },
				]}
				result={documents}
				renderItem={(item, i) => (
					<BaseDocumentsItem item={item} />
				)}
			/>
		</Container>
	);
}

BaseDocuments.getMetadata = () => {
	return {
		rights: ['BASE_DOWNLOAD'],
		title: 'Documents stockés'
	}
}

