import React, { useState, useCallback } from 'react';
import { Card, CardContent, CardActions, CardHeader, TableCell, TableRow, Button, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from '@/libs/redux';
import { usePageable, useSnackMessage, useEffectOnChange } from '@/libs/hooks';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import { model } from '@/libs/candy';
import { findReferents } from '@/stores/referent';
import { Referent } from '@/models';
import CrudAnnuaireAccompagnementSocialFilters from '@/components/crud/accompagnementSocial/Filters/Filters';

export interface CrudReferentSelectProps {
  value: Referent[];
  onChange: (v: Referent[]) => any;
  onClose: () => any;
  onSave: () => void;
}

export function CrudReferentSelect({ value, onChange, onClose, onSave }: CrudReferentSelectProps) {
  const dispatch = useDispatch();
  const pushMessage = useSnackMessage();
  const pageable = usePageable();
  const referents = useSelector(state => state.referent.referents);

  const [filters, setFilters] = useState({
    id: '',
    nom: '',
    libre1: '', // Email
    libre2: '', // Téléphone
  });

  const refresh = useCallback(() => {
    (async () => {
      try {
        await dispatch(findReferents(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
      } catch (e) {
        console.error(e);
        pushMessage('Une erreur est survenue.', 'error');
      }
    })();
  }, [filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage]);

  useEffectOnChange(() => {
    refresh();
  }, [refresh]);

  return (
    <Card className="c-Crud-TriLot-Select" style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw', zIndex: 9999, display: 'flex', flexDirection: 'column' }}>
      <CardHeader className="py-1 mb-1" title="Sélectionnez un référent" titleTypographyProps={{ className: 'u-t-size-19' }} />
      <CardContent style={{ flexGrow: 1, padding: 0, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flexShrink: 0 }}>
          <CrudAnnuaireAccompagnementSocialFilters
            {...model(filters, setFilters)}
          />
        </div>

        <div style={{ flexGrow: 1, overflowY: 'auto' }}>
          <SortableTable
            style={{ width: '100%' }}
            heads={[
              { name: 'Id', sort: 'id' },
              { name: 'Nom', sort: 'nom' },
              { name: 'Email', sort: 'libre1' },
              { name: 'Téléphone', sort: 'libre2' },
              { name: 'Actions' },
            ]}
            result={referents}
            renderItem={(item: Referent) => (
              <CrudReferentSelectItem
                value={value}
                onChange={onChange}
                item={item}
              />
            )}
            pageable={pageable}
          />
        </div>
      </CardContent>
      <CardActions style={{ justifyContent: 'space-between' }}>
        <Button onClick={onClose}>Fermer</Button>
        <Button
          onClick={onSave}
          disabled={!value || value.length === 0} // Désactive le bouton si aucun référent n'est sélectionné
        >
          Sauvegarder
        </Button>
      </CardActions>
    </Card>
  );
}

export interface CrudReferentSelectItemProps {
  item: Referent;
  value: Referent[];
  onChange: (v: Referent[]) => any;
}

export function CrudReferentSelectItem({ item, value, onChange }: CrudReferentSelectItemProps) {
  const selected = value.map(r => r.id).includes(item.id);

  return (
    <TableRow>
      <TableCell>{item.id}</TableCell>
      <TableCell>{item.nom}</TableCell>
      <TableCell>{item.libre1}</TableCell>
      <TableCell>{item.libre2}</TableCell>
      <TableCell>
        <Checkbox
          checked={selected}
          onChange={(e) => {
            if (e.target.checked) {
              onChange([item]); // Sélection unique
            } else {
              onChange([]); // Désélectionne tout
            }
          }}
        />
      </TableCell>
    </TableRow>
  );
}
