import { AnyAction, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { Caller } from '@/libs/caller';
import snackMessage, { SnackMessageState } from '@/stores/snackMessage';
import page, { PageState } from '@/stores/page';
import user, { UserState } from '@/stores/user';
import token, { TokenState } from '@/stores/token';
import loader, { LoaderState } from '@/stores/loader';
import documentType, { DocumentTypeState } from '@/stores/documentType';
import fileSlot, { FileSlotState } from '@/stores/fileSlot';
import slotList, { SlotListState } from '@/stores/slotList';
import relanceSlotList, { RelanceSlotListState } from '@/stores/relanceSlotList';
import upload, { UploadState } from '@/stores/upload';
import document, { DocumentState } from '@/stores/document';
import recouvrement, { RecouvrementState } from '@/stores/recouvrement';
import recouvrementRecord, { RecouvrementRecordState } from '@/stores/recouvrementRecord';
import graphFiche, { GraphFicheState } from '@/stores/graphFiche';
import annuaireLogement, { AnnuaireLogementState } from '@/stores/annuaireLogement';
import synoptique, { SynoptiqueState } from '@/stores/synoptique';
import colorscheme, { ColorSchemeState } from '@/stores/colorscheme';
import annuaireProprietaire, { AnnuaireProprietaireState } from '@/stores/annuaireProprietaire';
import etatMaj, { EtatMajState } from '@/stores/etatMaj';
import iframe, { IframeState } from '@/stores/iframe';
import logModeration, { LogModerationState } from '@/stores/logModeration';
import feedback, { FeedbackState } from '@/stores/feedback';
import lot, { LotState } from '@/stores/lot';
import typeLot, { TypeLotState } from '@/stores/typeLot';
import acquisition, { AcquisitionState } from '@/stores/acquisition';
import referent, { ReferentState } from '@/stores/referent';
import typeProprietaire, { TypeProprietaireState } from '@/stores/typeProprietaire';
import email, { EmailState } from '@/stores/email';
import adresse, { AdresseState } from '@/stores/adresse';
import telephone, { TelephoneState } from '@/stores/telephone';
import individu, { IndividuState } from '@/stores/individu';
import proprietaire, { ProprietaireState } from '@/stores/proprietaire';
import accompagnementSocial, { AccompagnementSocialState } from '@/stores/accompagnementSocial';
import triAcqui, { TriAcquiState } from '@/stores/triAcqui';
import triEvalSociale, { TriEvalSocialeState } from '@/stores/triEvalSociale';
import triLot, { TriLotState } from '@/stores/triLot';
import acquisitionVisite, { AcquisitionVisiteState } from '@/stores/acquisitionVisite';
import acquisitionDia, { AcquisitionDiaState } from '@/stores/acquisitionDia';
import triDia, { TriDiaState } from '@/stores/triDia';
import contact from '@/stores/contact';
import { Contact } from '@/models/Contact';
import actionSociale from './actionSociale';
import { ActionSociale } from '@/models';

// LIST SUB STORES
const stores = {
	page,
	snackMessage,
	user,
	documentType,
	token,
	loader,
	fileSlot,
	slotList,
	relanceSlotList,
	upload,
	document,
	recouvrement,
	recouvrementRecord,
	graphFiche,
	annuaireLogement,
	synoptique,
	annuaireProprietaire,
	iframe,
	etatMaj,
	logModeration,
	feedback,
	lot,
	typeLot,
	acquisition,
	referent,
	typeProprietaire,
	email,
	adresse,
	telephone,
	proprietaire,
	individu,
	accompagnementSocial,
	triAcqui,
	triEvalSociale,
	triLot,
	colorscheme,
	acquisitionVisite,
	acquisitionDia,
	triDia,
	contact,
	actionSociale,
};

interface SubStates {
	page: PageState;
	snackMessage: SnackMessageState;
	user: UserState;
	documentType: DocumentTypeState;
	token: TokenState;
	loader: LoaderState;
	fileSlot: FileSlotState;
	slotList: SlotListState;
	relanceSlotList: RelanceSlotListState;
	upload: UploadState;
	document: DocumentState;
	recouvrement: RecouvrementState;
	recouvrementRecord: RecouvrementRecordState;
	graphFiche: GraphFicheState;
	annuaireLogement: AnnuaireLogementState;
	synoptique: SynoptiqueState;
	colorscheme: ColorSchemeState;
	annuaireProprietaire: AnnuaireProprietaireState;
	iframe: IframeState;
	etatMaj: EtatMajState;
	logModeration: LogModerationState;
	feedback: FeedbackState;
	lot: LotState;
	typeLot: TypeLotState;
	acquisition: AcquisitionState;
	referent: ReferentState;
	typeProprietaire: TypeProprietaireState,
	email: EmailState,
	adresse: AdresseState,
	telephone: TelephoneState,
	proprietaire: ProprietaireState,
	individu: IndividuState,
	accompagnementSocial: AccompagnementSocialState,
	triAcqui: TriAcquiState,
	triEvalSociale: TriEvalSocialeState,
	triLot: TriLotState,
	acquisitionVisite: AcquisitionVisiteState,
	acquisitionVisites: AcquisitionVisiteState,
	acquisitionDia: AcquisitionDiaState,
	triDia: TriDiaState,
	contact: Contact,
	actionSociale: ActionSociale,
}

// STATE GLOBAL
class IndexState {
	public constructor() {
		for (const [name, subState] of Object.entries(stores)) {
			(this as any)[name] = new (subState.state)();
		}
	}
	public init: boolean = false
	public beforeFirstLoad: boolean = false;
	public firstLoad: boolean = false;
	public firstRender: boolean = false;
}

export type State = IndexState & SubStates;
export type GetState = () => State;
export type Services = {
	caller: Caller
};

export function createState(): State {
	return new IndexState() as any;
}

// ACTION TYPE GLOBAL
enum Action {
	INIT = 'INDEX_INIT',
	BEFORE_FIRST_LOAD = 'BEFORE_FIRST_LOAD',
	FIRST_LOAD = 'INDEX_FIRST_LOAD',
	FIRST_RENDER = 'INDEX_FIRST_RENDER',
}

// REDUCERS GLOBAL
export const reducer = (state: State = createState(), action: AnyAction) => {

	if (action.type === Action.INIT) {
		state = { ...state, init: true };
	} else if (action.type === Action.BEFORE_FIRST_LOAD) {
		state = { ...state, beforeFirstLoad: true };
	} else if (action.type === Action.FIRST_LOAD) {
		state = { ...state, firstLoad: true };
	} else if (action.type === Action.FIRST_RENDER) {
		state = { ...state, firstRender: true };
	} else {
		for (const [name, store] of Object.entries(stores)) {
			if (action.state === store.state) {
				delete action.state;
				state = {
					...state,
					[name]: store.reducer((state as any)[name], action)
				} as any;
				break;
			}
		}
	}

	return state;
}

let caller: Nullable<Caller> = null;
export const store = configureStore({
	reducer,
	middleware: getDefaultMiddleware({
		thunk: {
			extraArgument: {
				get caller(): Caller {
					if (!caller) {
						caller = new Caller(store);
					}
					return caller as Caller;
				}
			},
		},
		serializableCheck: false,
		immutableCheck: false,
	}),
});


// ACTIONS

export const initialSide = () => async (dispatch: Dispatch, getState: GetState, services: Services) => {
	if (!getState().init) {
		dispatch({ type: Action.INIT });
		await Promise.all(
			Object.values(stores).map(async store => {
				if (store.initialSide) {
					return await store.initialSide(dispatch, getState, services);
				}
			})
		);
	}
}

export const firstLoad = () => async (dispatch: Dispatch, getState: GetState, services: Services) => {
	dispatch({ type: Action.BEFORE_FIRST_LOAD });
	await Promise.all(
		Object.values(stores).map(async store => {
			if (store.onFirstLoad) {
				return await store.onFirstLoad(dispatch, getState, services);
			}
		})
	);
	return dispatch({ type: Action.FIRST_LOAD });
}

export const firstRender = () => async (dispatch: Dispatch, getState: GetState, services: Services) => {
	await Promise.all(
		Object.values(stores).map(async store => {
			if (store.onFirstRender) {
				return await store.onFirstRender(dispatch, getState, services);
			}
		})
	);
	dispatch({ type: Action.FIRST_RENDER });
}
