import React, { useState } from 'react';
import { Card, CardActions, CardContent, CardHeader, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { HActionSociale, Referent, ActionSociale } from '@/models';
import { useForm } from '@/libs/form';
import { useDispatch } from '@/libs/redux';
import { LoadingButton } from '@mui/lab';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import TextField from '@/components/inputs/TextField/TextField';
import { postActionSociale, putActionSociale } from '@/stores/actionSociale';
import { useValidators } from '@/libs/validators';
import DatePicker from '@/components/inputs/DatePicker/DatePicker';

export interface CrudActionSocialeEditProps {
	referent: Referent;
	actionSociale: HActionSociale;
	onSave: (actionSociale: ActionSociale) => any;
	onClose: () => any;
}

export function CrudActionSocialeEdit({
	referent,
	actionSociale,
	onSave,
	onClose,
}: CrudActionSocialeEditProps) {
	const dispatch = useDispatch();
	const validators = useValidators();

	const [typeAction, setTypeAction] = useState<Nullable<string>>(actionSociale?.typeAction || '');
	const [dateDepot, setDateDepot] = useState<Nullable<Date>>(actionSociale?.dateDepot ? new Date(actionSociale.dateDepot) : null);
	const [dateAccord, setDateAccord] = useState<Nullable<Date>>(actionSociale?.dateAccord ? new Date(actionSociale.dateAccord) : null);
	const [montant, setMontant] = useState<Nullable<number>>(actionSociale?.montant || null);
	const [demarches, setDemarches] = useState<Nullable<string>>(actionSociale?.demarches || '');
	const [synthese, setSynthese] = useState<Nullable<string>>(actionSociale?.synthese || '');

	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				const newActionSociale = {
					...(actionSociale || {}),
					typeAction,
					dateDepot: dateDepot ? dateDepot.toISOString() : null,
					dateAccord: dateAccord ? dateAccord.toISOString() : null,
					montant: montant ? parseFloat(montant.toString()) : null,
					demarches,
					synthese,
				};
				if (newActionSociale.id) {
					const result = await dispatch(putActionSociale(newActionSociale));
					onSave(result);
				} else {
					const result = await dispatch(postActionSociale(referent, newActionSociale));
					onSave(result);
				}
			});
		},
		fields: {
			typeAction: {
				...model(typeAction, setTypeAction),
				label: 'Type d\'action',
				rules: [validators.required()],
			},
			dateDepot: {
				...model(dateDepot, setDateDepot),
				label: 'Date de dépôt',
				rules: [validators.required()],
			},
			dateAccord: {
				...model(dateAccord, setDateAccord),
				label: 'Date d\'accord',
			},
			montant: {
				...model(montant, setMontant),
				label: 'Montant',
				type: 'number',
			},
			demarches: {
				...model(demarches, setDemarches),
				label: 'Démarches',
			},
			synthese: {
				...model(synthese, setSynthese),
				label: 'Synthèse',
			},
		},
	});

	return (
		<Form form={form}>
			<Card>
				<CardHeader
					title="Édition de la mesure engagée"
					className="CardHeader--primary"
				/>
				<CardContent>
					<FormMainErrors form={form} />
					<Grid container spacing={1}>
						{/* Type d'action */}
						<Grid xs={12} md={6}>
							<FormControl fullWidth>
								<InputLabel id="type-action-label">Type d'action</InputLabel>
								<Select
									labelId="type-action-label"
									value={typeAction}
									onChange={(e) => setTypeAction(e.target.value as string)}
									label="Type d'action"
								>
									<MenuItem value="Aide financière">Aide financière</MenuItem>
									<MenuItem value="Soutien psychologique">Soutien psychologique</MenuItem>
									<MenuItem value="Orientation professionnelle">Orientation professionnelle</MenuItem>
									{/* Ajoutez d'autres types si nécessaire */}
								</Select>
							</FormControl>
						</Grid>

						{/* Date de dépôt */}
						<Grid xs={12} md={6}>
							<DatePicker {...form.fields.dateDepot} />
						</Grid>

						{/* Date d'accord */}
						<Grid xs={12} md={6}>
							<DatePicker {...form.fields.dateAccord} />
						</Grid>

						{/* Montant */}
						<Grid xs={12} md={6}>
							<TextField type="number" {...form.fields.montant} />
						</Grid>

						{/* Démarches */}
						<Grid xs={12}>
							<TextField
								multiline
								rows={4}
								{...form.fields.demarches}
							/>
						</Grid>

						{/* Synthèse */}
						<Grid xs={12}>
							<TextField
								multiline
								rows={4}
								{...form.fields.synthese}
							/>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions className="u-d-block">
					<LoadingButton
						onClick={onClose}
						loading={form.loading}
					>
						Fermer
					</LoadingButton>
					<LoadingButton
						type="submit"
						color="primary"
						variant="contained"
						loading={form.loading}
						style={{ float: 'right' }}
					>
						Valider
					</LoadingButton>
				</CardActions>
			</Card>
		</Form>
	);
}
