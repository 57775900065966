import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { DocumentType, FileSlotPeriodicity, Upload, UserSyncicat } from '@/models';
import { GetState, Services } from '@/stores/index';
import { dateToDash, UriHelper, Uuid } from '@/libs/utils';

const getChunkFile = (file: File, offset: number, length: number): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(<string>reader.result);
		reader.onerror = () => reject(reader.error);
		reader.readAsBinaryString(file.slice(offset, offset + length));
	});
};

export class UploadState {
	finishs: { [uuid: string]: Promise<Upload> } = {};
	progresses: { [uuid: string]: number } = {};
}

export default {
	state: UploadState,
	reducer: mapReducer(['finishs', 'progresses']),
} as SubStore;

// MUTATIONS

export const setFinish = (upload: Upload, finish: Promise<Upload>) => (dispatch: DispatchApp, getState: GetState) => {
	const finishs: any = { ...getState().upload.finishs };
	finishs[upload.uuid] = finish;
	dispatch({ state: UploadState, type: MapReducerAction.MAP, finishs });
};

export const setProgress = (upload: Upload, progress: number) => (dispatch: DispatchApp, getState: GetState) => {
	const progresses: any = { ...getState().upload.progresses };
	progresses[upload.uuid] = progress;
	dispatch({ state: UploadState, type: MapReducerAction.MAP, progresses });
};

export const clearProgress = (upload: Upload) => (dispatch: DispatchApp, getState: GetState) => {
	const progresses: any = { ...getState().upload.progresses };
	delete progresses[upload.uuid];
	dispatch({ state: UploadState, type: MapReducerAction.MAP, progresses });
};

// ACTIONS

export const waitFinish =
	(upload: Upload) =>
	async (dispatch: DispatchApp, getState: GetState): Promise<Upload> => {
		return await getState().upload.finishs[upload.uuid];
	};

const fileToUpload =
	(file: File, callbackSend: (upload: Upload) => Promise<Upload>, callbackEnd: (upload: Upload) => any = () => {}, callbackProgress: (progress: number, upload: Upload) => any = () => {}, fileName: Nullable<string> = null) =>
	(dispatch: DispatchApp): Upload => {
		let upload = {
			uuid: Uuid.generate(),
			originalFileName: fileName || file.name,
		} as Upload;

		const finish = (async (): Promise<Upload> => {
			try {
				const chunkSize = 1024 * 300; // 300ko (base 64 ajoute 33% de taille)
				let offset = 0;

				callbackProgress(0, upload);
				dispatch(setProgress(upload, 0));

				while (!upload.endOfFile) {
					upload.endOfFile = chunkSize > file.size - offset;

					upload.data = btoa(await getChunkFile(file, offset, chunkSize));
					offset += chunkSize;
					upload = await callbackSend(upload);

					const progress = file.size ? offset / file.size : 0;
					callbackProgress(progress, upload);
					dispatch(setProgress(upload, progress));
				}

				callbackProgress(1, upload);
				callbackEnd(upload);

				setTimeout(() => {
					dispatch(clearProgress(upload));
				}, 2000);
			} catch (e) {
				setTimeout(() => {
					dispatch(clearProgress(upload));
				}, 2000);
				throw e;
			}
			return upload;
		})();

		dispatch(setFinish(upload, finish));
		return upload;
	};

export const postDocumentYear =
	(file: File, syndicat: UserSyncicat, periodicity: FileSlotPeriodicity, document: DocumentType, year: number, customFields: any, onProgress: (progress: number) => any) =>
	async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Upload> => {
		return await dispatch(
			fileToUpload(
				file,
				(upload) =>
					caller.post<Upload>(
						`/api/uploads/documents/year/${syndicat}/${periodicity}/${document.id}/${year}${UriHelper.queries({
							customFields: JSON.stringify(customFields),
						})}`,
						upload
					),
				(upload) => {
					console.log('Upload terminé');
				},
				onProgress
			)
		);
	};

export const postDocumentBetween =
	(file: File, syndicat: UserSyncicat, periodicity: FileSlotPeriodicity, document: DocumentType, start: Date, end: Date, customFields: any, onProgress: (progress: number) => any) =>
	async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Upload> => {
		return await dispatch(
			fileToUpload(
				file,
				(upload) =>
					caller.post<Upload>(
						`/api/uploads/documents/between/${syndicat}/${periodicity}/${document.id}/${dateToDash(start)}/${dateToDash(end)}${UriHelper.queries({
							customFields: JSON.stringify(customFields),
						})}`,
						upload
					),
				(upload) => {
					console.log('Upload terminé');
				},
				onProgress
			)
		);
	};

export const postDocumentDate =
	(file: File, syndicat: UserSyncicat, periodicity: FileSlotPeriodicity, document: DocumentType, date: Date, customFields: any, onProgress: (progress: number) => any) =>
	async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Upload> => {
		return await dispatch(
			fileToUpload(
				file,
				(upload) =>
					caller.post<Upload>(
						`/api/uploads/documents/date/${syndicat}/${periodicity}/${document.id}/${dateToDash(date)}${UriHelper.queries({
							customFields: JSON.stringify(customFields),
						})}`,
						upload
					),
				(upload) => {
					console.log('Upload terminé');
				},
				onProgress
			)
		);
	};

// Action spécifique pour l'upload de la documentation
export const postDocumentation =
	(file: File, document: DocumentType, onProgress: (progress: number) => any) =>
	async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Upload> => {
		return await dispatch(
			fileToUpload(
				file,
				(upload) => caller.post<Upload>(`/api/uploads/documents/documentation/${document.id}`, upload),
				(upload) => {
					console.log('Upload documentation terminé');
				},
				onProgress
			)
		);
	};
