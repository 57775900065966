import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { ActionSociale, Referent } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';

export class ActionSocialeState {
    // Vous pouvez ajouter ici des propriétés d'état si nécessaire
}

export default {
    state: ActionSocialeState,
    reducer: mapReducer([]),
} as SubStore;

// ACTIONS

export const putActionSociale = (
    actionSociale: Partial<ActionSociale>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ActionSociale> => {
    return await caller.put<ActionSociale>(`/api/action-sociales/${actionSociale.id}`, {
        referentId: actionSociale.referentId,
        typeAction: actionSociale.typeAction,
        dateDepot: actionSociale.dateDepot,
        dateAccord: actionSociale.dateAccord,
        montant: actionSociale.montant,
        demarches: actionSociale.demarches,
        synthese: actionSociale.synthese,
    });
});

export const postActionSociale = (
    referent: Referent | string, // Accepte soit un objet Referent soit un ID
    actionSociale: Partial<ActionSociale>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ActionSociale> => {
    let referentId: string;

    if (typeof referent === 'string') {
        referentId = referent;
    } else if (typeof referent === 'object' && referent !== null) {
        if (!referent.id) {
            console.error('Referent missing id:', referent);
            throw new Error('Le référent n\'a pas de propriété id.');
        }
        referentId = referent.id;
    } else {
        console.error('Invalid referent:', referent);
        throw new Error('Référent invalide. Attendu un objet ou une chaîne de caractères.');
    }

    if (!referentId) {
        console.error('Referent object:', referent);
        throw new Error('referentId est indéfini. Veuillez vérifier l\'objet référent.');
    }

    return await caller.post<ActionSociale>(`/api/action-sociales/${encodeURIComponent(referentId)}`, {
        typeAction: actionSociale.typeAction,
        dateDepot: actionSociale.dateDepot,
        dateAccord: actionSociale.dateAccord,
        montant: actionSociale.montant,
        demarches: actionSociale.demarches,
        synthese: actionSociale.synthese,
    });
});

export const deleteActionSociale = (
    actionSociale: ActionSociale,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ActionSociale> => {
    return await caller.delete<ActionSociale>(`/api/action-sociales/${actionSociale.id}`);
});
