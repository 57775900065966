import { Card, CardActions, CardContent, CardHeader, Select, SelectChangeEvent, InputLabel, MenuItem, FormControl, Dialog } from '@mui/material';
import { Acquisition } from '@/models';
import { AcquisitionDia, HAcquisitionDia } from '@/models/AcquisitionDia';
import { useState, useEffect } from 'react';
import { useForm } from '@/libs/form';
import { useDispatch, useSelector } from '@/libs/redux';
import { LoadingButton } from '@mui/lab';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useValidators } from '@/libs/validators';
import { postAcquisitionDia, putAcquisitionDia } from '@/stores/acquisitionDia';
import { findTriDia } from '@/stores/triDia'; // Import de l'action pour récupérer triDia
import TextField from '@/components/inputs/TextField/TextField';
import DatePicker from '@/components/inputs/DatePicker/DatePicker';

export interface CrudAcquisitionDiaEditProps {
	acquisition: Acquisition,
	diaAcqui: HAcquisitionDia,
	onSave: (dia: AcquisitionDia) => any;
	onClose: () => any;
}

export function CrudAcquisitionDiaEdit({
	acquisition,
	diaAcqui,
	onSave,
	onClose,
}: CrudAcquisitionDiaEditProps) {

	const dispatch = useDispatch();
	const validators = useValidators();
	const [date, setDate] = useState<Nullable<Date>>(diaAcqui?.date || null);
	const [surfaceCarrez, setSurfaceCarrez] = useState<Nullable<number>>(diaAcqui?.surfaceCarrez || null);
	// const [prixm2, setPrixm2] = useState<Nullable<number>>(diaAcqui?.prixm2 || null);
	const [prixPreemption, setPrixPreemption] = useState<Nullable<number>>(diaAcqui?.prixPreemption || null);
	const [dateDemandeUniqueVisite, setDateDemandeUniqueVisite] = useState<Nullable<Date>>(diaAcqui?.dateDemandeUniqueVisite || null);
	const [dateDemandeDocs, setDateDemandeDocs] = useState<Nullable<Date>>(diaAcqui?.dateDemandeDocs || null);
	const [dateReceptionDocs, setDateReceptionDocs] = useState<Nullable<Date>>(diaAcqui?.dateReceptionDocs || null);
	const [dateSaisineNotaire, setDateSaisineNotaire] = useState<Nullable<Date>>(diaAcqui?.dateSaisineNotaire || null);
	const [montantCommissionAgence, setMontantCommissionAgence] = useState<Nullable<number>>(diaAcqui?.montantCommissionAgence || null);
	const [chargeCommission, setChargeCommission] = useState<Nullable<string>>(diaAcqui?.chargeCommission || null);
	const [nomAcquereur, setNomAcquereur] = useState<Nullable<string>>(diaAcqui?.nomAcquereur || null);
	const [montant, setMontant] = useState<Nullable<number>>(diaAcqui?.montant || null);
	const [decisionPreemptionEpf, setDecisionPreemptionEpf] = useState<Nullable<string>>(diaAcqui?.decisionPreemptionEpf || '');
	const [decisionPreemptionEpfAdju, setDecisionPreemptionEpfAdju] = useState<Nullable<string>>(diaAcqui?.decisionPreemptionEpfAdju || '');
	const [dateCourrierPreemption, setDateCourrierPreemption] = useState<Nullable<Date>>(diaAcqui?.dateCourrierPreemption || null);
	const [retourProprietaire, setRetourProprietaire] = useState<Nullable<string>>(diaAcqui?.retourProprietaire || '');
	const [acquisitionId, setAcquisitionId] = useState<Nullable<number>>(acquisition.id || null);
	const acquiID = acquisition.id;

	// Récupération de triDia depuis le store
	const triDiaState = useSelector((state) => state.triDia);
	const triDia = triDiaState.triDia;

	// Utilisation de useEffect pour récupérer les données de triDia
	useEffect(() => {
		if (diaAcqui?.id) {
			dispatch(findTriDia(diaAcqui.id));
		}
	}, [dispatch, diaAcqui?.id]);

	// Accès à montantm2 depuis triDia
	const montantm2 = triDia?.montantm2;
	const prixPreemptionm2 = triDia?.prixPreemptionm2;

	// Fonction pour sauvegarder prixm2 lors du onBlur
	// async function savePrixm2() {
	// 	const newAcquisitionDia = {
	// 		id: diaAcqui.id,
	// 		acquisitionId: acquisition.id,
	// 		prixm2: prixm2 ? parseFloat(prixm2.toString()) : null,
	// 	};

	// 	try {
	// 		if (newAcquisitionDia.id) {
	// 			await dispatch(putAcquisitionDia(newAcquisitionDia));
	// 		} else {
	// 			await dispatch(postAcquisitionDia(acquisition, newAcquisitionDia));
	// 		}
	// 		// Après la sauvegarde, rafraîchir triDia pour obtenir montantm2 mis à jour
	// 		dispatch(findTriDia(diaAcqui.id));
	// 	} catch (error) {
	// 		console.error('Échec de la sauvegarde du prix au m² :', error);
	// 	}
	// }

	// // Gestionnaire d'événement pour le onBlur du champ prixm2
	// function handlePrixm2Blur() {
	// 	savePrixm2();
	// }

	// Nouvelle fonction pour sauvegarder montant lors du onBlur
	async function saveMontant() {
		const newAcquisitionDia = {
			id: diaAcqui.id,
			acquisitionId: acquisition.id,
			montant: montant ? parseFloat(montant.toString()) : null,
			surfaceCarrez: surfaceCarrez ? parseFloat(surfaceCarrez.toString()) : null,
			prixPreemption: prixPreemption ? parseFloat(prixPreemption.toString()) : null,
		};

		try {
			if (newAcquisitionDia.id) {
				await dispatch(putAcquisitionDia(newAcquisitionDia));
			} else {
				await dispatch(postAcquisitionDia(acquisition, newAcquisitionDia));
			}
			// Après la sauvegarde, rafraîchir triDia pour obtenir montantm2 mis à jour
			dispatch(findTriDia(diaAcqui.id));
		} catch (error) {
			console.error('Échec de la sauvegarde du montant :', error);
		}
	}

	// Gestionnaire d'événement pour le onBlur du champ montant
	function handleMontantBlur() {
		saveMontant();
	}

	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				// Ne pas fermer la page après la sauvegarde
				const newAcquisitionDia = {
					...diaAcqui,
					date,
					surfaceCarrez: surfaceCarrez ? parseFloat(surfaceCarrez.toString()) : null,
					// prixm2: prixm2 ? parseFloat(prixm2.toString()) : null,
					dateDemandeUniqueVisite,
					dateDemandeDocs,
					dateReceptionDocs,
					dateSaisineNotaire,
					montantCommissionAgence: montantCommissionAgence ? parseFloat(montantCommissionAgence.toString()) : null,
					chargeCommission,
					nomAcquereur,
					montant: montant ? parseFloat(montant.toString()) : null,
					decisionPreemptionEpf,
					// decisionPreemptionEpfAdju,
					dateCourrierPreemption,
					retourProprietaire,
					acquisitionId,
					prixPreemption: prixPreemption ? parseFloat(prixPreemption.toString()) : null,
				};
				if (newAcquisitionDia.id) {
					const result = await dispatch(putAcquisitionDia(newAcquisitionDia));
					onSave(result);
				} else {
					const result = await dispatch(postAcquisitionDia(acquisition, newAcquisitionDia));
					onSave(result);
				}
				// Après la sauvegarde, rafraîchir triDia
				dispatch(findTriDia(diaAcqui.id));
			});
		},
		fields: {
			date: {
				label: 'Date DIA',
				...model(date, setDate),
				rules: [
					validators.required(),
				],
				type: 'date',
			},
			surfaceCarrez: {
				label: 'Surface Carrez préemption',
				...model(surfaceCarrez, setSurfaceCarrez),
				type: 'number',
				onBlur: handleMontantBlur,
				inputProps: {
					endAdornment: 'm²'
				},
			},
			// prixm2: {
			// 	label: 'Prix préemption m² saisie',
			// 	...model(prixm2, setPrixm2),
			// 	type: 'number',
			// 	inputProps: {
			// 		endAdornment: '€/m²'
			// 	},
			// 	onBlur: handlePrixm2Blur,
			// },
			prixPreemption: {
				label: 'Prix préemption',
				...model(prixPreemption, setPrixPreemption),
				type: 'number',
				onBlur: handleMontantBlur,
				inputProps: {
					endAdornment: '€'
				},
			},
			dateDemandeUniqueVisite: {
				label: 'Date d\'envoi de la demande unique visite',
				...model(dateDemandeUniqueVisite, setDateDemandeUniqueVisite),
				type: 'date',
			},
			dateDemandeDocs: {
				label: 'Date d\'envoi de la demande de doc à l\'EPFIF',
				...model(dateDemandeDocs, setDateDemandeDocs),
				type: 'date',
			},
			dateReceptionDocs: {
				label: 'Date de réception de doc',
				...model(dateReceptionDocs, setDateReceptionDocs),
				type: 'date',
			},
			dateSaisineNotaire: {
				...model(dateSaisineNotaire, setDateSaisineNotaire),
				label: 'Date saisine notaire',
				type: 'date',
			},
			montantCommissionAgence: {
				label: 'Montant Commission Agence',
				...model(montantCommissionAgence, setMontantCommissionAgence),
				type: 'number',
				inputProps: {
					endAdornment: '€'
				},
			},
			chargeCommission: {
				label: 'Commission à la charge de',
				...model(chargeCommission, setChargeCommission),
			},
			nomAcquereur: {
				label: 'Nom de l\'acquéreur',
				...model(nomAcquereur, setNomAcquereur),
			},
			montant: {
				label: 'Montant',
				...model(montant, setMontant),
				type: 'number',
				inputProps: {
					endAdornment: '€'
				},
				onBlur: handleMontantBlur,
			},
			decisionPreemptionEpf: {
				...model(decisionPreemptionEpf, setDecisionPreemptionEpf),
				label: 'Décision de préempter',
				items: ['', 'Au prix', 'Offre', 'Renonciation'],
			},
			// decisionPreemptionEpfAdju: {
			// 	...model(decisionPreemptionEpfAdju, setDecisionPreemptionEpfAdju),
			// 	label: 'Décision de préemption EPFIF',
			// 	items: ['', 'Oui', 'Non'],
			// },
			dateCourrierPreemption: {
				label: 'Date d\'envoi du courrier de préemption / Renonciation',
				...model(dateCourrierPreemption, setDateCourrierPreemption),
				type: 'date',
			},
			retourProprietaire: {
				label: 'Retour Propriétaire',
				...model(retourProprietaire, setRetourProprietaire),
				items: ['', 'Renonciation à vendre', 'Accord', 'Fixation judiciaire'],
			},
			acquisitionId: {
				label: 'ID de l\'acquisition',
				...model(acquisitionId, setAcquisitionId),
				value: acquiID,
				hidden: true,
			},
		}
	});

	return (
		<Dialog open={true} onClose={onClose} maxWidth={'xl'} fullWidth >
			<Form form={form}>
				<Card style={{ height: 'auto', width: '100%', overflow: 'auto' }}>
					<CardHeader
						title={diaAcqui?.id ? "Édition de la DIA" : "Création d'une DIA"}
						className="CardHeader--primary"
					/>
					<CardContent>
						<FormMainErrors form={form} />
						<Grid container spacing={2}>
							<Grid xs={12}><strong>Réception DIA</strong></Grid>
							<Grid xs={12} md={3}><DatePicker {...form.fields.date} /></Grid>
							<Grid xs={12} md={9}></Grid>
							<Grid xs={12} md={3}><TextField {...form.fields.surfaceCarrez} /></Grid>
							<Grid xs={12} md={3}>
								<TextField
									{...form.fields.montant}
									onBlur={handleMontantBlur}
								/>
							</Grid>

							{/* Affichage du montantm2 récupéré depuis triDia */}
							<Grid xs={12} md={3}>
								<TextField
									label="Montant m²"
									value={montantm2 || ''}
									InputLabelProps={{
										shrink: true,
									}}
									readOnly
									inputProps={{
										endAdornment: '€/m²',
									}}
									fullWidth
								/>
							</Grid>
							{/* Champ prixm2 avec onBlur */}
							{/* <Grid xs={12} md={3}>
								<TextField
									{...form.fields.prixm2}
									onBlur={handlePrixm2Blur}
								/>
							</Grid> */}

							{/* Autres champs */}
							<Grid xs={12} md={3}><DatePicker {...form.fields.dateDemandeUniqueVisite} /></Grid>
							<Grid xs={12} md={3}><DatePicker {...form.fields.dateDemandeDocs} /></Grid>
							<Grid xs={12} md={3}><DatePicker {...form.fields.dateReceptionDocs} /></Grid>
							<Grid xs={12} md={3}><TextField {...form.fields.montantCommissionAgence} /></Grid>
							<Grid xs={12} md={3}>
								<FormControl fullWidth>
									<InputLabel>Commission à la charge de</InputLabel>
									<Select
										{...form.fields.chargeCommission}
										value={chargeCommission || ''}
										onChange={(e: SelectChangeEvent) => setChargeCommission(e.target.value)}
									>
										<MenuItem value=""><em>Aucune</em></MenuItem>
										<MenuItem value="Vendeur">Vendeur</MenuItem>
										<MenuItem value="Acquéreur">Acquéreur</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid xs={12} md={3}><TextField {...form.fields.nomAcquereur} /></Grid>
							<Grid xs={12}><strong>Décision EPFIF</strong></Grid>
							<Grid xs={12} md={2}>
								<FormControl fullWidth>
									<InputLabel>Décision de préempter</InputLabel>
									<Select
										{...form.fields.decisionPreemptionEpf}
										value={decisionPreemptionEpf || ''}
										onChange={(e: SelectChangeEvent) => setDecisionPreemptionEpf(e.target.value)}
									>
										<MenuItem value=""><em>Aucune</em></MenuItem>
										<MenuItem value="Au prix">Au prix</MenuItem>
										<MenuItem value="Offre">Offre</MenuItem>
										<MenuItem value="Renonciation">Renonciation</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid xs={12} md={2}><TextField {...form.fields.prixPreemption} /></Grid>
							<Grid xs={12} md={3}>
								<TextField
									label="Prix préemption m²"
									value={prixPreemptionm2 || ''}
									InputLabelProps={{
										shrink: true,
									}}
									readOnly
									inputProps={{
										endAdornment: '€/m²',
									}}
									fullWidth
								/>
							</Grid>
							<Grid xs={12} md={3}><DatePicker {...form.fields.dateCourrierPreemption} /></Grid>
							<Grid xs={12} md={2}>
								<FormControl fullWidth>
									<InputLabel>Retour Propriétaire</InputLabel>
									<Select
										{...form.fields.retourProprietaire}
										value={retourProprietaire || ''}
										onChange={(e: SelectChangeEvent) => setRetourProprietaire(e.target.value)}
									>
										<MenuItem value=""><em>Aucune</em></MenuItem>
										<MenuItem value="Renonciation à vendre">Renonciation à vendre</MenuItem>
										<MenuItem value="Accord">Accord</MenuItem>
										<MenuItem value="Fixation judiciaire">Fixation judiciaire</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid xs={12} md={3}><DatePicker {...form.fields.dateSaisineNotaire} /></Grid>
						</Grid>
					</CardContent>
					<CardActions className="u-d-block">
						<LoadingButton loading={form.loading} onClick={onClose}>
							Fermer
						</LoadingButton>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							loading={form.loading}
							style={{ float: 'right' }}
							onClick={(event) => {
								event.preventDefault(); // Empêche le formulaire de se soumettre traditionnellement
								form.handle(event); // Passe l'objet événement en argument
							}}
						>
							Enregistrer
						</LoadingButton>
					</CardActions>
				</Card>
			</Form>
		</Dialog>
	);
}
