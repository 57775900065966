import { buildModelHelper } from '@/libs/modelHelper';

export interface ActionSociale {
	id: number;
	referentId: string | null;
	typeAction: string | null;
	dateDepot: string | null; // Date au format ISO string
	dateAccord: string | null; // Date au format ISO string
	montant: number | null;
	demarches: string | null;
	synthese: string | null;
}

// Helper pour gérer les conversions de date et la manipulation du modèle ActionSociale
export const hActionSociale = buildModelHelper<ActionSociale>({
	get dateDepot(): Nullable<Date> {
		return this._self.dateDepot ? new Date(this._self.dateDepot) : null;
	},
	set dateDepot(value: Nullable<Date>) {
		this._self.dateDepot = value ? value.toISOString() : null;
	},

	get dateAccord(): Nullable<Date> {
		return this._self.dateAccord ? new Date(this._self.dateAccord) : null;
	},
	set dateAccord(value: Nullable<Date>) {
		this._self.dateAccord = value ? value.toISOString() : null;
	},
});

export type HActionSociale = ReturnType<typeof hActionSociale>;
