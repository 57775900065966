import { Token } from '@/models/Token';
import { buildModelHelper } from '@/libs/modelHelper';
import { UserSyncicat } from '@/models/UserSyncicat';

export interface User {
	id: number;
	email: Nullable<string>;
	firstName: string;
	fonction: string;
	enable: boolean;
	lastName: string;
	mission: Nullable<string>;
	phone: Nullable<string>;
	phone2: Nullable<string>;
	structure: Nullable<string>
	roles: string[];
	syndicats: UserSyncicat[];
	currentToken: Nullable<Token>;
	tags: Nullable<string[]>;
	pbi: boolean;
	charte: string | null;
	lastPasswordUpdated: string;
	userStatus: number; // Ajout du champ status
}

export const hUser = buildModelHelper<User>({

	get sigle(): string {
		return (this.firstName?.trim()?.[0] || '') + (this.lastName?.trim()?.[0] || '');
	},

	get fullName(): string {
		return `${this.firstName} ${this.lastName}`.trim();
	},

	get fullNameDetail(): string {
		return `${this.fullName} <${this.email}>`.trim();
	},

	get isActive(): boolean {
		return this.userStatus === 1;
	},

	get lastPasswordUpdated(): Nullable<Date> { return this._self.lastPasswordUpdated ? new Date(this._self.lastPasswordUpdated) : null; },

	get charte(): Nullable<Date> {
		return this._self.charte ? new Date(this._self.charte) : null;
	},

	set charte(value: Nullable<Date>) {
		this._self.charte = value ? value.toISOString() : null;
	},

	set lastPasswordUpdated(value: Nullable<Date>) { this._self.lastPasswordUpdated = value ? value.toISOString() : null; },

	hasRole(role: string): boolean {
		return this.roles.indexOf(role) !== -1;
	},

	hasSyndicat(syndicat: string): boolean {
		return this.syndicats.indexOf(syndicat) !== -1;
	}
});

export type HUser = ReturnType<typeof hUser>;
