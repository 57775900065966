import './View.scss';
import { Container } from '@mui/material';
import { useConnectedPage, useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { useDispatch, useSelector } from '@/libs/redux';
import { Fragment, useCallback, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import { Feedback, FeedbackStatus, hFeedback } from '@/models/Feedback';
import { useTranslation } from 'react-i18next';
import { findFeedbacks, patchFeedbackStatus } from '@/stores/feedback';
import { toDate } from '@/libs/utils';
import { LoadingButton } from '@mui/lab';
import { Direction } from '@/models';

interface PageFeedbackViewItemProps {
	item: Feedback;
	onChange: () => any;
}

export function PageFeedbackViewItem(
	{
		item,
		onChange = () => {},
	}: PageFeedbackViewItemProps
) {

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [ loading, setLoading ] = useState(false);
	const feedback = hFeedback(item);
	const pushMessage = useSnackMessage();

	const handleStatus = async (status: FeedbackStatus) => {
		setLoading(true);
		try {
			await dispatch(patchFeedbackStatus({
				...feedback,
				status
			}));
			onChange();
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur est survenue', 'error');
		}
		
		setLoading(false);
	}

	return (
		<TableRow>
			<TableCell>
				{feedback.message.split(/\n/g).map((line: string, i: number) => (
					<Fragment key={i}>
						{line}<br/>
					</Fragment>
				))}
			</TableCell>
			<TableCell>
				{feedback.user.fullName}
			</TableCell>
			<TableCell>
				{feedback.user.email}
			</TableCell>
			<TableCell>
				{toDate(feedback.createdAt)}
			</TableCell>
			<TableCell>
				{toDate(feedback.updatedAt)}
			</TableCell>
			<TableCell>
				{t(`models.feedback.values.${feedback.status}`)}
			</TableCell>
			<TableCell>
				
				{feedback.status === FeedbackStatus.NEW && (
					<LoadingButton
						className="mr-1"
						loading={loading}
						variant="contained"
						color="info"
						onClick={() => handleStatus(FeedbackStatus.COPRO_PLUS)}
					>
						Envoyer chez copro plus
					</LoadingButton>
				)}
				
				{feedback.status !== FeedbackStatus.CLOSE && (
					<LoadingButton
						className="mr-1"
						loading={loading}
						variant="contained"
						onClick={() => handleStatus(FeedbackStatus.CLOSE)}
					>
						Fermer
					</LoadingButton>
				)}
				
				{feedback.status !== FeedbackStatus.NEW && (
					<LoadingButton
						loading={loading}
						variant="contained"
						color="secondary"
						onClick={() => handleStatus(FeedbackStatus.NEW)}
					>
						Ré-ouvrir
					</LoadingButton>
				)}
			</TableCell>
		</TableRow>
	);
}

export default function PageFeedbackView() {

	useConnectedPage();
	const dispatch = useDispatch();
	const logs = useSelector(state => state.feedback.feedbacks);
	const pushMessage = useSnackMessage();
	const pageable = usePageable({
		direction: Direction.DESC,
		order: 'createdAt'
	});

	const [ filters, setFilters ] = useState({
		type: [],
		user: '',
		createdAt: {
			start: '',
			end: ''
		}
	});

	const refresh = useCallback(() => {
		(async () => {
			try {
				await dispatch(findFeedbacks(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
			} catch (e) {
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [ filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage ])

	useEffectOnChange(() => {
		refresh();
	}, [ refresh ]);



	return (
		
		<Container className="p-Feedback">
			<h2>Retours utilisateur</h2>

			<SortableTable
				className="mt-2"
				heads={[
					{ name: 'message'},
					{ name: 'Utilisateur' },
					{ name: 'Email' },
					{ name: 'Date de création', sort: 'createdAt' },
					{ name: 'Mise à jour', sort: 'updatedAt' },
					{ name: 'Statut', sort: 'status' },
					{ name: 'Action' },
				]}
				result={logs}
				renderItem={(item) => (
					<PageFeedbackViewItem
						item={item}
						onChange={refresh}
					/>
				)}
				pageable={pageable}
			/>
		</Container>
	);
}

PageFeedbackView.getMetadata = () => {
	return {
		title: 'Retour utilisateurs',
		rights: [ 'API_GETC_FEEDBACK' ]
	};
};
