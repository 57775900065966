import { RouteObject } from 'react-router';
import Catalogue from '@/pages/documentaire/Catalogue/Catalogue';
import Dictionnaire from '@/pages/documentaire/Dictionnaire/Dictionnaire';
import Aides from '@/pages/documentaire/Aides/Aides';
import Annuaire from '@/pages/documentaire/acteurs/Annuaire/Annuaire';
import Synoptique from '@/pages/documentaire/acteurs/Synoptique/Synoptique';
import Charte from '@/pages/documentaire/Charte/Charte';

export const documentaire: RouteObject[] & any = [
	{
		path: '/documentaire/catalogue',
		element: <Catalogue />
	},
	{
		path: '/documentaire/dictionnaire',
		element: <Dictionnaire />
	},
	{
		path: '/documentaire/aides',
		element: <Aides />
	},
	{
		path: '/documentaire/acteurs/synoptique',
		element: <Synoptique />
	},
	{
		path: '/documentaire/acteurs/annuaire',
		element: <Annuaire />
	},
	{
		path: '/documentaire/charte',
		element: <Charte />
	},
];
