import { buildModelHelper } from '@/libs/modelHelper';

export interface Contact {
    id: number;
    date: string; // La date de création du repérage
    type: string;
    demarches: string;
    synthese: string;
	interlocuteur: string;
    referentId: string;
    dateProchainRdv: string; // Date du prochain rendez-vous
}

// Helper pour gérer les conversions de date et la manipulation du modèle Contact
export const hContact = buildModelHelper<Contact>({
    get date(): Nullable<Date> { return this._self.date ? new Date(this._self.date) : null; },
    set date(value: Nullable<Date>) { this._self.date = value ? value.toISOString() : null; },

    get dateProchainRdv(): Nullable<Date> { return this._self.dateProchainRdv ? new Date(this._self.dateProchainRdv) : null; },
    set dateProchainRdv(value: Nullable<Date>) { this._self.dateProchainRdv = value ? value.toISOString() : null; },
});

export type HContact = ReturnType<typeof hContact>;
