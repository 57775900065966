import IconHome from '@mui/icons-material/Home';
import IconHelp from '@mui/icons-material/Help';
import IconStorage from '@mui/icons-material/Storage';
import IconPeople from '@mui/icons-material/People';
import IconFolderCopy from '@mui/icons-material/FolderCopy';
import IconMenuBook from '@mui/icons-material/MenuBook';
import IconPublic from '@mui/icons-material/Public';
import IconModeEdit from '@mui/icons-material/ModeEdit';
import { ReactNode } from 'react';
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';
import './navigations';


export interface NavigationItem {
	title: string;
	icon?: ReactNode;
	children?: NavigationItem[],
	to?: string,
	granted?: string[],
}

const navigations = [
	{
		title: 'Accueil',
		icon: <IconHome />,
		to: '/home'
	},
	{
		title: 'Habilitation',
		icon: <IconPeople />,
		children: [
			{
				title: 'Gestion des utilisateurs',
				to: '/habilitation/users',
				granted: ['HABILITATION_USERS_MANAGE']
			},
			{
				title: 'Statistiques',
				to: '/habilitation/statistiques',
				granted: ['HABILITATION_STATISTIQUES']
			},
			{
				title: 'Logs de modération',
				to: '/habilitation/logs',
				granted: ['API_GETC_LOG']
			},
			{
				title: 'Retours utilisateur',
				to: '/habilitation/feedbacks',
				granted: ['API_GETC_FEEDBACK']
			},
			// {
			// 	title: 'Gestion des tokens',
			// 	to: '/habilitation/tokens'
			// }
		],
		granted: ['HABILITATION']
	},
	{
		title: 'Annuaire',
		icon: <IconMenuBook />,
		children: [
			{
				title: 'Acteurs',
				to: '/annuaire/acteurs',
				granted: ['ANNUAIRE_ACTEUR']
			},
			{
				title: 'Personnes',
				to: '/annuaire/personnes',
				granted: ['ANNUAIRE_PERSONNE']
			},
			{
				title: 'Logements',
				to: '/annuaire/logements',
				granted: ['ANNUAIRE_LOGEMENT']
			},
		],
		granted: ['ANNUAIRE']
	},
	{
		title: 'Gestion des données',
		icon: <IconStorage />,
		children: [
			{
				title: 'Dépôt de données',
				to: '/base/upload',
				granted: ['BASE_UPLOAD']
			},
			{
				title: 'Documents stockés',
				to: '/base/documents',
				granted: ['BASE_DOC']
			},
			{
				title: 'Workflow des fichiers',
				to: '/base/status',
				granted: ['BASE_MANAGE']
			},
			{
				title: 'Types de Document',
				to: '/base/document-types',
				granted: ['MANAGE_DOCUMENT_TYPE']
			},
			{
				title: 'Emplacement de document',
				to: '/base/file-slots',
				granted: ['MANAGE_FILE_SLOT']
			},
			{
				title: 'État de mise à jour',
				to: '/base/data-available',
				granted: ['BASE_STATE']
			},
		],
		granted: ['BASE']
	},
	{
		title: 'Consultation',
		icon: <IconFolderCopy />,
		children: [
			{
				title: 'Syndicats',
				to: '/consultation/syndicats',
				granted: ['CONSULTATION_SYNDICATS']
			},
			{
				title: 'Copropriétaires',
				to: '/consultation/coproprietaires',
				granted: ['CONSULTATION_COPROPRIETAIRES']
			},
			{
				title: 'Immobilier',
				to: '/consultation/immobilier',
				granted: ['CONSULTATION_IMMOBILIER']
			},
			{
				title: 'Liquidation',
				to: '/consultation/liquidation',
				granted: ['CONSULTATION_LIQUIDATION']
			},
		],
		granted: ['CONSULTATION']
	},
	{
		title: 'Formulaires saisie',
		icon: <IconModeEdit />,
		children: [
			{
				title: 'Suivi du recouvrement',
				to: '/saisie/recouvrement',
				granted: ['SAISIE_RECOUVREMENT']
			},
			{
				title: ' →  Synthèse recouvrement',
				to: '/saisie/synthese-recouvrement',
				granted: ['SAISIE_RECOUVREMENT'],
				className: 'synthese-recouvrement' // Ajout d'une classe spécifique
			},
			{
				title: 'Accompagnement social',
				to: '/saisie/accompagnement-social',
				granted: ['SAISIE_ACCOMPAGNEMENT_SOCIAL']
			},
			{
				title: 'Acquisitions',
				to: '/saisie/acquisitions',
				granted: ['SAISIE_ACQUISITIONS']
			},
			{
				title: 'Désordres',
				to: '/saisie/DESORDRES',
				granted: ['SAISIE_DESORDRES']
			},
		],
		granted: ['SAISIE']
	},
	{
		title: 'Observatoire habitat',
		icon: <IconPublic />,
		children: [
			{
				title: 'Observatoire syndicats',
				to: '/observatoire/syndicats',
				granted: ['OBSERVATOIRE_SYNDICATS']
			},
			{
				title: 'Suivi impayés',
				to: '/observatoire/suivi-impayes',
				granted: ['OBSERVATOIRE_SUIVI_IMPAYES'],
			},
			{
				title: 'Synoptiques',
				to: '/observatoire/synoptiques',
				granted: ['OBSERVATOIRE_SYNOPTIQUES']
			},
			{
				title: 'Cartographie',
				to: '/observatoire/cartographie',
				granted: ['OBSERVATOIRE_CARTOGRAPHIE']
			},

		],
		granted: ['OBSERVATOIRE']
	},
	// {
	// 	title: 'Espace Services métier ',
	// 	icon: <IconFolderCopy/>,
	// 	children: [
	// 		{
	// 			title: 'Syndicat',
	// 			to: '/services/syndicat',
	// 			granted: [ 'SERVICES_SYNDICAT' ]
	// 		},
	// 		{
	// 			title: 'Immobilier',
	// 			to: '/services/immobilier'
	// 		},
	// 		{
	// 			title: 'Accompagnement social',
	// 			to: '/services/accompagnement-social'
	// 		},
	// 		{
	// 			title: 'Liquidation',
	// 			to: '/services/liquidation',
	// 			granted: [ 'SERVICES_LIQUIDATION' ]
	// 		},
	// 	],
	// 	granted: [ 'SERVICES' ]
	// },
	// {
	// 	title: 'Observatoire habitat',
	// 	icon: <IconFolderCopy/>,
	// 	children: [
	// 		{
	// 			title: 'Fiches',
	// 			children: [
	// 				{
	// 					title: 'Syndicat',
	// 					to:  '/observatoire/fiches/syndicat'
	// 				},
	// 				{
	// 					title: 'Personne',
	// 					to:  '/observatoire/fiches/personne'
	// 				},
	// 				{
	// 					title: 'Logement',
	// 					to:  '/observatoire/fiches/logement'
	// 				}
	// 			]
	// 		},
	// 		{
	// 			title: 'Synthèses des données',
	// 			children: [
	// 				{
	// 					title: 'Permanentes',
	// 					to:  '/observatoire/syntheses/permanentes'
	// 				},
	// 				{
	// 					title: 'Gestion',
	// 					to:  '/observatoire/syntheses/gestion'
	// 				},
	// 				{
	// 					title: 'Recouvrement et Accompagnement social',
	// 					to:  '/observatoire/fiches/logement'
	// 				}
	// 			]
	// 		},
	// 		{
	// 			title: 'Évolutions',
	// 			to: '/observatoire/evolutions'
	// 		},
	// 		{
	// 			title: 'Synoptiques',
	// 			to: '/observatoire/synoptique'
	// 		},
	// 		{
	// 			title: 'Cartographie',
	// 			to: '/observatoire/cartographie'
	// 		},
	// 	],
	// 	granted: [ 'OBSERVATOIRE' ]
	// },
	{
		title: 'Espace Documentaire',
		icon: <IconHelp />,
		children: [
			{
				title: 'Dicopro ®',
				to: '/documentaire/dicopro'
			},
			{
				title: 'Charte utilisateur',
				to: '/documentaire/charte'
			},
		],
		granted: ['DOCUMENTAIRE']
	},
] as NavigationItem[];

export default navigations;
