import { Card, CardContent } from '@mui/material';
import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useEffectTimeout, usePropState } from '@/libs/hooks';
import { CardProps } from '@mui/material/Card/Card';
import CrudUserSyncicatSelect from '@/components/crud/userSyncicat/Select/Select';
import TextField from '@/components/inputs/TextField/TextField';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

export interface CrudAnnuaireAccompagnementSocialFiltersProps extends CardProps {
    value?: Nullable<any>;
    onChange?: (value: Nullable<any>) => any,
}

export default function CrudAnnuaireAccompagnementSocialFilters(
    {
        value,
        onChange,
        className = '',
        ...rest
    }: CrudAnnuaireAccompagnementSocialFiltersProps
) {

    const [filters, setFilters] = usePropState<any>(value, onChange, {
        id: '',
        prenom: '',
        libre1: '',
        libre2: ''
    });

    const [id, setId] = useState<string>(filters.id);
    const [nom, setNom] = useState<string>(filters.nom);
    const [libre1, setEmail] = useState<string>(filters.libre1);
    const [libre2, setTelephone] = useState<string>(filters.libre2);
    const [open, setOpen] = useState<boolean>(false);

    useEffectTimeout(() => {
        if (
            filters.id !== id ||
            filters.nom !== nom ||
            filters.libre1 !== libre1 ||
            filters.libre2 !== libre2
        ) {
            setFilters({
                id,
                nom,
                libre1,
                libre2
            })
        }
    }, 500, [
        id,
        nom,
        libre1,
        libre2
    ]);

    return (
        <Card
            {...rest}
            className={[
                className,
                'mt-2',
                'mb-1',
                'c-Crud-AccompagnementSocial-Filters'
            ].join(' ')}
        >
            <CardContent className="p-1">
                <div onClick={() => setOpen(!open)}>
                    <h5 className="m-0" style={{ cursor: 'pointer' }}>  {open ? <KeyboardArrowUp style={{ verticalAlign: '-7px' }} /> : <KeyboardArrowDown style={{ verticalAlign: '-7px' }} />}<span>Filtres :</span></h5>
                </div>
                {open && (
                    <Grid container spacing={1}>
                        <Grid xs={12} md={3}>
                            <TextField
                                {...model(id, setId)}
                                clearable
                                size="small"
                                variant="standard"
                                label="Id"
                            />
                        </Grid>
                        <Grid xs={12} md={3}>
                            <TextField
                                {...model(nom, setNom)}
                                clearable
                                size="small"
                                variant="standard"
                                label="Nom"
                            />
                        </Grid>
                        <Grid xs={12} md={3}>
                            <TextField
                                {...model(libre1, setEmail)}
                                clearable
                                size="small"
                                variant="standard"
                                label="Email"
                            />
                        </Grid>
                        <Grid xs={12} md={3}>
                            <TextField
                                {...model(libre2, setTelephone)}
                                clearable
                                size="small"
                                variant="standard"
                                label="Telephone"
                            />
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
}