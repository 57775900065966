import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { DocumentType, FileSlotPeriodicity, ResultType, UserSyncicat } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class DocumentTypeState {
	public all: Nullable<DocumentType[]> = null;
	public bySyndicatAndPeriodicity: Nullable<DocumentType[]> = null;
	public documentTypes: Nullable<ResultType<DocumentType>> = null;
	public documentType: Nullable<DocumentType> = null;
}

export default {
	state: DocumentTypeState,
	reducer: mapReducer([
		'all',
		'bySyndicatAndPeriodicity',
		'documentTypes',
		'documentType',
	]),
} as SubStore;

// MUTATIONS
export const setAll = (all: Nullable<DocumentType[]>) => (dispatch: DispatchApp) => {
	dispatch({ state: DocumentTypeState, type: MapReducerAction.MAP, all });
};
export const setBySyndicatAndPeriodicity = (bySyndicatAndPeriodicity: Nullable<DocumentType[]>) => (dispatch: DispatchApp) => {
	dispatch({ state: DocumentTypeState, type: MapReducerAction.MAP, bySyndicatAndPeriodicity });
};
export const setDocumentTypes = (documentTypes: Nullable<ResultType<DocumentType>>) => (dispatch: DispatchApp) => {
	dispatch({ state: DocumentTypeState, type: MapReducerAction.MAP, documentTypes });
};
export const setDocumentType = (documentType: Nullable<DocumentType>) => (dispatch: DispatchApp) => {
	dispatch({ state: DocumentTypeState, type: MapReducerAction.MAP, documentType });
};

// ACTIONS
export const findDocumentTypes = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {}
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<DocumentType>> => {
	const documentTypes = await caller.get<ResultType<DocumentType>>(`/api/document-types${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);
	
	dispatch(setDocumentTypes(documentTypes));
	
	return documentTypes;
});

let allPromise: Nullable<Promise<DocumentType[]>> = null;
export const findDocumentTypeAll = () => loadingCall(async (dispatch: DispatchApp): Promise<DocumentType[]> => {
	if (allPromise) {
		return await allPromise;
	}
	allPromise = dispatch(findDocumentTypes(0, '', Direction.ASC, 200)).then((result: ResultType<DocumentType>) => result.data);
	const all = (await allPromise) as DocumentType[];
	dispatch(setAll(all));

	return all;
});

export const findDocumentTypeBySyndicatAndPeriodicity = (
	syndicat: UserSyncicat,
	periodicity: FileSlotPeriodicity
) => loadingCall(async (dispatch: DispatchApp): Promise<DocumentType[]> => {
	
	dispatch(setBySyndicatAndPeriodicity(null));
	const documentTypes = (await dispatch(findDocumentTypes(
		0,
		'', 
		Direction.ASC,
		200,
		{
			syndicat: [ syndicat ],
			periodicity: [ periodicity ],
		}
	))
		.then((result: ResultType<DocumentType>) => result.data)) as DocumentType[]
	;
	dispatch(setBySyndicatAndPeriodicity(documentTypes));

	return documentTypes;
});

export const findDocumentTypeById = (id: number) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<DocumentType>> => {
	const documentType = await caller.get<DocumentType>(`/api/document-types/${id}`);
	dispatch(setDocumentType(documentType));
	return documentType;
});

export const deleteDocumentTypeById = (id: number) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<DocumentType> => {
	const documentType = await caller.delete<DocumentType>(`/api/document-types/${id}`);
	dispatch(setDocumentType(documentType));
	return documentType;
});

export const putDocumentTypeById = (
	id: number,
	documentType: Partial<DocumentType>
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<DocumentType>> => {
	const result = await caller.put<DocumentType>(`/api/document-types/${id}`, documentType);
	dispatch(setDocumentType(result));
	return result;
});

export const postDocumentType = (documentType: Partial<DocumentType>) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<DocumentType>> => {
	const result = await caller.post<DocumentType>(`/api/document-types`, documentType);
	dispatch(setDocumentType(result));
	return result;
});
