import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Contact, Referent } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';

export class ContactState {
}

export default {
    state: ContactState,
    reducer: mapReducer([]),
} as SubStore;

// ACTIONS

export const putContact = (
    contact: Partial<Contact>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Contact> => {
    return await caller.put<Contact>(`/api/contacts/${contact.id}`, {
        date: contact.date,
        type: contact.type,
        demarches: contact.demarches,
        synthese: contact.synthese,
        dateProchainRdv: contact.dateProchainRdv,
    });
});

export const postContact = (
    referent: Referent | string, // Accepter soit un objet soit un id
    contact: Partial<Contact>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Contact> => {
    let referentId;

    if (typeof referent === 'string') {
        referentId = referent;
    } else if (typeof referent === 'object' && referent !== null) {
        if (!referent.id) {
            console.error('Referent missing id:', referent);
            throw new Error('Referent object is missing id property.');
        }

        if (!Array.isArray(referent.contact)) {
            console.warn('Referent contacts is not an array. Defaulting to empty array.');
            referent.contact = [];
        }

        if (referent.contact.length > 0) {
            referentId = referent.contact.map((a: any) => a.referent).join(',');
        } else {
            referentId = referent.id;
        }
    } else {
        console.error('Invalid referent:', referent);
        throw new Error('Invalid referent. Expected an object or a string but received a non-valid type.');
    }

    if (!referentId) {
        console.error('referent object:', referent);
        throw new Error('referentId is undefined. Please check the referent object.');
    }

    return await caller.post<Contact>(`/api/contacts/${encodeURIComponent(referentId)}`, {
        date: contact.date,
        type: contact.type,
        demarches: contact.demarches,
        synthese: contact.synthese,
		interlocuteur: contact.interlocuteur,
        dateProchainRdv: contact.dateProchainRdv,
    });
});

export const deleteContact = (
    contact: Contact,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Contact> => {
    return await caller.delete<Contact>(`/api/contacts/${contact.id}`);
});
