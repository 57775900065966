import './Edit.scss';
import { useForm } from '@/libs/form';
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors';
import { model, number } from '@/libs/candy';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Form from '@/components/forms/Form/Form';
import { Form as FormObject } from '@/libs/form';
import IconDelete from '@mui/icons-material/Delete';
import Select from '@/components/inputs/Select/Select';
import { Edit as IconEdit } from '@mui/icons-material';
import { useDispatch, useSelector } from '@/libs/redux';
import IconModeEdit from '@mui/icons-material/ModeEdit';
import Confirm from '@/components/dialog/Confirm/Confirm';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useEffectOnChange, useSnackMessage } from '@/libs/hooks';
import TextField from '@/components/inputs/TextField/TextField';
import DatePicker from '@/components/inputs/DatePicker/DatePicker';
import { dateToDash, formatNumber, formatDate } from '@/libs/utils';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, IconButton, Typography, Paper, Box, Table, TableBody, TableRow, TableCell, InputAdornment, TableHead, Tooltip, Checkbox, FormControlLabel, debounce } from '@mui/material';

import { HEmail, HIndividu, HSuiviSocial, Referent, TriEvalSociale, HAdresse, HTelephone, TriAcqui, hTriAcqui, HContact, hContact, HActionSociale, hActionSociale, hAccompagnementSocial, hTriEvalSociale, Direction } from '@/models';
import accompagnementSocial, { findAccompagnementSocials, findAccompagnementSocialById, deleteAccompagnementSocial, postAccompagnementSocial, putAccompagnementSocial } from '@/stores/accompagnementSocial';
import type { AccompagnementSocial, HAccompagnementSocial } from '@/models/AccompagnementSocial';
import { hReferent } from '@/models/Referent';
import { findReferentById, setReferent } from '@/stores/referent';
import triEvalSociale, { findTriEvalSociales, getTriEvalSociale } from '@/stores/triEvalSociale';

import CrudEmailList from '@/components/crud/email/List/List';
import CrudAdresseList from '@/components/crud/adresse/List/List';
import CrudTelephoneList from '@/components/crud/telephone/List/List';
import CrudReferentEditHeadChart from '../EditHeadChart/EditHeadChart';
import { CrudReferentSelect } from '../Select/Select';
import { CrudEmailEdit } from '../../email/Edit/Edit';
import { CrudContactEdit } from '../../contact/Edit/Edit';
import { CrudAdresseEdit } from '../../adresse/Edit/Edit';
import { CrudTelephoneEdit } from '../../telephone/Edit/Edit';
import { CrudActionSocialeEdit } from '../../actionSociale/Edit/Edit';
import { CrudIndividuEdit } from '@/components/crud/individut/Edit/Edit';
import { CrudSuiviSocialEdit } from '@/components/crud/suiviSocial/Edit/Edit';
import { CrudChargesMenageEdit } from '@/components/crud/chargesMenage/Edit/Edit';

import { User } from '@/models';
import { getMe } from '@/stores/user';
import recouvrement from '@/stores/recouvrement';
import { deleteIndividu } from '@/stores/individu';
import { deleteSuiviSocial } from '@/stores/suiviSocial';
import { deleteEmail, postEmail, putEmail } from '@/stores/email';
import { deleteAdresse, postAdresse, putAdresse } from '@/stores/adresse';
import { deleteTelephone, postTelephone, putTelephone } from '@/stores/telephone';

import { postContact, putContact, deleteContact } from '@/stores/contact';
import { postActionSociale, putActionSociale, deleteActionSociale } from '@/stores/actionSociale';
import { TriEvalSocialeState } from '@/stores/triEvalSociale';

import { hAcquisition, Acquisition, Lot, TriLot, hTriLot, hProprietaire } from '@/models';

export interface CrudReferentEditProps {
	onClose: () => any;
	onUpdate: () => any;
	onPrev: () => Promise<any>;
	onNext: () => Promise<any>;
	item: Nullable<TriEvalSociale>;
}

//_______________________ Formulaire Evaluation Sociale _________________________________________
export interface InformationsProps {
	onRefresh: () => any;
}

export interface InformationsPropss {
	onRefresh: () => any;
	onTypeAccompagnement: (value: string) => void;
	onEtatAccompagnement: (value: string) => void;
	onDateEvalSociale: (value: string) => void;

}

function EvaluationSocialeInfos({ onRefresh, onTypeAccompagnement, onEtatAccompagnement, onDateEvalSociale }: InformationsPropss) {
	const dispatch = useDispatch();
	const referent = hReferent(useSelector(state => state.referent.referent));
	const [referentId, setReferentId] = useState(referent.id);
	const evaluation: Partial<HAccompagnementSocial> = referent?.accompagnementSocial || {};

	const [loading, setLoading] = useState(false);
	const [showContent, setShowContent] = useState(false);
	const [showEvalContent, setShowEvalContent] = useState(false);
	const [showAccompContent, setShowAccompContent] = useState(false);

	const [fsl, setFsl] = useState(evaluation.fsl || '');
	const [planBdf, setPlanBdf] = useState(evaluation.planBdf || '');
	const [synthese, setSynthese] = useState(evaluation.synthese || '');
	const [protocole, setProtocole] = useState(evaluation.protocole || '');
	const [prixAchat, setPrixAchat] = useState(evaluation.prixAchat || '');
	const [capitalDu, setCapitalDu] = useState(evaluation.capitalDu || '');
	const [numeroCaf, setNumeroCaf] = useState(evaluation.numeroCaf || '');
	const [fiscaleLbl, setFiscale] = useState(evaluation.fiscaleLbl || '');
	const [numeroDls, setNumeroDls] = useState(evaluation.numeroDls || '');
	const [numeroSecu, setNumeroSecu] = useState(evaluation.numeroSecu || '');
	const [orientation, setOrientation] = useState(evaluation.orientation || '');
	const [dateMaj, setDateMaj] = useState<Nullable<Date>>(evaluation.dateMaj || null);
	const [anneeFinCredit, setAnneeFinCredit] = useState(evaluation.anneeFinCredit || '');
	const [actionLogement, setActionLogement] = useState(evaluation.actionLogement || '');
	const [fslVerse, setFslVerse] = useState<Nullable<number>>(evaluation.fslVerse || null);
	const [dureeCreditImmo, setDureeCreditImmo] = useState(evaluation.dureeCreditImmo || '');
	const [ouvertureDroits, setOuvertureDroits] = useState(evaluation.ouvertureDroits || '');
	const [banqueCreditImmo, setBanqueCreditImmo] = useState(evaluation.banqueCreditImmo || '');
	const [precisionsDettes, setPrecisionsDettes] = useState(evaluation.precisionsDettes || '');
	const [anneeAcquisition, setAnneeAcquisition] = useState(evaluation.anneeAcquisition || '');
	const [montantCreditImmo, setMontantCreditImmo] = useState(evaluation.montantCreditImmo || '');
	const [chargesMensuelles, setChargesMensuelles] = useState(evaluation.chargesMensuelles || '');
	const [structureEnCharge, setStructureEnCharge] = useState(evaluation.structureEnCharge || '');
	const [montantCreditConso, setMontantCreditConso] = useState(evaluation.montantCreditConso || '');
	const [originePriseContact, setOriginePriseContact] = useState(evaluation.originePriseContact || '');
	const [montantAutresDettes, setMontantAutresDettes] = useState(evaluation.montantAutresDettes || '');
	const [montantDetteFiscale, setMontantDetteFiscale] = useState(evaluation.montantDetteFiscale || '');
	const [compositionFamiliale, setCompositionFamiliale] = useState(evaluation.compositionFamiliale || '');
	const [ressourcesMensuelles, setRessourcesMensuelles] = useState(evaluation.ressourcesMensuelles || '');
	const [aidesExceptionnelles, setAidesExceptionnelles] = useState(evaluation.aidesExceptionnelles || '');
	const [dateDernierBdf, setDateDernierBdf] = useState<Nullable<Date>>(evaluation.dateDernierBdf || null);
	const [precisionsAcquisition, setPrecisionsAcquisition] = useState(evaluation.precisionsAcquisition || '');
	const [nombreDossiersDeposes, setNombreDossiersDeposes] = useState(evaluation.nombreDossiersDeposes || '');
	const [insertionProfessionnelle, setInsertionProfessionnelle] = useState(evaluation.insertionProfessionnelle || '');
	const [precisionsSurendettement, setPrecisionsSurendettement] = useState(evaluation.precisionsSurendettement || '');
	const [typeAccompagnementAnterieur, setTypeAccompagnementAnterieur] = useState(evaluation.typeAccompagnementAnterieur || '');
	const [precisionsDonneesPersonnelles, setPrecisionsDonneesPersonnelles] = useState(evaluation.precisionsDonneesPersonnelles || '');
	const [informationConfidentialite, setInformationConfidentialite] = useState<boolean>(evaluation.informationConfidentialite ?? false);

	const useStyles = {
		readonlyField: {
			backgroundColor: '#fafafa',
		},
		labelReadOnly: {
			color: 'grey',
			backgroundColor: 'transparent',
		},
		label: {
			backgroundColor: 'transparent',
		},
		pageNumber: {
			padding: '4px 8px',
			border: '2px solid #00a19a',
			borderRadius: '4px',
			display: 'inline-block',
			margin: '0 8px',
			fontWeight: 'bold',
		},
		button: {
			color: '#00a19a',
		},
	};

	const toggleContenEvalSocial = () => {
		setShowEvalContent(!showEvalContent);
	}; const toggleContentMiseEnOeuvreAccompSocial = () => {
		setShowAccompContent(!showAccompContent);
	};
	const pushMessage = useSnackMessage();

	const [typeAccompagnement, setTypeAccompagnement] = useState(evaluation.typeAccompagnement || '');
	const [etatAccompagnement, setEtatAccompagnement] = useState(evaluation.etatAccompagnement || '');
	const [dateEvalSociale, setDateEvalSociale] = useState(evaluation.dateEvalSociale || '');

	const accompagnementS = hAccompagnementSocial(useSelector((state) => state.accompagnementSocial.accompagnementSocial));
	const triAccompagnementState = useSelector((state) => state.triEvalSociale);
	const triAccompagnement = hTriEvalSociale(triAccompagnementState.triEvalSociale);

	const refreshTriAcccomp = useCallback(() => {
		if (evaluation.referentId) {
			setLoading(true);
			dispatch(getTriEvalSociale(evaluation.referentId))
				.finally(() => setLoading(false));
		} else {
			console.warn("Evaluation ID is undefined");
		}
	}, [dispatch, evaluation.referentId]);

	const saveAccompagnementSocialDetails = useCallback(async () => {
		if (!evaluation.id) {
			console.error('Impossible de mettre à jour AccompagnementSocial : evaluation.id est indéfini');
			return;
		}
		// Vérifier si la date est vide ou invalide, et la mettre à null si nécessaire
		const validDateEvalSociale = dateEvalSociale ? dateEvalSociale : null;
		
		const newEvalPart = {
			id: evaluation.id,
			referentId: referent.id,
			typeAccompagnement,
			etatAccompagnement,
			dateEvalSociale: validDateEvalSociale,
		};
		try {
			setLoading(true);
			await dispatch(putAccompagnementSocial(newEvalPart));
			refreshTriAcccomp();
		} catch (error) {
			console.error('Échec de la mise à jour de AccompagnementSocial :', error);
		} finally {
			setLoading(false);
		}
	}, [dispatch, typeAccompagnement, etatAccompagnement, dateEvalSociale, refreshTriAcccomp, pushMessage]);
	
	const handleSaveRefreshAndReopen = useCallback(() => {
		saveAccompagnementSocialDetails().then(() => {
			setTimeout(refreshTriAcccomp, 1000);
		});
	}, [saveAccompagnementSocialDetails, refreshTriAcccomp]);

	useEffect(() => {
		const debouncedHandleChange = debounce(() => {
			handleSaveRefreshAndReopen();
		}, 500);

		if (typeAccompagnement !== null) {
			debouncedHandleChange();
		}

		return () => {
			debouncedHandleChange.clear();
		};
	}, [typeAccompagnement, handleSaveRefreshAndReopen]);

	useEffect(() => {
		const debouncedHandleChange = debounce(() => {
			handleSaveRefreshAndReopen();
		}, 500);

		if (etatAccompagnement !== null) {
			debouncedHandleChange();
		}

		return () => {
			debouncedHandleChange.clear();
		};
	}, [etatAccompagnement, handleSaveRefreshAndReopen]);

	useEffect(() => {
		const debouncedHandleChange = debounce(() => {
			handleSaveRefreshAndReopen();
		}, 500);

		if (dateEvalSociale !== null) {
			debouncedHandleChange();
		}

		return () => {
			debouncedHandleChange.clear();
		};
	}, [dateEvalSociale, handleSaveRefreshAndReopen]);

	const formAccompSocial = useForm({
		handle: async () => {
			await formAccompSocial.call(async () => {
				await save();
			});
		},
		fields: {
			typeAccompagnement: {
				...model(typeAccompagnement, setTypeAccompagnement),
				label: 'Type accompagnement social envisagé',
				items: ['', 'Redressement - Maintien autonome', 'Redressement - Maintien accompagné', 'Redressement - Maintien compromis', 'Redressement - Changement de statut/vente libre', 'Recyclage - Vente bloquée', 'Recyclage - Vente avec désendettement préalable', 'Recyclage - Vente autonome'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			etatAccompagnement: {
				...model(etatAccompagnement, setEtatAccompagnement),
				label: "Etat de l'accompagnement social",
				items: ['', 'Veille', 'Refus', 'Clôturé', 'Suivi'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			dateEvalSociale: {
				...model(dateEvalSociale, setDateEvalSociale),
				label: 'Date de réalisation de l\'ES',
			},
		},
	});

	const save = async () => {
		let newRecordAccomp: Partial<AccompagnementSocial> = {
			id: evaluation.id,
			referentId: referent.id,
			typeAccompagnement,
			etatAccompagnement,
			dateEvalSociale,
		} as any;
		if (newRecordAccomp.id) {
			newRecordAccomp = await dispatch(putAccompagnementSocial(newRecordAccomp));
		}
		pushMessage(`Mise à jour de la valeur terminée.`);
	};

	useEffect(() => {
		if (accompagnementS) {
			setTypeAccompagnement(accompagnementS.typeAccompagnement || '');
			onTypeAccompagnement(accompagnementS.typeAccompagnement || '');

			setEtatAccompagnement(accompagnementS.etatAccompagnement || '');
			onEtatAccompagnement(accompagnementS.etatAccompagnement || '');

			setDateEvalSociale(accompagnementS.dateEvalSociale || '');
			onDateEvalSociale(accompagnementS.dateEvalSociale || '');
		}
	}, [accompagnementS?.typeAccompagnement, accompagnementS?.etatAccompagnement, accompagnementS?.dateEvalSociale, onTypeAccompagnement, onEtatAccompagnement, onDateEvalSociale]);

	useEffect(() => {
		if (accompagnementS) {
			setTypeAccompagnement(accompagnementS.typeAccompagnement || '');
			onTypeAccompagnement(accompagnementS.typeAccompagnement || '');

			setEtatAccompagnement(accompagnementS.etatAccompagnement || '');
			onEtatAccompagnement(accompagnementS.etatAccompagnement || '');

			setDateEvalSociale(accompagnementS.dateEvalSociale || '');
			onDateEvalSociale(accompagnementS.dateEvalSociale || '');
		}
	}, [accompagnementS?.typeAccompagnement, accompagnementS?.etatAccompagnement, accompagnementS?.dateEvalSociale, onTypeAccompagnement, onEtatAccompagnement, onDateEvalSociale]);

	const typeAccomp = formAccompSocial.fields.typeAccompagnement.value;
	const etatAccomp = formAccompSocial.fields.etatAccompagnement.value;
	const dateEvalSocialeAccomp = formAccompSocial.fields.dateEvalSociale.value;

	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				let newEval: Partial<AccompagnementSocial> = {
					id: evaluation.id,
					referentId: referent.id,
					structureEnCharge,
					dateDernierBdf: dateDernierBdf ? dateDernierBdf.toISOString() : null,
					typeAccompagnement,
					originePriseContact,
					planBdf,
					nombreDossiersDeposes,
					protocole,
					fsl,
					fslVerse: fslVerse ? parseFloat(fslVerse.toString()) : null,
					actionLogement,
					ouvertureDroits,
					aidesExceptionnelles,
					insertionProfessionnelle,
					synthese,
					numeroSecu,
					numeroCaf,
					anneeAcquisition,
					prixAchat: prixAchat ? parseFloat(prixAchat) : null,
					capitalDu: capitalDu ? parseFloat(capitalDu) : null,
					anneeFinCredit,
					montantCreditImmo: montantCreditImmo ? parseFloat(montantCreditImmo) : null,
					dureeCreditImmo,
					banqueCreditImmo,
					montantCreditConso: montantCreditConso ? parseFloat(montantCreditConso) : null,
					montantDetteFiscale: montantDetteFiscale ? parseFloat(montantDetteFiscale) : null,
					montantAutresDettes: montantAutresDettes ? parseFloat(montantAutresDettes) : null,
					numeroDls,
					compositionFamiliale,
					ressourcesMensuelles: ressourcesMensuelles ? parseFloat(ressourcesMensuelles) : null,
					chargesMensuelles: chargesMensuelles ? parseFloat(chargesMensuelles) : null,
					informationConfidentialite: informationConfidentialite,
					orientation,
					precisionsDonneesPersonnelles,
					precisionsDettes,
					precisionsAcquisition,
					precisionsSurendettement,
					typeAccompagnementAnterieur,
					dateMaj: dateMaj ? dateMaj.toISOString() : null,
				} as any;
				if (referent.accompagnementSocial?.id) {
					newEval = await dispatch(putAccompagnementSocial(newEval));
				}
				referent.accompagnementSocial = newEval;
				dispatch(setReferent(referent));
				onRefresh();
			});
		},
		fields: {
			structureEnCharge: {
				...model(structureEnCharge, setStructureEnCharge),
				label: "Structure en charge de l'ES",
				items: ['', 'SOLIHA', 'URBANIS', 'L. CHATELAIN'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			dateDernierBdf: {
				...model(dateDernierBdf, setDateDernierBdf),
				label: 'Date du dernier BDF',
			},
			originePriseContact: {
				...model(originePriseContact, setOriginePriseContact),
				label: 'Origine prise de contact',
				items: ['', 'Repérage GTI', 'Orientation acquisitions', 'Orientation partenaires', 'Sollicitation du ménage', 'Commissions impayés'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			typeAccompagnementAnterieur: {
				...model(typeAccompagnementAnterieur, setTypeAccompagnementAnterieur),
				label: 'Type accompagnement social envisagé antérieur',
				items: ['', 'Redressement - Maintien autonome', 'Redressement - Maintien accompagné', 'Redressement - Maintien compromis', 'Redressement - Changement de statut/vente libre', 'Recyclage - Vente bloquée', 'Recyclage - Vente avec désendettement préalable', 'Recyclage - Vente autonome'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},


			protocole: {
				...model(protocole, setProtocole),
				label: 'Protocole d\'apurement',
				items: ['', 'En cours d\'élaboration', 'Moratoire', 'PCR', 'Mesures imposées', 'Rétablissement personnel', 'Plan caduc'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			fsl: {
				...model(fsl, setFsl),
				label: 'FSL',
				items: ['', 'En cours d\'élaboration', 'Dossier déposé', 'Dossier accepté', 'Dossier refusé', 'FSL versé'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			fslVerse: {
				...model(fslVerse, setFslVerse),
				label: 'Montant FSL versé (€)',
				type: 'number',
			},
			actionLogement: {
				...model(actionLogement, setActionLogement),
				label: 'Action Logement',
				items: ['', 'Orienté vers AL', 'Suivi par AL', 'Dossier accepté', 'Dossier refusé', 'AL versé'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			ouvertureDroits: {
				...model(ouvertureDroits, setOuvertureDroits),
				label: 'Ouverture de droits',
				items: ['', 'Oui', 'Non concerné', 'En cours d\'élaboration', 'A faire'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			aidesExceptionnelles: {
				...model(aidesExceptionnelles, setAidesExceptionnelles),
				label: 'Aides exceptionnelles',
			},
			insertionProfessionnelle: {
				...model(insertionProfessionnelle, setInsertionProfessionnelle),
				label: 'Insertion professionnelle',
				items: ['', 'Antenne Emploi', 'Autres'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			synthese: {
				...model(synthese, setSynthese),
				label: 'Synthèse de la situation',
			},
			numeroSecu: {
				...model(numeroSecu, setNumeroSecu),
				label: 'Numéro de Sécurité Sociale',
			},
			numeroCaf: {
				...model(numeroCaf, setNumeroCaf),
				label: 'Numéro CAF',
			},
			dateAcquisition: {
				...model(anneeAcquisition, setAnneeAcquisition),
				label: 'Année d\'acquisition',
				type: 'number',
			},
			prixAchat: {
				...model(prixAchat, setPrixAchat),
				label: 'Prix d’achat (€)',
				type: 'number',
			},
			capitalDu: {
				...model(capitalDu, setCapitalDu),
				label: 'Capital restant dû (€)',
				type: 'number',
			},
			dureeCreditImmo: {
				...model(dureeCreditImmo, setDureeCreditImmo),
				label: 'Durée du crédit immobilier (années)',
			},
			montantCreditImmo: {
				...model(montantCreditImmo, setMontantCreditImmo),
				label: 'Montant du crédit immobilier (€)',
				type: 'number',
			},
			anneeFinCredit: {
				...model(anneeFinCredit, setAnneeFinCredit),
				label: 'Année de fin du crédit',
			},
			banqueCreditImmo: {
				...model(banqueCreditImmo, setBanqueCreditImmo),
				label: 'Banque du crédit immobilier',
			},
			typeDetteFiscale: {
				...model(montantDetteFiscale, setMontantDetteFiscale),
				label: 'Montant de la dette fiscale (€)',
				type: 'number',
			},
			fiscaleLbl: {
				...model(fiscaleLbl, setFiscale),
				label: 'Fiscale ?',
			},
			planBdf: {
				...model(planBdf, setPlanBdf),
				label: 'Plan BDF',
				items: ['', 'Information demandée en attente', 'Dossier déposé', 'Dossier recevable', 'Dossier rejeté', 'Moratoire', 'Plan conventionnel de redressement', 'Mesures imposées', 'Rétablissement personnel', 'Plan Caduc'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			nombreDossiersDeposes: {
				...model(nombreDossiersDeposes, setNombreDossiersDeposes),
				label: 'Nombre dossiers déposés',
				items: ['', '1', '2', '3', '4', '5+'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			montantAutresDettes: {
				...model(montantAutresDettes, setMontantAutresDettes),
				label: 'Montant autres dettes cumulé',
				type: 'number',
			},
			montantCreditConso: {
				...model(montantCreditConso, setMontantCreditConso),
				label: 'Montant crédit à la consommation',
				type: 'number',
			},
			montantDetteFiscale: {
				...model(montantDetteFiscale, setMontantDetteFiscale),
				label: 'Montant dette fiscale',
				type: 'number',
			},
			referentId: {
				...model(referentId, setReferentId),
				type: 'hidden',
			},
			numeroDls: {
				...model(numeroDls, setNumeroDls),
				label: 'N° DLS',
			},
			compositionFamiliale: {
				...model(compositionFamiliale, setCompositionFamiliale),
				label: 'Composition familiale (nombre de personnes)',
			},
			ressourcesMensuelles: {
				...model(ressourcesMensuelles, setRessourcesMensuelles),
				label: 'Ressources Mensuelles (€)',
				type: 'number',
			},
			chargesMensuelles: {
				...model(chargesMensuelles, setChargesMensuelles),
				label: 'Charges Mensuelles (€)',
				type: 'number',
			},
			informationConfidentialite: {
				...model(informationConfidentialite, setInformationConfidentialite),
				label: 'Information Confidentielle',
				items: [
					{ value: true, text: 'Oui' },
					{ value: false, text: 'Non' },
				],
			},
			orientation: {
				...model(orientation, setOrientation),
				label: 'Orientation',
				items: ['', 'Orientation par l’EPFIF', 'Orientation par un partenaire', 'Sollicitation en direct du ménage'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			precisionsDonneesPersonnelles: {
				...model(precisionsDonneesPersonnelles, setPrecisionsDonneesPersonnelles),
				label: 'Précisions Données Personnelles',
			},
			precisionsDettes: {
				...model(precisionsDettes, setPrecisionsDettes),
				label: 'Précisions Dettes',
			},
			precisionsAcquisition: {
				...model(precisionsAcquisition, setPrecisionsAcquisition),
				label: 'Précisions Acquisition',
			},
			precisionsSurendettement: {
				...model(precisionsSurendettement, setPrecisionsSurendettement),
				label: 'Précisions Surendettement',
			},
			dateMaj: {
				...model(dateMaj, setDateMaj),
				label: 'Date du dernier BDF',
			}
		},
	});

	return (
		<Form form={form}>
			<Card elevation={4} className="mb-1">
				<div onClick={toggleContenEvalSocial} style={{ cursor: 'pointer' }}>
					<CardHeader
						className="py-1 mb-1"
						title="Évaluation sociale"
						titleTypographyProps={{ className: 'u-t-size-19' }}
						action={
							<IconButton onClick={toggleContenEvalSocial} style={{ color: 'white' }}>
								{showEvalContent ? <ExpandLessIcon /> : <AddIcon />}
							</IconButton>
						}
					/>
				</div>
				{showEvalContent && (
					<CardContent className="pt-0">
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12} md={4}>
								<Select {...form.fields.originePriseContact} />
							</Grid>
							<Grid xs={12} md={12}></Grid>
						</Grid>
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12} md={4}>
								<Select {...form.fields.structureEnCharge} />
							</Grid>
							<Grid xs={12} md={4}>
								<DatePicker {...formAccompSocial.fields.dateEvalSociale} />
							</Grid>

							<Grid xs={12} md={12}></Grid>
						</Grid>


						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12} md={12}>
								<FormControlLabel
									control={
										<Checkbox
											checked={informationConfidentialite}
											onChange={(e) => setInformationConfidentialite(e.target.checked)}
											name="informationConfidentialite"
											color="primary"
										/>
									}
									label={
										<span style={{
											fontSize: '0.85rem',
											fontStyle: 'italic',
										}}>
											La personne enquêtée est informée que les informations contenues dans ce diagnostic social sont susceptibles d’être communiquées aux partenaires soumis à la discrétion professionnelle.
										</span>
									}
								/>
							</Grid>
							<Grid xs={12} md={12}></Grid>
							<Grid xs={12} md={12}></Grid>

							<Grid xs={12} md={4}>
								<TextField {...form.fields.numeroSecu} />
							</Grid>
							<Grid xs={12} md={4}>
								<TextField {...form.fields.numeroCaf} />
							</Grid>
							<Grid xs={12} md={4}>
								<TextField {...form.fields.numeroDls} />
							</Grid>
							<Grid xs={12} md={4}>
								<TextField {...form.fields.compositionFamiliale} />
							</Grid>
							<Grid xs={12} md={4}>
								<TextField {...form.fields.ressourcesMensuelles} />
							</Grid>
							<Grid xs={12} md={4}>
								<TextField {...form.fields.chargesMensuelles} />
							</Grid>
							<Grid xs={12}>
								<TextField
									multiline
									rows={2}
									{...form.fields.precisionsDonneesPersonnelles}
									placeholder="Vos données de commentaire seront traitées conformément à notre politique de confidentialité et aux réglementations de la RGPD."
									helperText="Conformément au RGPD, les informations collectées et renseignées sur les personnes doivent être adéquates, pertinentes et non excessives au regard de la finalité du traitement envisagé. Les commentaires ne doivent donc pas être inappropriés, subjectifs et insultants."
								/>
							</Grid>
						</Grid>
						<Grid container spacing={1} rowSpacing={2} className="customGridContainer">
							<Grid xs={12} md={12}>
								<Typography variant="h6">Acquisition</Typography>
							</Grid>
							<Grid xs={12} md={2.4}>
								<TextField {...form.fields.dateAcquisition} />
							</Grid>
							<Grid xs={12} md={2.4}>
								<TextField {...form.fields.prixAchat} />
							</Grid>
							<Grid xs={12} md={2.4}>
								<TextField {...form.fields.capitalDu} />
							</Grid>
							<Grid xs={12} md={2.4}>
								<TextField {...form.fields.anneeFinCredit} />
							</Grid>
							<Grid xs={12} md={2.4}>
								<TextField {...form.fields.montantCreditImmo} />
							</Grid>
							<Grid xs={12} md={2.4}>
								<TextField {...form.fields.dureeCreditImmo} />
							</Grid>
							<Grid xs={12} md={2.4}>
								<TextField {...form.fields.banqueCreditImmo} />
							</Grid>
							<Grid xs={12}>
								<TextField
									multiline
									rows={2}
									{...form.fields.precisionsAcquisition}
									placeholder="Vos données de commentaire seront traitées conformément à notre politique de confidentialité et aux réglementations de la RGPD."
									helperText="Conformément au RGPD, les informations collectées et renseignées sur les personnes doivent être adéquates, pertinentes et non excessives au regard de la finalité du traitement envisagé. Les commentaires ne doivent donc pas être inappropriés, subjectifs et insultants."
								/>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid xs={12}>
								<Grid container spacing={1} rowSpacing={2} className="customGridContainer">
									<Grid xs={12} md={12}>
										<Box className="customBox">
											<Grid container alignItems="center">
												<Grid xs={12} md={3}>
													<Typography variant="h6">Type de dettes :</Typography>
												</Grid>
												<Grid xs={12} md={3}>
													<Typography>Crédit consommation ?</Typography>
												</Grid>
												<Grid xs={12} md={3}>
													<Typography>Fiscales ?</Typography>
												</Grid>
												<Grid xs={12} md={3}>
													<Typography>Autres ?</Typography>
												</Grid>
											</Grid>
										</Box>
										<Grid container spacing={2} alignItems="center">
											<Grid xs={12} md={3}>
												<Typography variant="h6">Montant (en €) :</Typography>
											</Grid>
											<Grid xs={3} md={3}>
												<TextField {...form.fields.montantCreditConso} />
											</Grid>
											<Grid xs={3} md={3}>
												<TextField {...form.fields.montantDetteFiscale} />
											</Grid>
											<Grid xs={3} md={3}>
												<TextField {...form.fields.montantAutresDettes} />
											</Grid>
										</Grid>
										<Grid xs={12}>
											<TextField
												multiline
												rows={2}
												{...form.fields.precisionsDettes}
												placeholder="Vos données de commentaire seront traitées conformément à notre politique de confidentialité et aux réglementations de la RGPD."
												helperText="Conformément au RGPD, les informations collectées et renseignées sur les personnes doivent être adéquates, pertinentes et non excessives au regard de la finalité du traitement envisagé. Les commentaires ne doivent donc pas être inappropriés, subjectifs et insultants."
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid container spacing={1} rowSpacing={2} className="customGridContainer">

							<Grid xs={12} md={12}>
								<Typography variant="h6">Surendettement</Typography>
							</Grid>
							<Grid xs={12} md={4}>
								<Select {...form.fields.planBdf} />
							</Grid>
							<Grid xs={12} md={4}>
								<Select {...form.fields.nombreDossiersDeposes} />
							</Grid>
							<Grid xs={12} md={4}>
								<DatePicker {...form.fields.dateDernierBdf} />
							</Grid>
							<Grid xs={12}>
								<TextField
									multiline
									rows={2}
									{...form.fields.precisionsSurendettement}
									placeholder="Vos données de commentaire seront traitées conformément à notre politique de confidentialité et aux réglementations de la RGPD."
									helperText="Conformément au RGPD, les informations collectées et renseignées sur les personnes doivent être adéquates, pertinentes et non excessives au regard de la finalité du traitement envisagé. Les commentaires ne doivent donc pas être inappropriés, subjectifs et insultants."
								/>
							</Grid>
						</Grid>
					</CardContent>
				)}
			</Card>
			<Card elevation={4} className="mb-1">
				<div onClick={toggleContentMiseEnOeuvreAccompSocial} style={{ cursor: 'pointer' }}>
					<CardHeader
						className="py-1 mb-1"
						title="Mise en œuvre de l'accompagnement social"
						titleTypographyProps={{ className: 'u-t-size-19' }}
						action={
							<IconButton onClick={toggleContentMiseEnOeuvreAccompSocial} style={{ color: 'white' }}>
								{showAccompContent ? <ExpandLessIcon /> : <AddIcon />}
							</IconButton>
						}
					/>
				</div>
				{showAccompContent && (
					<CardContent className="pt-0">
						<FormMainErrors form={form} />
						<Grid container spacing={1} rowSpacing={2} className="customGridContainer">
							<Grid xs={12}>
								<strong>Type d'accompagnement social envisagé</strong>
							</Grid>
							<Grid xs={12} md={4}>
								<Select {...formAccompSocial.fields.typeAccompagnement} />
							</Grid>
							<Grid xs={12} md={4}>
								<Select {...formAccompSocial.fields.etatAccompagnement}
									InputLabelProps={{
										shrink: true,
										style: useStyles.label,
									}}
								/>
							</Grid>
							<Grid xs={12} md={4}>
								<Select {...form.fields.typeAccompagnementAnterieur} />
							</Grid>
							{/* <Select {...form.fields.etatAccompagnement} value={typeAccomp} style={{ display: 'none' }} /> */}
							<Grid xs={12} md={12}>
								<TextField
									className="custom_textfieldAlignL"
									label="Statut du dossier"
									value={triAccompagnement?.statutAccompagnement || '-'}
									variant="outlined"
									readOnly={true}
									InputLabelProps={{
										shrink: true,
										style: useStyles.label,
									}}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={1} rowSpacing={2} className="customGridContainer">
							<Grid xs={12}>
								<strong>Outils désendettement / Mesures engagées</strong>
							</Grid>
							<Grid xs={12} md={4}>
								<Select {...form.fields.protocole} />
							</Grid>
							<Grid xs={12} md={4}>
								<Select {...form.fields.fsl} />
							</Grid>
							<Grid xs={12} md={4}>
								<TextField {...form.fields.fslVerse} />
							</Grid>
							<Grid xs={12} md={3}>
								<Select {...form.fields.actionLogement} />
							</Grid>
							<Grid xs={12} md={3}>
								<Select {...form.fields.ouvertureDroits} />
							</Grid>
							<Grid xs={12} md={3}>
								<TextField {...form.fields.aidesExceptionnelles} />
							</Grid>
							<Grid xs={12} md={3}>
								<Select {...form.fields.insertionProfessionnelle} />
							</Grid>
							<Grid xs={12}>
								<TextField
									multiline
									rows={5}
									{...form.fields.synthese}
									placeholder="Vos données de commentaire seront traitées conformément à notre politique de confidentialité et aux réglementations de la RGPD."
									helperText="Conformément au RGPD, les informations collectées et renseignées sur les personnes doivent être adéquates, pertinentes et non excessives au regard de la finalité du traitement envisagé. Les commentaires ne doivent donc pas être inappropriés, subjectifs et insultants."
								/>
							</Grid>
						</Grid>
					</CardContent>
				)}
			</Card>
			<Grid container spacing={1} rowSpacing={2} className="myGridContainerFormButtons">
				<Grid xs={12} sx={{ textAlign: 'center' }}>
					<LoadingButton className="loadingButton" loading={form.loading} variant="contained" type="submit">
						Enregistrer
					</LoadingButton>
				</Grid>
			</Grid>
		</Form>
	);
}

//_______________________ Sous formulaire Suivi Social _________________________________________
export interface CompositionsProps {
	onRefresh: () => any;
}

export interface ChargesProps {
	onRefresh: () => any;
}

export interface SuiviSocialProps {
	onRefresh: () => any;
}

function SuiviSocial({ onRefresh }: CompositionsProps) {
	const dispatch = useDispatch();
	const referent = hReferent(useSelector((state) => state.referent.referent));
	const [edit, setEdit] = useState<Nullable<HSuiviSocial>>(null);
	const [remove, setRemove] = useState<Nullable<HSuiviSocial>>(null);
	const [showContent, setShowContent] = useState(false);
	const filteredReferenta = referent?.id;

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	return (
		<Card elevation={4} className="mb-1">
			<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
				<CardHeader
					className="py-1 mb-1"
					title="Suivi social"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <ExpandLessIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
			</div>
			{showContent && (
				<CardContent className="pt-0">
					<div style={{ overflow: 'auto' }}>
						{referent?.suiviSocials?.length ? (
							<div style={{ overflow: 'auto' }}>
								<table className="c-Crud-Referent-Edit-suiviSocial">
									<thead>
										<tr>
											<th>Date</th>
											<th>Type contact</th>
											<th>Démarche</th>
											<th>À faire</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{referent.suiviSocials.map((ss: HSuiviSocial) => (
											<tr key={ss.id}>
												<td>{dateToDash(ss.date)}</td>
												<td>{ss.typeContact}</td>
												<td>{ss.demarches}</td>
												<td>{ss.aFaire}</td>
												<td>
													<IconButton onClick={() => setEdit(ss)}>
														<IconModeEdit />
													</IconButton>
													<IconButton onClick={() => setRemove(ss)}>
														<IconDelete />
													</IconButton>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<div className="u-t-center">
								<i>Aucun suivi sur le ménage</i>
							</div>
						)}
					</div>
					<br />
					<Button
						variant="contained"
						onClick={() =>
							setEdit({
								date: new Date(),
								typeContact: '',
								aFaire: '',
								demarches: '',
							})
						}
					>
						Créer un nouveau suivi
					</Button>
					<Dialog open={edit} onClose={() => setEdit(null)} maxWidth={'md'}>
						<CrudSuiviSocialEdit
							referent={filteredReferenta}
							suivi={edit}
							onSave={() => {
								setEdit(null);
								onRefresh();
							}}
							onClose={() => setEdit(null)}
						/>
					</Dialog>
					<Confirm
						value={!!remove}
						onChange={() => setRemove(null)}
						title="Suppression d'un suivi"
						message={`Êtes--vous sûr de vouloir supprimer le suivi de ${dateToDash(remove?.date)} ?`}
						onConfirm={async () => {
							await dispatch(deleteSuiviSocial(remove));
							onRefresh();
						}}
					/>
				</CardContent>
			)}
		</Card>
	);
}

//_______________________ Sous formulaire Action Sociale _________________________________________
export interface ActionSocialeProps {
	onRefresh: () => any;
}

function ActionSociale({ onRefresh }: ActionSocialeProps) {
	const dispatch = useDispatch();
	const referent = hReferent(useSelector((state) => state.referent.referent));
	const [edit, setEdit] = useState<Nullable<HActionSociale>>(null);
	const [remove, setRemove] = useState<Nullable<HActionSociale>>(null);
	const [showContent, setShowContent] = useState(false);

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	return (
		<Card elevation={4} className="mb-1">
			<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
				<CardHeader
					className="py-1 mb-1"
					title="Mesures engagées"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <ExpandLessIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
			</div>
			{showContent && (
				<CardContent className="pt-0">
					<div style={{ overflow: 'auto' }}>
						{referent?.actionSociales?.length ? (
							<div style={{ overflow: 'auto' }}>
								<table
									className="c-Crud-Referent-Edit-suiviSocial"
									style={{ width: '100%' }}
								>
									<thead>
										<tr>
											<th style={{ width: '15%' }}>Type d'action</th>
											<th style={{ width: '10%' }}>Date de dépôt</th>
											<th style={{ width: '10%' }}>Date d'accord</th>
											<th style={{ width: '10%' }}>Montant</th>
											<th style={{ width: '25%' }}>Démarches</th>
											<th style={{ width: '20%' }}>Synthèse</th>
											<th style={{ width: '10%' }}>Actions</th>
										</tr>
									</thead>
									<tbody>
										{referent.actionSociales.map((as: HActionSociale) => (
											<tr key={as.id}>
												<td>{as.typeAction}</td>
												<td>
													{as.dateDepot
														? formatDate(as.dateDepot)
														: ''}
												</td>
												<td>
													{as.dateAccord
														? formatDate(as.dateAccord)
														: ''}
												</td>
												<td>{as.montant}</td>
												<td>{as.demarches}</td>
												<td>{as.synthese}</td>
												<td style={{ textAlign: 'center' }}>
													<IconButton onClick={() => setEdit(as)}>
														<IconEdit />
													</IconButton>
													<IconButton onClick={() => setRemove(as)}>
														<IconDelete />
													</IconButton>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<div className="u-t-center">
								<i>Aucune action sociale</i>
							</div>
						)}
					</div>

					<br />
					<Grid
						xs={12}
						sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}
					>
						<Button
							variant="contained"
							onClick={() =>
								setEdit({
									typeAction: '',
									dateDepot: null,
									dateAccord: null,
									montant: null,
									demarches: '',
									synthese: '',
								} as HActionSociale)
							}
						>
							+
						</Button>
					</Grid>
					<Dialog open={!!edit} onClose={() => setEdit(null)} maxWidth={'md'}>
						{edit && (
							<CrudActionSocialeEdit
								referent={referent}
								actionSociale={edit}
								onSave={() => {
									setEdit(null);
									onRefresh();
								}}
								onClose={() => setEdit(null)}
							/>
						)}
					</Dialog>
					<Confirm
						value={!!remove}
						onChange={() => setRemove(null)}
						title="Suppression d'une action sociale"
						message={`Êtes-vous sûr de vouloir supprimer l'action sociale "${remove?.typeAction}" ?`}
						onConfirm={async () => {
							if (remove) {
								await dispatch(deleteActionSociale(remove));
								setRemove(null);
								onRefresh();
							}
						}}
					/>
				</CardContent>
			)}
		</Card>
	);
}

//_______________________ Sous formulaire Coordonnées du ménage _________________________________________
export interface CrudReferentEditProps {
	onClose: () => any;
	onUpdate: () => any;
	onPrev: () => Promise<any>;
	onNext: () => Promise<any>;
	item: Nullable<TriEvalSociale>;
}

export interface InformationsProps {
	onRefresh: () => any;
}

function useReferentById(referentId: string) {
	const dispatch = useDispatch();
	const [referent, setReferent] = useState<Nullable<Referent>>(null);
	const [loading, setLoading] = useState(false);

	const fetchReferent = useCallback(async () => {
		setLoading(true);
		try {
			const result = await dispatch(findReferentById(referentId));
			setReferent(result);
		} catch (e) {
			console.error(e);
		}
		setLoading(false);
	}, [dispatch, referentId]);

	useEffect(() => {
		fetchReferent();
	}, [fetchReferent]);

	return { referent, loading, refresh: fetchReferent };
}

function Part9({ onRefresh }: InformationsProps) {
	const dispatch = useDispatch();
	const triAcqui = hTriAcqui(useSelector((state) => state.triAcqui.triAcqui));
	const filteredReferenta = triAcqui?.proprietaire;
	const referents = hReferent(useSelector((state) => state.referent.referent));
	const { referent, loading, refresh } = useReferentById(referents.id);
	const [currentUser, setCurrentUser] = useState<Nullable<User>>(null);
	const [editAdresse, setEditAdresse] = useState<Nullable<HAdresse>>(null);
	const [editTelephone, setEditTelephone] = useState<Nullable<HTelephone>>(null);
	const [editEmail, setEditEmail] = useState<Nullable<HEmail>>(null);
	const [removeAdresse, setRemoveAdresse] = useState<Nullable<HAdresse>>(null);
	const [removeTelephone, setRemoveTelephone] = useState<Nullable<HTelephone>>(null);
	const [removeEmail, setRemoveEmail] = useState<Nullable<HEmail>>(null);
	const [showContent, setShowContent] = useState(false);
	const [addresses, setAddresses] = useState<HAdresse[]>([]);
	const [emails, setEmails] = useState<HEmail[]>([]);
	const [telephones, setTelephones] = useState<HTelephone[]>([]);

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	useEffect(() => {
		refresh();
		dispatch(getMe()).then((user: Nullable<User>) => {
			setCurrentUser(user);
		});
	}, [refresh, dispatch]);

	const getTypeLabel = (type: any) => {
		switch (type) {
			case 1:
				return "Donnée syndic";
			case 2:
				return "Saisie manuelle";
			default:
				return "";
		}
	};

	const concatAddresses = (adresse1: string | null, adresse2: string | null, adresse3: string | null) => {
		return [adresse1, adresse2, adresse3].filter(Boolean).join(' | ');
	};

	const getSource = (type: number, source: string | null) => {
		return source || '';
	};

	const filterAndSortItems = <T extends { type: number; dateMaj: string; pertinence: number; coche: boolean }>(items: T[], key: keyof T) => {
		return items.sort((a, b) => {
			if (a.coche !== b.coche) {
				return (b.coche ? 1 : 0) - (a.coche ? 1 : 0);
			} else if (a.type !== b.type) {
				return b.type - a.type;
			} else if (new Date(a.dateMaj).getTime() !== new Date(b.dateMaj).getTime()) {
				return new Date(b.dateMaj).getTime() - new Date(a.dateMaj).getTime();
			} else {
				return b.pertinence - a.pertinence;
			}
		});
	};

	const submitPertinenceChange = async (newItem: any, previousItem: any, putFunction: any) => {
		try {
			if (previousItem) {
				const updatedPreviousItem = { ...previousItem, pertinence: null };
				await dispatch(putFunction(updatedPreviousItem));
			}

			if (newItem) {
				await dispatch(putFunction(newItem));
			}
		} catch (error) {
			console.error('Error updating item:', error);
		}
	};

	const handleCheckboxChangeForAddress = async (index: number) => {
		const previousRelevantItem = addresses.find(item => item.pertinence === 1);

		const updatedList = addresses.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? 0 : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putAdresse);

		setAddresses(filterAndSortItems(updatedList, 'dateMaj'));
	};

	const handleCheckboxChangeForEmail = async (index: number) => {
		const previousRelevantItem = emails.find(item => item.pertinence === 1);

		const updatedList = emails.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? 0 : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putEmail);

		setEmails(filterAndSortItems(updatedList, 'dateMaj'));
	};

	const handleCheckboxChangeForTelephone = async (index: number) => {
		const previousRelevantItem = telephones.find(item => item.pertinence === 1);

		const updatedList = telephones.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? null : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putTelephone);

		setTelephones(filterAndSortItems(updatedList, 'dateMaj'));
	};

	useEffect(() => {
		if (referent) {
			setAddresses(filterAndSortItems(referent.adresses?.map(adresse => ({
				...adresse,
				coche: adresse.pertinence === 1,
				pertinence: adresse.pertinence ?? null
			})) || [], 'adresse1'));
			setEmails(filterAndSortItems(referent.emails?.map(email => ({
				...email,
				coche: email.pertinence === 1,
				pertinence: email.pertinence ?? null
			})) || [], 'email1'));
			setTelephones(filterAndSortItems(referent.telephones?.map(telephone => ({
				...telephone,
				coche: telephone.pertinence === 1,
				pertinence: telephone.pertinence ?? null
			})) || [], 'telephone1'));
		}
	}, [referent]);

	const columnWidths = {
		type: '10%',
		address: '35%',
		comment: '20%',
		source: '10%',
		dateMaj: '10%',
		actions: '10%',
		reference: '5%'
	};

	return (
		<>
			<Card elevation={4} className="mb-1">
				<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
					<CardHeader
						className="py-1 mb-1"
						title="Coordonnées du ménage"
						titleTypographyProps={{ className: 'u-t-size-19' }}
						action={
							<IconButton onClick={toggleContent} style={{ color: 'white' }}>
								{showContent ? <ExpandLessIcon /> : <AddIcon />}
							</IconButton>
						}
					/>
				</div>
				{showContent && (
					<CardContent className="pt-0">
						{/* ------------------------------ ADRESSES --------------------------------------------- */}
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Adresse(s) :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.address }}>Adresse</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme adresse de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{addresses.length ? (
												addresses.map((adresse: HAdresse, index) => (
													<Tooltip
														key={adresse.id}
														title="Adresse de référence"
														disableHoverListener={adresse.pertinence !== 1}
													>
														<tr style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.address }}>
																{concatAddresses(adresse.adresse1, adresse.adresse2, adresse.adresse3)}
															</td>
															<td style={{ width: columnWidths.comment }}>{adresse.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(adresse.type, adresse.source)}
															</td>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(adresse.type)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(adresse.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<Checkbox
																	checked={adresse.coche}
																	onChange={() => handleCheckboxChangeForAddress(index)}
																	inputProps={{ 'aria-label': 'Adresse de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{adresse.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditAdresse(adresse)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveAdresse(adresse)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>
														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucune adresse</i>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditAdresse({
											id: 0,
											referent: referent?.id,
											adresse1: '',
											adresse2: '',
											adresse3: '',
											commentaire: '',
											source: '',
											pertinence: 0, // Ajout de pertinence par défaut pour une nouvelle adresse
											dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour une nouvelle adresse
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editAdresse !== null} onClose={() => setEditAdresse(null)} maxWidth={'md'}>
								{editAdresse && (
									<CrudAdresseEdit
										referent={referent as Referent}
										value={editAdresse}
										onSave={() => {
											setEditAdresse(null);
											refresh();
										}}
										onClose={() => setEditAdresse(null)}
										currentUser={currentUser}
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeAdresse}
								onChange={() => setRemoveAdresse(null)}
								title="Suppression d'une adresse"
								message={`Êtes-vous sûr de vouloir supprimer cette adresse ?`}
								onConfirm={async () => {
									if (removeAdresse) {
										await dispatch(deleteAdresse(removeAdresse.id));
										setRemoveAdresse(null);
										refresh();
									}
								}}
							/>
						</Grid>
						{/* ------------------------------ EMAILS ----------------------------------------------- */}
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Email(s) :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.address }}>Email</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme email de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{emails.length ? (
												emails.map((email: HEmail, index) => (
													<Tooltip
														key={email.id}
														title="Email de référence"
														disableHoverListener={email.pertinence !== 1}
													>
														<tr key={email.id} style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(email.type)}
															</td>
															<td style={{ width: columnWidths.address }}>{email.email1}</td>
															<td style={{ width: columnWidths.comment }}>{email.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(email.type, email.source)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(email.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<Checkbox
																	checked={email.coche}
																	onChange={() => handleCheckboxChangeForEmail(index)}
																	inputProps={{ 'aria-label': 'Email de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{email.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditEmail(email)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveEmail(email)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>
														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucun email</i>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditEmail({
											id: 0,
											referent: referent?.id,
											email1: '',
											commentaire: '',
											source: '',
											pertinence: 0, // Ajout de pertinence par défaut pour un nouvel email
											dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour un nouvel email
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editEmail !== null} onClose={() => setEditEmail(null)} maxWidth={'md'}>
								{editEmail && (
									<CrudEmailEdit
										referent={referent as Referent}
										value={editEmail}
										onSave={() => {
											setEditEmail(null);
											refresh();
										}}
										onClose={() => setEditEmail(null)}
										currentUser={currentUser}
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeEmail}
								onChange={() => setRemoveEmail(null)}
								title="Suppression d'un email"
								message={`Êtes-vous sûr de vouloir supprimer cet email ?`}
								onConfirm={async () => {
									if (removeEmail) {
										await dispatch(deleteEmail(removeEmail.id));
										setRemoveEmail(null);
										refresh();
									}
								}}
							/>
						</Grid>
						{/* ------------------------------ TELEPHONES ------------------------------------------- */}
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Numéro(s) de téléphone :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.address }}>Numéro</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme téléphone de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{telephones.length ? (
												telephones.map((telephone: HTelephone, index) => (
													<Tooltip
														key={telephone.id}
														title="Téléphone de référence"
														disableHoverListener={telephone.pertinence !== 1}
													>
														<tr key={telephone.id} style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(telephone.type)}
															</td>
															<td style={{ width: columnWidths.address }}>{telephone.telephone1}</td>
															<td style={{ width: columnWidths.comment }}>{telephone.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(telephone.type, telephone.source)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(telephone.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<Checkbox
																	checked={telephone.coche}
																	onChange={() => handleCheckboxChangeForTelephone(index)}
																	inputProps={{ 'aria-label': 'Téléphone de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{telephone.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditTelephone(telephone)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveTelephone(telephone)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>
														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucun téléphone</i>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditTelephone({
											id: 0,
											referent: referent?.id,
											telephone1: '',
											commentaire: '',
											source: '',
											pertinence: 0, // Ajout de pertinence par défaut pour un nouveau téléphone
											dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour un nouveau téléphone
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editTelephone !== null} onClose={() => setEditTelephone(null)} maxWidth={'md'}>
								{editTelephone && (
									<CrudTelephoneEdit
										referent={referent as Referent}
										value={editTelephone}
										onSave={() => {
											setEditTelephone(null);
											refresh();
										}}
										onClose={() => setEditTelephone(null)}
										currentUser={currentUser}
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeTelephone}
								onChange={() => setRemoveTelephone(null)}
								title="Suppression d'un téléphone"
								message={`Êtes-vous sûr de vouloir supprimer ce téléphone ?`}
								onConfirm={async () => {
									if (removeTelephone) {
										await dispatch(deleteTelephone(removeTelephone.id));
										setRemoveTelephone(null);
										refresh();
									}
								}}
							/>
						</Grid>
					</CardContent>
				)}
			</Card>
		</>
	);
}

//_______________________ Sous formulaire Contact du ménage _________________________________________
export interface ContactProps {
	onRefresh: () => any;
}

function Contact({ onRefresh }: ContactProps) {
	const dispatch = useDispatch();
	const referent = hReferent(useSelector((state) => state.referent.referent));
	const [edit, setEdit] = useState<Nullable<HContact>>(null);
	const [remove, setRemove] = useState<Nullable<HContact>>(null);
	const [showContent, setShowContent] = useState(false);
	const filteredReferenta = referent?.id;
	const [typeAccompagnement, setTypeAccompagnement] = useState<string>('');

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	return (
		<Card elevation={4} className="mb-1">
			<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
				<CardHeader
					className="py-1 mb-1"
					title="Contacts du ménage"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <ExpandLessIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
			</div>
			{showContent && (
				<CardContent className="pt-0">
					<div style={{ overflow: 'auto' }}>
						{referent?.contacts?.length ? (
							<div style={{ overflow: 'auto' }}>
								<table className="c-Crud-Referent-Edit-suiviSocial" style={{ width: '100%' }}>
									<thead>
										<tr>
											<th style={{ width: '10%' }}>Date</th>
											<th style={{ width: '15%' }}>Type contact</th>
											<th style={{ width: '15%' }}>Interlocuteur</th>
											<th style={{ width: '30%' }}>Démarche</th>
											<th style={{ width: '20%' }}>Synthèse</th>
											<th style={{ width: '15%' }}>Date prochain rendez-vous</th>
											<th style={{ width: '10%' }}>Actions</th>
										</tr>
									</thead>
									<tbody>
										{referent.contacts.map((rp: HContact) => (
											<tr key={rp.id}>
												<td>{formatDate(dateToDash(rp.date))}</td>
												<td>{rp.type}</td>
												<td>{rp.interlocuteur}</td>
												<td>{rp.demarches}</td>
												<td>{rp.synthese}</td>
												<td>{formatDate(dateToDash(rp.dateProchainRdv))}</td>
												<td style={{ textAlign: 'center' }}>
													<IconButton onClick={() => setEdit(rp)}>
														<IconModeEdit />
													</IconButton>
													<IconButton onClick={() => setRemove(rp)}>
														<IconDelete />
													</IconButton>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<div className="u-t-center">
								<i>Aucun contact sur le ménage</i>
							</div>
						)}
					</div>
					<br />
					<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
						<Button
							variant="contained"
							onClick={() =>
								setEdit({
									date: new Date(),
									type: '',
									interlocuteur: '',
									synthese: '',
									demarches: '',
									dateProchainRdv: '',
								})
							}
						>
							+
						</Button>
					</Grid>
					<Dialog open={edit !== null} onClose={() => setEdit(null)} maxWidth={'md'}>
						<CrudContactEdit
							referent={filteredReferenta}
							contact={edit}
							onSave={() => {
								setEdit(null);
								onRefresh();
							}}
							onClose={() => setEdit(null)}
						/>
					</Dialog>

					<Confirm
						value={!!remove}
						onChange={() => setRemove(null)}
						title="Suppression d'un repérage"
						message={`Êtes-vous sûr de vouloir supprimer le repérage de ${dateToDash(remove?.date)} ?`}
						onConfirm={async () => {
							await dispatch(deleteContact(remove));
							onRefresh();
						}}
					/>
				</CardContent>
			)}
		</Card>
	);
}

export default function CrudReferentEdit({ onClose, onUpdate, onPrev, onNext, item }: CrudReferentEditProps) {
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const referent = useSelector((state) => state.referent.referent);
	const [loading, setLoading] = useState(false);
	const [etatAccompagnement, setEtatAccompagnement] = useState<string>('');
	const [typeAccompagnement, setTypeAccompagnement] = useState<string>('');
	const [dateEvalSociale, setDateEvalSociale] = useState<string>('');

	// Reference for the main container of the component
	const componentRef = useRef<HTMLDivElement>(null);

	// Function to handle close and reload the page
	const handleClose = () => {
		window.location.reload(); // Reloads the page when the form is closed
	};

	// Listener for the Escape key
	useEffect(() => {
		const handleKeyDown = (event: { key: string }) => {
			if (event.key === 'Escape') {
				handleClose();
			}
		};
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	const refresh = useCallback(() => {
		(async () => {
			setLoading(true);
			try {
				if (item) {
					await dispatch(findReferentById(item.id));
				}
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue', 'error');
			}
			setLoading(false);
		})();
	}, [dispatch, item, pushMessage]);

	useEffect(() => {
		refresh();
	}, [refresh]);

	const handlePrev = async () => {
		setLoading(true);
		try {
			await onPrev();
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur est survenue.', 'error');
		}
		setLoading(false);
	};

	const handleNext = async () => {
		setLoading(true);
		try {
			await onNext();
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur est survenue.', 'error');
		}
		setLoading(false);
	};

	// Handler function for typeAccompagnement change
	const handleTypeAccompagnementChange = (value: string) => {
		setTypeAccompagnement(value);
	};

	const handleEtatAccompagnementChange = (value: string) => {
		setEtatAccompagnement(value);
	};

	const handleDateEvalSociale = (value: string) => {
		setDateEvalSociale(value);
	};

	return (
		<Dialog
			open={true}
			fullScreen
			onClose={handleClose}
			PaperProps={{
				style: {
					backgroundColor: '#fff',
				},
			}}
		>
			{referent ? (
				<div
					ref={componentRef}
					className="c-Crud-Referent-Edit"
					style={{ height: '100%', width: '100%' }}
				>
					<Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
						<CardContent
							style={{
								flex: '1 1 auto',
								overflow: 'auto',
							}}
						>
							<Card
								sx={{
									p: 2,
									mb: 2,
									overflow: 'hidden',
									boxShadow: 3,
								}}
							>
								<Grid container spacing={2}>
									<Grid xs={12}>
										<Typography variant="h6" sx={{ mb: 2 }}>
											Ménage: {`${item?.id || ''} - ${item?.nomProprietaire || ''}`}
										</Typography>
									</Grid>
									<Grid xs={12} md={4} lg={4}>
										<Table size="small">
											<TableBody>
												<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
													<TableCell
														component="th"
														scope="row"
														sx={{
															fontWeight: 'bold',
															backgroundColor: '#f5f5f5',
															boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
															textAlign: 'right',
														}}
													>
														Type :
													</TableCell>
													<TableCell sx={{ textAlign: 'center' }}>{item?.typeProprietaire}</TableCell>
												</TableRow>
												<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
													<TableCell
														component="th"
														scope="row"
														sx={{
															fontWeight: 'bold',
															backgroundColor: '#f5f5f5',
															boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
															textAlign: 'right',
														}}
													>
														Solde SDC :
													</TableCell>
													<TableCell sx={{ textAlign: 'center' }}>{item?.soldeSdc}</TableCell>
												</TableRow>
												<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
													<TableCell
														component="th"
														scope="row"
														sx={{
															fontWeight: 'bold',
															backgroundColor: '#f5f5f5',
															boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
															textAlign: 'right',
														}}
													>
														Solde SP :
													</TableCell>
													<TableCell sx={{ textAlign: 'center' }}>{item?.soldeSp}</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</Grid>
								</Grid>
							</Card>
							{/* Include your form fields here */}
							<Part9 onRefresh={refresh} />
							<Contact onRefresh={refresh} />
							<ActionSociale onRefresh={refresh} />
							<EvaluationSocialeInfos
								onRefresh={refresh}
								onTypeAccompagnement={handleTypeAccompagnementChange}
								onEtatAccompagnement={handleEtatAccompagnementChange}
								onDateEvalSociale={handleDateEvalSociale}
							/>
						</CardContent>
						<CardActions className="u-d-block">
							<Button onClick={handleClose}>Fermer</Button>
							<LoadingButton style={{ float: 'right' }} type="button" loading={loading} onClick={handleNext}>
								Suivant
							</LoadingButton>
							<LoadingButton style={{ float: 'right' }} type="button" loading={loading} onClick={handlePrev}>
								Précédent
							</LoadingButton>
						</CardActions>
					</Card>
				</div>
			) : (
				<div className="p-4 u-t-center">
					<CircularProgress size={150} thickness={2} />
				</div>
			)}
		</Dialog>
	);
}

function setLoading(arg0: boolean) {
	throw new Error('Function not implemented.');
}
