import { buildModelHelper } from '@/libs/modelHelper';

export interface TriDia {
	id: number;
	acquisition: Nullable<number>;
	date: Nullable<string>;
	dateLimitePreemption: Nullable<string>;
	montantm2: Nullable<number>;
	prixPreemptionm2: Nullable<number>;
}

export const hTriDia = buildModelHelper<TriDia>({});

export type HTriDia = ReturnType<typeof hTriDia>;
