import './View.scss';
import { Card, CardContent, Container, IconButton, Skeleton, Grid, TableRow } from '@mui/material';
import { useConnectedPage, useEffectFirstRender } from '@/libs/hooks';
import { useDispatch, useSelector } from '@/libs/redux';
import { findUserById } from '@/stores/user';
import IconArrowBack from '@mui/icons-material/ArrowBack';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import IconCheck from '@mui/icons-material/Check';
import { hUser } from '@/models';
import { PhoneHelper } from '@/libs/utils/PhoneHelper';

export default function PageUsersView() {

	useConnectedPage();

	const dispatch = useDispatch();
	const user = hUser(useSelector(state => state.user.user));
	const { id } = useParams();
	const [notFound, setNotFound] = useState(false);

	useEffectFirstRender(() => {
		(async () => {
			setNotFound(false);
			setNotFound(!(await dispatch(findUserById(+(id as any)))));
		})();
	}, [id]);

	return notFound ? (
		<div className="u-t-center p-4">
			Utilisateur non trouvé
		</div>
	) :
		(
			<Container className="p-habilitation-users-View">

				<h2>
					<IconButton color="default" href="/habilitation/users">
						<IconArrowBack />
					</IconButton>
					&nbsp;Utilisateur {user?.fullName}
				</h2>


				<Grid container spacing={2}>

					<Grid
						item
						xs={12} md={6}
					>
						<Card className="mb-1">
							<CardContent>
								{user ? (
									<Table>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Nom         </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.firstName}</TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Prénom      </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.lastName}</TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Structure   </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.structure}</TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Mail        </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.email}</TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Téléphone 1 </strong></TableCell><TableCell sx={{ padding: '4px' }}><span className="u-t-nowrap">{PhoneHelper.format(user.phone)}</span></TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Téléphone 2 </strong></TableCell><TableCell sx={{ padding: '4px' }}><span className="u-t-nowrap">{PhoneHelper.format(user.phon2)}</span></TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Mission   </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.mission}</TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Fonction  </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.fonction}</TableCell></TableRow>
									</Table>
								) : (
									<Skeleton variant="rounded" height={350} />
								)}
							</CardContent>
						</Card>

						<Card className="mb-1">
							<CardContent>
								{user ? (
									<Table>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Admin </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.hasRole('ROLE_ADMIN') && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Fourn </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.hasRole('ROLE_FOURN') && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Syndic</strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.hasRole('ROLE_SYNDIC') && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>SDC   </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.hasRole('ROLE_SDC') && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Immo  </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.hasRole('ROLE_IMMO') && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>AS    </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.hasRole('ROLE_AS') && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Obs   </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.hasRole('ROLE_OBS') && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Liq   </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.hasRole('ROLE_LIQ') && <IconCheck color="success" />}</TableCell></TableRow>
									</Table>
								) : (
									<Skeleton variant="rounded" height={350} />
								)}
							</CardContent>
						</Card>
					</Grid>

					<Grid
						item
						xs={12} md={6}
					>
						<Card className="mb-1">
							<CardContent>
								{user ? (
									<Table>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Charte Utilisateur </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.charte ? new Date(user.charte).toLocaleDateString() : 'Non Signée'}</TableCell></TableRow>
										<TableRow><TableCell sx={{ padding: '4px' }}><strong>Licence Power BI </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user?.pbi && <IconCheck color="success" />}</TableCell></TableRow>
									</Table>
								) : (
									<Skeleton variant="rounded" height={350} />
								)}
							</CardContent>
						</Card>
						<Card className="mb-1">
							<CardContent>
								{user ? (
									<Table>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>SP </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('SP') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}></TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR11 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR11') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR12 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR12') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR13 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR13') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR14 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR14') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR15 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR15') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR16 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR16') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR17 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR17') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR18 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR18') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR21 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR21') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR24 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR24') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR25 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR25') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR26 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR26') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR27 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR27') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR28 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR28') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR29 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR29') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR30 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR30') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR31 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR31') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR33 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR33') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR41 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR41') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR42 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR42') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR43 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR43') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR44 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR44') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR45 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR45') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR46 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR46') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR47 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR47') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR48 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR48') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR49 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR49') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR79 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR79') && <IconCheck color="success" />}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ padding: '4px' }}><strong>TR80 </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR80') && <IconCheck color="success" />}</TableCell>
											<TableCell sx={{ padding: '4px' }}><strong>TR8X </strong></TableCell><TableCell sx={{ padding: '4px' }}>{user.hasSyndicat('TR8X') && <IconCheck color="success" />}</TableCell>
										</TableRow>
									</Table>
								) : (
									<Skeleton variant="rounded" height={350} />
								)}
							</CardContent>
						</Card>
					</Grid>

				</Grid>

			</Container>
		)
		;
}


PageUsersView.getMetadata = () => {
	return {
		rights: ['HABILITATION']
	}
}
