import { TypeProprietaire } from '@/models/TypeProprietaire';
import { buildModelHelper } from '@/libs/modelHelper';
import { Adresse, HAdresse, hAdresse } from '@/models/Adresse';
import { hTelephone, HTelephone, Telephone } from '@/models/Telephone';
import { Email, hEmail, HEmail } from '@/models/Email';
import { HIndividu, hIndividu, Individu } from '@/models/Individu';
import { ChargesMenage } from '@/models/ChargesMenage';
import { HSuiviSocial, hSuiviSocial, SuiviSocial } from '@/models/SuiviSocial';
import { HContact, hContact, Contact } from '@/models/Contact';
import { HActionSociale, hActionSociale, ActionSociale } from  '@/models/ActionSociale';
import { AccompagnementSocial, HAccompagnementSocial, hAccompagnementSocial } from '@/models/AccompagnementSocial';

export interface Referent {
	id: string;
	nom: Nullable<string>;
	typeReferent: Nullable<TypeProprietaire>;
	libre1: Nullable<string>;
	libre2: Nullable<string>;
	recouvSortie: Nullable<number>;
	recouvSortieMotif: Nullable<string>;
	dateClassementIrrecouvrable: Nullable<string>;
	recouvDateRevoyure: Nullable<string>;
	recouvCasParticulier: Nullable<boolean>;
	adresses: Adresse[];
	emails: Email[];
	telephones: Telephone[];
	individus: Individu[];
	charges: ChargesMenage;
	suiviSocials: SuiviSocial[];
	actionSociale: ActionSociale[];
	accompagnementSocial: AccompagnementSocial;
	contact: Contact[];
}


export const hReferent = buildModelHelper<Referent>({

	get emails(): HEmail[] {
		return this._self.emails?.map((e: Email) => hEmail(e));
	},
	get adresses(): HAdresse[] {
		return this._self.adresses?.map((a: Adresse) => hAdresse(a));
	},
	get telephones(): HTelephone[] {
		return this._self.telephones?.map((t: Telephone) => hTelephone(t));
	},
	get individus(): HIndividu[] {
		return this._self.individus?.map((i: Individu) => hIndividu(i));
	},
	get suiviSocials(): HSuiviSocial[] {
		return this._self.suiviSocials?.map((e: SuiviSocial) => hSuiviSocial(e));
	},
	get contacts(): HContact[] {
		return this._self.contacts?.map((e: Contact) => hContact(e));
	},
	get currentAddress(): Nullable<HAdresse> {
		return hAdresse(this.adresses?.[0] || null);
	},
	get currentEmail(): Nullable<HEmail> {
		return hAdresse(this.emails?.[0] || null);
	},
	get currentTelephone(): Nullable<HTelephone> {
		return hAdresse(this.telephones?.[0] || null);
	},
	get accompagnementSocial(): Nullable<HAccompagnementSocial> {
		return hAccompagnementSocial(this._self.accompagnementSocial || null);
	},
	get actionSociale(): Nullable<HActionSociale> {
		return hActionSociale(this._self.actionSociale || null);
	}
});
export type HReferent = ReturnType<typeof hReferent>;
