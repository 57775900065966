import './View.scss'

function PageStatistiques() {
    return (
        <div className='Base'>
            <h1>Statistiques</h1>
            <div className='Title'>
                <h3 className='multiple-Title'>Graphique Récapitulatif</h3>
                <h3 className='multiple-Title'>Cartes des visiteurs</h3>
            </div>
            <div className='frame'>
                <iframe
                    width="50%"
                    height="800"
                    src={`https://${process.env.REACT_APP_MATOMO_URL}/index.php?module=Widgetize&action=iframe&containerId=VisitOverviewWithGraph&disableLink=1&widget=1&moduleToWidgetize=CoreHome&actionToWidgetize=renderWidgetContainer&idSite=1&period=day&date=today`}
                    scrolling="no">
                </iframe>

                <iframe
                    width="50%"
                    height="800"
                    src={`https://${process.env.REACT_APP_MATOMO_URL}/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=UserCountryMap&actionToWidgetize=visitorMap&idSite=1&period=day&date=today`}
                    scrolling="no">
                </iframe>
            </div>
            <div className='Title'>
                <h3 className='multiple-Title'>Pages</h3>
                <h3 className='multiple-Title'>Visite par Heure (Horaire Local)</h3>
            </div>
            <div className='frame'>
                <iframe
                    width="55%"
                    height="400"
                    src={`https://${process.env.REACT_APP_MATOMO_URL}/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=Actions&actionToWidgetize=getPageUrls&idSite=1&period=day&date=today`}
                    scrolling="yes">
                </iframe>
                <iframe
                    width="45%"
                    height="400"
                    src={`https://${process.env.REACT_APP_MATOMO_URL}/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=VisitTime&actionToWidgetize=getVisitInformationPerLocalTime&idSite=1&period=day&date=today`}
                    scrolling="no">
                </iframe>
            </div>
            <div className='Title'>
                <h3 className='single-Title'>Nombre de visiteurs Actuel</h3>
            </div>
            <iframe
                className='frame'
                width="25%"
                height="220"
                src={`https://${process.env.REACT_APP_MATOMO_URL}/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=Live&actionToWidgetize=getSimpleLastVisitCount&idSite=1&period=day&date=today`}
                scrolling="no">
            </iframe>
        </div >


    );
};

export default PageStatistiques;

PageStatistiques.getMetadata = () => {
    return {
        rights: ['HABILITATION_STATISTIQUES'],
        title: 'Statistiques'
    }
}
