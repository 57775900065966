export function toTextMonth(month?: Nullable<number>): string {
	if (month) {
		const date = new Date(2000, month - 1);
		return new Intl.DateTimeFormat('fr-FR', { month: 'long' }).format(date);
	}
	return '';
}

export function dateToDash(date?: Nullable<Date>): string {
	if (date) {
		return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
	}
	return '';
}

export function toDate(value: number | Date | null | string, locale: string = 'fr', options: any = {}): string {
	if (!value) {
		return '';
	}
	const dateValue = typeof value === 'string' ? new Date(value) : value;
	return new Intl.DateTimeFormat(locale, options).format(dateValue);
}

export function reverseDate(date: Date | null): string {
	if (!date) {
		return '';
	}
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	return `${year}/${month}/${day}`;
}

export function formatDate(dateString: string | null | undefined): string {
	// Vérifie si dateString est valide
	if (!dateString || isNaN(new Date(dateString).getTime())) {
		return ''; // Retourne une chaîne vide si la date est invalide ou manquante
	}
	// Options de formatage de la date
	const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
	return new Date(dateString).toLocaleDateString('fr-FR', options);
}

export function formatDateTime(dateString: string | null | undefined): string {
	// Vérifie si dateString est valide
	if (!dateString || isNaN(new Date(dateString).getTime())) {
		return ''; // Retourne une chaîne vide si la date est invalide ou manquante
	}

	const date = new Date(dateString);

	// Récupère les composantes de la date
	const day = String(date.getUTCDate()).padStart(2, '0');
	const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
	const year = date.getUTCFullYear();
	const hours = String(date.getUTCHours()).padStart(2, '0');
	const minutes = String(date.getUTCMinutes()).padStart(2, '0');
	const seconds = String(date.getUTCSeconds()).padStart(2, '0');

	// Retourne la date formatée en "jj/mm/aaaa hh:mm:ss"
	return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}
