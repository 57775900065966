import { RouteObject } from 'react-router';
import UsersIndex from '@/pages/habilitation/users/Index/Index';
import UsersView from '@/pages/habilitation/users/View/View';
import Tokens from '@/pages/habilitation/Tokens/Tokens';
import LogModeration from '@/pages/habilitation/LogModeration/View/View';
import PageFeedbackView from '@/pages/habilitation/Feedbacks/View/View';
import PageStatistiques from '@/pages/habilitation/Statistiques/View/View';

export const habilitation: RouteObject[] = [
	{
		path: '/habilitation/users',
		element: <UsersIndex />
	},
	{
		path: '/habilitation/users/:id',
		element: <UsersView />
	},
	{
		path: '/habilitation/tokens',
		element: <Tokens />
	},
	{
		path: '/habilitation/logs',
		element: <LogModeration />
	},
	{
		path: '/habilitation/feedbacks',
		element: <PageFeedbackView />
	},
	{
		path: '/habilitation/statistiques',
		element: <PageStatistiques />
	},
];
