import { Card, CardActions, CardContent, CardHeader, FormControl, InputLabel } from '@mui/material';
import { HContact, Referent, Contact } from '@/models';
import { useState } from 'react';
import { useForm } from '@/libs/form';
import { useDispatch } from '@/libs/redux';
import { LoadingButton } from '@mui/lab';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import TextField from '@/components/inputs/TextField/TextField';
import { postContact, putContact } from '@/stores/contact';
import { useValidators } from '@/libs/validators';
import DatePicker from '@/components/inputs/DatePicker/DatePicker';
import { Select, MenuItem } from '@mui/material';

export interface CrudContactEditProps {
	referent: Referent;
	contact: HContact;
	onSave: (contact: Contact) => any;
	onClose: () => any;
}

export function CrudContactEdit({
	referent,
	contact,
	onSave,
	onClose,
}: CrudContactEditProps) {
	const dispatch = useDispatch();
	const validators = useValidators();
	const [date, setDate] = useState<Nullable<Date>>(contact?.date || null);
	const [type, setType] = useState<Nullable<string>>(contact?.type || '');
	const [interlocuteur, setInterlocuteur] = useState<Nullable<string>>(contact?.interlocuteur || '');
	const [synthese, setSynthese] = useState<Nullable<string>>(contact?.synthese || '');
	const [demarches, setDemarches] = useState<Nullable<string>>(contact?.demarches || '');
	const [dateProchainRdv, setDateProchainRdv] = useState<Nullable<Date>>(contact?.dateProchainRdv || null);

	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				const newContact = {
					...(contact || {}),
					date: date ? date.toISOString() : null,
					type,
					interlocuteur,
					synthese,
					demarches,
					dateProchainRdv: dateProchainRdv ? dateProchainRdv.toISOString() : null,
				};
				if (newContact.id) {
					const result = await dispatch(putContact(newContact));
					onSave(result);
				} else {
					const result = await dispatch(postContact(referent, newContact));
					onSave(result);
				}
			});
		},
		fields: {
			date: {
				...model(date, setDate),
				rules: [validators.required()],
				label: 'Date',
			},
			interlocuteur: {
				...model(interlocuteur, setInterlocuteur),
				label: 'Interlocuteur',
			},
			type: {
				...model(type, setType),
				label: 'Type de contact',
			},
			synthese: {
				...model(synthese, setSynthese),
				label: 'Synthèse',
			},
			demarches: {
				...model(demarches, setDemarches),
				label: 'Démarches',
			},
			dateProchainRdv: {
				...model(dateProchainRdv, setDateProchainRdv),
				label: 'Date du prochain rendez-vous',
			},
		},
	});

	return (
		<Form form={form}>
			<Card>
				<CardHeader
					title="Édition du contact"
					className="CardHeader--primary"
				/>
				<CardContent>
					<FormMainErrors form={form} />
					<Grid container spacing={1}>
						<Grid xs={12} md={4}><DatePicker {...form.fields.date} /></Grid>
						<Grid xs={12} md={4}>
							<FormControl fullWidth>
								<InputLabel id="type-contact-label">Type de contact</InputLabel>
								<Select
									labelId="type-contact-label"
									{...form.fields.type}
									value={type}
									onChange={(e) => setType(e.target.value as string)} // Conversion de type ici
									label="Type de contact"
								>
									<MenuItem value="Mise à disposition">Mise à disposition</MenuItem>
									<MenuItem value="Mail">Mail</MenuItem>
									<MenuItem value="Appel téléphonique">Appel téléphonique</MenuItem>
									<MenuItem value="Porte à porte">Porte à porte</MenuItem>
									<MenuItem value="RDV / Permanences">RDV / Permanences</MenuItem>
									<MenuItem value="Visite à domicile">Visite à domicile</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid xs={12} md={4}>
							<FormControl fullWidth>
								<InputLabel id="type-contact-label">Interlocuteur</InputLabel>
								<Select
									labelId="type-contact-label"
									{...form.fields.interlocuteur}
									value={interlocuteur}
									onChange={(e) => setInterlocuteur(e.target.value as string)} // Conversion de type ici
									label="Interlocuteur"
								>
									<MenuItem value="Ménage">Ménage</MenuItem>
									<MenuItem value="Partenaires">Partenaires</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid xs={12}><TextField multiline rows={7} {...form.fields.demarches} /></Grid>
						<Grid xs={12}><TextField multiline rows={7} {...form.fields.synthese} /></Grid>
						<Grid xs={12} md={12}></Grid>
						<Grid xs={12} md={4}><DatePicker {...form.fields.dateProchainRdv} /></Grid>
					</Grid>
				</CardContent>
				<CardActions className="u-d-block">
					<LoadingButton
						loading={form.loading}
						onClick={onClose}
					>
						Fermer
					</LoadingButton>
					<LoadingButton
						type="submit"
						color="primary"
						variant="contained"
						loading={form.loading}
						style={{ float: 'right' }}
					>
						Valider
					</LoadingButton>
				</CardActions>
			</Card>
		</Form>
	);
}
