import './Synoptique.scss';
import { Button, Tooltip } from '@mui/material';
import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { findAllAnnuaireLogements } from '@/stores/synoptique';
import { hAnnuaireLogement } from '@/models';
import { findAllColorSchemes } from '@/stores/colorscheme';
import { useDispatch, useSelector } from 'react-redux';
import { usePageable, useSnackMessage } from '@/libs/hooks';
import { findEtatMajs } from '@/stores/etatMaj';
import { processData } from '../../../../../src/components/Synoptique/Synoptiques';
import pdfGenerator from '@/libs/pdfGenerator';
import { MyDocument } from '../../../../../src/components/Synoptique/SynoptiqueTableau';
import MainLoader from '@/components/MainLoader/MainLoader';

// Déclaration des variables d'état pour les filtres
export interface PageSynoptiqueItemProps {
	item: any,
	color: any
	Majs: any
}

export default function Synoptique() {
	// Référence pour le contenu HTML à convertir en PDF
	const contentRef = useRef<HTMLDivElement>(null);
	const [buttonVisible, setButtonVisible] = useState(true);
	const annuaireLogements = useSelector((state: any) => state.synoptique?.annuaireLogements);
	const ColorScheme = useSelector((state: any) => state.colorscheme?.colorSchemes);
	const EtatsMajs = useSelector((state: any) => state.etatMaj?.etatMajs);
	const pageable = usePageable();
	const dispatch = useDispatch();
	const buttonRef = useRef(null);
	const [selectedTranche, setSelectedTranche] = useState('TR11');
	const [selectedAdresse, setSelectedAdresse] = useState('');
	const [selectedEtatMajs, setSelectedEtatMajs] = useState({});
	const pushMessage = useSnackMessage();

	// Fonction pour rafraîchir les données
	const refresh = useCallback(() => {
		(async () => {
			try {
				await dispatch<any>(findAllAnnuaireLogements());
				await dispatch<any>(findAllColorSchemes());
				await dispatch<any>(findEtatMajs(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage));
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [])

	// Utiliser useEffect pour appeler la fonction refresh au montage du composant
	useEffect(() => {
		refresh();
	}, [refresh]);

	useEffect(() => {
		if (annuaireLogements) {
			processData(annuaireLogements);
		}
	}, [annuaireLogements]);

	const handleTrancheChange = (event: { target: { value: any; }; }) => {
		const newTranche = event.target.value;
		setSelectedTranche(newTranche);
		if (Array.isArray(EtatsMajs.data)) {
			const etatMaj = EtatsMajs.data.find((etatMaj: { syndicat: any; }) => etatMaj.syndicat === newTranche);
			setSelectedEtatMajs(etatMaj || {});
			// console.log(etatMaj)
		}
		setSelectedAdresse('');
	};

	const handleAdresseChange = (event: { target: { value: SetStateAction<string>; }; }) => {
		setSelectedAdresse(event.target.value);
	};

	return (
		<div ref={contentRef} className="p-documentaire-acteurs-Synoptique">
			{/* Bouton pour générer le PDF */}
			<Tooltip
				disableFocusListener
				arrow
				placement="right"
				title="Veuillez sélectionner une tranche et une adresse de bâtiment pour générer le PDF"
				PopperProps={{
					anchorEl: buttonRef.current
				}}
			>
				<span>
					<Button onClick={() => pdfGenerator.generatePDF(contentRef.current, setButtonVisible, selectedEtatMajs)} disabled={!selectedAdresse} variant='contained' className='PdfBtn' style={{ display: buttonVisible ? 'block' : 'none' }}>Générer PDF</Button>
				</span>
			</Tooltip>
			{/* Affichage des données ou message de chargement */}
			{annuaireLogements && ColorScheme && EtatsMajs ? (
				<MyDocument
					hide={buttonVisible}
					item={annuaireLogements}
					color={ColorScheme}
					Majs={EtatsMajs}
					selectedTranche={selectedTranche}
					selectedAdresse={selectedAdresse}
					handleTrancheChange={handleTrancheChange}
					handleAdresseChange={handleAdresseChange} />
			) : (
				<div className='loaderPlacementSynoptique'>
					<MainLoader />
				</div>
			)}
		</div>
	);
}

Synoptique.getMetadata = () => {
	return {
		title: 'Synoptique',
		rights: ['OBSERVATOIRE_SYNOPTIQUES']
	}
}
