import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { useForm } from '@/libs/form';
import Form from '@/components/forms/Form/Form';
import { LoadingButton } from '@mui/lab';
import {
	Document,
	DocumentType,
	DocumentTypeMode,
	DocumentUpload,
	FileSlotPeriodicity,
	Upload,
	UserSyncicat
} from '@/models';
import { useEffect, useState } from 'react';
import CrudUserSyncicatSelect from '@/components/crud/userSyncicat/Select/Select';
import { model } from '@/libs/candy';
import { useValidators } from '@/libs/validators';
import Grid from '@mui/material/Unstable_Grid2';
import CrudFileSlotPeriodicitySelect from '@/components/crud/fileSlotPeriodicity/Select/Select';
import { useDispatch } from '@/libs/redux';
import { findDocumentTypeBySyndicatAndPeriodicity, setBySyndicatAndPeriodicity } from '@/stores/documentType';
import CrudDocumentUploadList from '@/components/crud/document/UploadList/UploadList';
import { postDocumentBetween, postDocumentDate, postDocumentYear, waitFinish } from '@/stores/upload';

export interface CrudDocumentUploadProps {
	onClose?: () => any,
	onUploaded?: (syndicat: Nullable<UserSyncicat>) => any,
	syndicat?: Nullable<UserSyncicat>,
	periodicity?: Nullable<FileSlotPeriodicity>,
	documentType?: Nullable<DocumentType>,
	date?: Nullable<Date>,
	date2?: Nullable<Date>,
	year?: Nullable<number>,
}

export default function CrudDocumentUpload(
	{
		onClose = () => { },
		onUploaded = () => { },
		documentType = null,
		date = null,
		date2 = null,
		year = null,
		...props
	}: CrudDocumentUploadProps
) {

	const dispatch = useDispatch();
	const validators = useValidators();
	const [syndicat, setSyndicat] = useState<Nullable<UserSyncicat>>(props.syndicat || null);
	const [periodicity, setPeriodicity] = useState<Nullable<FileSlotPeriodicity>>(props.periodicity || null);
	const [fileList, setFileList] = useState<DocumentUpload[]>([
		{
			documentType: documentType,
			file: null,
			date: date,
			date2: date2,
			year: year,
			progress: null,
			error: null,
			success: false,
		}
	]);

	const form = useForm({
		async handle() {

			await form.call(async () => {

				let i = 0;
				let hasError = false;
				for (const file of fileList) {
					try {

						if (file.success) {
							continue;
						}

						fileList[i].error = null;
						setFileList([...fileList]);

						let upload: Nullable<Upload> = null;

						const onProgress = (progress: number) => {
							fileList[i].progress = progress;
							setFileList([...fileList]);
						};

						if (file.documentType?.mode === DocumentTypeMode.START_END) {
							upload = await dispatch(postDocumentBetween(
								file.file as File,
								syndicat as UserSyncicat,
								periodicity as FileSlotPeriodicity,
								file.documentType as DocumentType,
								file.date as Date,
								file.date2 as Date,
								file.documentType.customRows,
								onProgress
							));
						}
						if (file.documentType?.mode === DocumentTypeMode.DATE) {
							upload = await dispatch(postDocumentDate(
								file.file as File,
								syndicat as UserSyncicat,
								periodicity as FileSlotPeriodicity,
								file.documentType as DocumentType,
								file.date2 as Date,
								file.documentType.customRows,
								onProgress
							));
						}
						if (file.documentType?.mode === DocumentTypeMode.YEAR) {
							upload = await dispatch(postDocumentYear(
								file.file as File,
								syndicat as UserSyncicat,
								periodicity as FileSlotPeriodicity,
								file.documentType as DocumentType,
								file.year as number,
								file.documentType.customRows,
								onProgress
							));
						}

						if (upload) {
							await dispatch(waitFinish(upload));
						}

						fileList[i].success = true;
						setFileList([...fileList]);

					} catch (e: any) {
						hasError = true;
						if (e.response?.status === 404) {
							fileList[i].error = 'Échéance dépassée';
						} else {
							fileList[i].error = e.response?.data?.message || 'Une erreur est survenue';
						}
						setFileList([...fileList]);
					}
					i++;
				}
				onUploaded(syndicat);

				if (!hasError) {
					onClose();
				}

			});
		},
		fields: {
			syndicat: {
				...model(syndicat, setSyndicat),
				rules: [
					validators.required()
				]
			},
			periodicity: {
				...model(periodicity, setPeriodicity),
				rules: [
					validators.required()
				]
			},
		}
	});

	useEffect(() => {
		if (syndicat && periodicity) {
			dispatch(findDocumentTypeBySyndicatAndPeriodicity(syndicat, periodicity));
		} else {
			dispatch(setBySyndicatAndPeriodicity(null));
		}
	}, [syndicat, periodicity])

	return (
		<div className="c-Crud-Document-Upload" >
			<Form form={form}>
				<Card>
					<CardHeader title="Envoi de documents" />
					<CardContent>

						<Card elevation={3} className="p-2 mb-2">
							<Grid container spacing={2}>

								<Grid xs={12} md={6}>
									<CrudUserSyncicatSelect
										{...form.fields.syndicat}
										label="Syndicat"
										filtered
										readOnly={form.loading}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<CrudFileSlotPeriodicitySelect
										{...form.fields.periodicity}
										label="Périodicité"
										readOnly={form.loading}
									/>
								</Grid>

							</Grid>
						</Card>

						{syndicat && periodicity && (
							<CrudDocumentUploadList
								{...model(fileList, setFileList)}
								form={form}
							/>
						)}

					</CardContent>
					<CardActions
						className="u-d-block"
					>
						<Button
							onClick={onClose}
						>
							Fermer
						</Button>
						<LoadingButton
							style={{ float: 'right' }}
							type="submit"
							loading={form.loading}
							variant="contained"
						>
							Envoyer
						</LoadingButton>
					</CardActions>
				</Card>
			</Form>
		</div>
	);
}
