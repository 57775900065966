import { Card, CardContent } from '@mui/material';
import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useEffectTimeout, usePropState } from '@/libs/hooks';
import { CardProps } from '@mui/material/Card/Card';
import { DocumentTypeType, UserSyncicat } from '@/models';
import CrudDocumentTypeSelect from '@/components/crud/documentType/Select/Select';
import CrudUserSyncicatSelect from '@/components/crud/userSyncicat/Select/Select';
import CrudDocumentTypeTypeTypeSelect from '@/components/crud/documentTypeType/Select/Select';
import TextField from '@/components/inputs/TextField/TextField';

export interface CrudDocumentFiltersProps extends CardProps {
	value?: Nullable<any>;
	onChange?: (value: Nullable<any>) => any,
}

export default function CrudDocumentFilters(
	{
		value,
		onChange,
		className = '',
		...rest
	}: CrudDocumentFiltersProps
) {

	const [filters, setFilters] = usePropState<any>(value, onChange, {
		syndicat: [],
		year: (new Date()).getFullYear(),
		documentTypeType: [],
		documentType: [],
	});

	const [syndicat, setSyndicat] = useState<UserSyncicat>(filters.syndicat || []);
	const [year, setYear] = useState<Nullable<number>>(filters.year || null);
	const [documentTypeType, setDocumentTypeType] = useState<Nullable<DocumentTypeType>>(filters?.documentTypeType || []);
	const [documentType, setDocumentType] = useState<number>(filters.documentType || []);

	useEffectTimeout(() => {
		if (
			filters.syndicat !== syndicat ||
			filters.year !== year ||
			filters.documentTypeType !== documentTypeType ||
			filters.documentType !== documentType
		) {
			setFilters({
				syndicat,
				year,
				documentTypeType,
				documentType,
			})
		}
	}, 500, [
		syndicat,
		year,
		documentTypeType,
		documentType,
	]);

	return (
		<Card
			{...rest}
			className={[
				className,
				'mb-1',
				'c-Crud-Document-Filters'
			].join(' ')}
		>
			<CardContent>
				<h5 className="my-0">Filtres :</h5>
				<Grid container spacing={1}>
					<Grid xs={12} md={3}>
						<CrudUserSyncicatSelect
							{...model(syndicat, setSyndicat)}
							multiple
							clearable
							size="small"
							variant="standard"
							label="Syndicats"
						/>
					</Grid>
					<Grid xs={12} md={3}>
						<CrudDocumentTypeTypeTypeSelect
							{...model(documentTypeType, setDocumentTypeType)}
							multiple
							clearable
							label="Types"
							size="small"
							variant="standard"
						/>
					</Grid>
					<Grid xs={12} md={3}>
						<CrudDocumentTypeSelect
							{...model(documentType, setDocumentType)}
							multiple
							clearable
							label="Documents"
							size="small"
							variant="standard"
						/>
					</Grid>
					<Grid xs={12} md={3}>
						<TextField
							{...model(year, setYear)}
							type="number"
							clearable
							label="Période"
							size="small"
							variant="standard"
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}
