import { buildModelHelper } from '@/libs/modelHelper';

export interface AccompagnementSocial {
	id: string;
	referentId: string;
	structureEnCharge: string;
	date: string | null;
	originePriseContact: string;
	planBdf: string;
	nombreDossiersDeposes: string;
	typeAccompagnement: string;
	protocole: string;
	fsl: string;
	fslVerse: number | null;
	actionLogement: string;
	ouvertureDroits: string;
	aidesExceptionnelles: string;
	insertionProfessionnelle: string;
	synthese: string;
	numeroSecu: string;
	numeroCaf: string;
	anneeAcquisition: string | null;
	prixAchat: number | null;
	montantCreditImmo: number | null;
	dureeCreditImmo: string | null;
	banqueCreditImmo: string;
	montantCreditConso: number | null;
	montantDetteFiscale: number | null;
	montantAutresDettes: number | null;

	// Champs manquants ajoutés
	numeroDls: string | null; // Numéro DLS
	redressement: string; // Redressement
	recyclage: string; // Recyclage
	dateEvalSociale: string | null; // Date évaluation sociale
	dateDernierBdf: string | null; // Date dernier BDF
	etatAccompagnement: string; // État de l'accompagnement
	compositionFamiliale: string | null; // Composition familiale
	ressourcesMensuelles: number | null; // Ressources mensuelles
	chargesMensuelles: number | null; // Charges mensuelles
	capitalDu: number | null; // Capital dû
	anneeFinCredit: string | null; // Année fin de crédit
	informationConfidentialite: Nullable<boolean>; // Information confidentialité
	orientation: string | null; 
	precisionsDonneesPersonnelles: string | null;
	precisionsDettes: string | null;
	precisionsAcquisition: string | null;
	precisionsSurendettement: string | null;
	dateMaj: Nullable<string>;
	typeAccompagnementAnterieur: string | null;
}

export const hAccompagnementSocial = buildModelHelper<AccompagnementSocial>({
	get date(): Nullable<Date> {
		return this._self.date ? new Date(this._self.date) : null;
	},
	get dateEvalSociale(): Nullable<Date> {
		return this._self.dateEvalSociale ? new Date(this._self.dateEvalSociale) : null;
	},
	get dateMaj(): Nullable<Date> {
		return this._self.dateMaj ? new Date(this._self.dateMaj) : null;
	},
	set dateEvalSociale(value: Nullable<Date>) {
		this._self.dateEvalSociale = value ? value.toISOString() : null;
	},
	get dateDernierBdf(): Nullable<Date> {
		return this._self.dateDernierBdf ? new Date(this._self.dateDernierBdf) : null;
	},
	set dateDernierBdf(value: Nullable<Date>) {
		this._self.dateDernierBdf = value ? value.toISOString() : null;
	},
	set dateMaj(value: Nullable<Date>) {
		this._self.dateMaj = value ? value.toISOString() : null;
	},
});

export type HAccompagnementSocial = ReturnType<typeof hAccompagnementSocial>;
