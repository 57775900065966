import { hUser, User, UserSyncicat } from '@/models';
import { Button, Card, CardActions, CardContent, CardHeader, Chip, Typography, Tooltip } from '@mui/material'; // Importez Tooltip
import { useState } from 'react';
import TextField from '@/components/inputs/TextField/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { useForm } from '@/libs/form';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import { model } from '@/libs/candy';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from '@/libs/redux';
import { useValidators } from '@/libs/validators';
import Checkbox from '@/components/inputs/Checkbox/Checkbox';
import { postUser, putUserById } from '@/stores/user';
import PhoneField from '@/components/inputs/PhoneField/PhoneField';
import './Edit.scss';
import { useTranslation } from 'react-i18next';

export interface CrudUserEditProps {
	value?: Nullable<User>;
	onClose: () => any,
	onUpdate: () => any,
}

export default function CrudUserEdit(
	{
		value = null,
		onClose,
		onUpdate,
	}: CrudUserEditProps
) {

	const hValue = hUser(value);
	const dispatch = useDispatch();
	const validators = useValidators();
	const { t } = useTranslation();

	const [structure, setStructure] = useState(value?.structure || '');
	const [mission, setMission] = useState(value?.mission || '');
	const [fonction, setFonction] = useState(value?.fonction || '');
	const [firstName, setFirstName] = useState(value?.firstName || '');
	const [lastName, setLastName] = useState(value?.lastName || '');
	const [email, setEmail] = useState(value?.email || '');
	const [phone, setPhone] = useState(value?.phone || '');
	const [phone2, setPhone2] = useState(value?.phone2 || '');
	const [roles, setRoles] = useState(hValue?.roles || []);
	const [syndicats, setSyndicats] = useState(hValue?.syndicats || []);
	const [tags, setTags] = useState<string[]>(value?.tags || []);
	const [currentTag, setCurrentTag] = useState<string>('');
	const [pbi, setPbi] = useState(!!hValue?.pbi);
	const [charte, setCharte] = useState(value?.charte || null);
	const addTag = () => {
		if (currentTag.trim() !== '') {
			setTags([...tags, currentTag]);
			setCurrentTag('');
		}
	};
	const removeTag = (index: number) => {
		const newTags = [...tags];
		newTags.splice(index, 1);
		setTags(newTags);
	};

	const form = useForm({
		handle: async () => {
			await form.call(async () => {

				const user: Partial<User> = {
					structure,
					mission,
					fonction,
					firstName,
					lastName,
					email,
					phone,
					phone2,
					roles,
					syndicats,
					tags,
					pbi,
					charte,
				};

				if (value) {
					await dispatch(putUserById(value.id, user));
				} else {
					await dispatch(postUser({ ...user, userStatus: 1 })); // Définit userStatus à 1 lors de la création
				}
				onUpdate();
				onClose();
			});
		},
		fields: {
			lastName: {
				label: 'Nom',
				...model(lastName, setLastName)
			},
			firstName: {
				label: 'Prénom',
				...model(firstName, setFirstName)
			},
			structure: {
				label: 'Structure',
				...model(structure, setStructure)
			},
			mission: {
				label: 'Mission',
				...model(mission, setMission)
			},
			fonction: {
				label: 'Fonction',
				...model(fonction, setFonction)
			},
			email: {
				label: 'Mail',
				...model(email, setEmail),
				rules: [
					validators.required(),
					validators.email()
				]
			},
			phone: {
				label: 'Téléphone 1',
				...model(phone, setPhone),
				rules: [
					validators.phone(),
				]
			},
			phone2: {
				label: 'Téléphone 2',
				...model(phone2, setPhone2),
				rules: [
					validators.phone(),
				]
			},
			pbi: {
				label: 'Licence Power BI',
				...model(pbi, setPbi),
			},
			charte: {
				label: 'Charte Utilisateur',
				...model(charte, setCharte),
			},
		}

	});

	return (
		<div className="c-Crud-User-Edit" >
			<Form form={form}>
				<Card>
					<CardHeader
						title={value ? (
							<>Édition de {hValue.fullName}</>
						) : (
							<>Création d'un utilisateur</>
						)}
					/>
					<CardContent
						style={{
							maxHeight: 'calc(100vh - 200px)',
							overflow: 'auto',
						}}
					>
						<FormMainErrors form={form} />

						<Grid container spacing={2}>

							<Grid
								xs={12} md={6}
							>
								<TextField {...form.fields.firstName} />
							</Grid>
							<Grid
								xs={12} md={6}
							>
								<TextField {...form.fields.lastName} />
							</Grid>

							<Grid
								xs={12} md={6}
							>
								<TextField {...form.fields.structure} />
							</Grid>
							<Grid
								xs={12} md={6}
							>
								{/* Ajoutez le Tooltip ici */}
								{value ? (
									<Tooltip title="Une fois un utilisateur créé, son adresse e-mail n'est pas modifiable car elle est unique. Si une modification est nécessaire, veuillez recréer l'utilisateur pour changer son adresse e-mail.">
										{/* Utilisez une balise <span> pour envelopper le champ désactivé */}
										<span>
											<TextField {...form.fields.email} disabled />
										</span>
									</Tooltip>
								) : (
									<TextField {...form.fields.email} />
								)}
							</Grid>

							<Grid
								xs={12} md={6}
							>
								<TextField {...form.fields.mission} />
							</Grid>

							<Grid
								xs={12} md={6}
							>
								<TextField {...form.fields.fonction} />
							</Grid>

							<Grid
								xs={12} md={6}
							>
								<PhoneField {...form.fields.phone} />
							</Grid>
							<Grid
								xs={12} md={6}
							>
								<PhoneField {...form.fields.phone2} />
							</Grid>
						</Grid>
						<Card className="mt-2">
							<CardContent className="py-1">
								<Grid container spacing={2}>
									<Grid xs={12} md={6} alignItems="center" display={"flex"} >
										<Typography variant="body1">
											Charte Utilisateur: {form.fields.charte.value ? new Date(form.fields.charte.value).toLocaleDateString() : 'Non Signée'}
										</Typography>
									</Grid>
									<Grid xs={12} md={6}>
										<Checkbox {...form.fields.pbi} />
									</Grid>
								</Grid>
							</CardContent>
						</Card>

						<Card className="mt-2">
							<CardContent>
								<Grid container spacing={2}>
									<Grid xs={3}><Checkbox label="Admin" item="ROLE_ADMIN"  {...model(roles, setRoles)} /></Grid>
									<Grid xs={3}><Checkbox label="Gestionnaire" item="ROLE_FOURN"  {...model(roles, setRoles)} /></Grid>
									<Grid xs={3}><Checkbox label="Syndic" item="ROLE_SYNDIC" {...model(roles, setRoles)} /></Grid>
									<Grid xs={3}><Checkbox label="Opérateur" item="ROLE_SDC"    {...model(roles, setRoles)} /></Grid>
									<Grid xs={3}><Checkbox label="Immo" item="ROLE_IMMO"   {...model(roles, setRoles)} /></Grid>
									<Grid xs={3}><Checkbox label="AS" item="ROLE_AS"     {...model(roles, setRoles)} /></Grid>
									<Grid xs={3}><Checkbox label="Obs" item="ROLE_OBS"    {...model(roles, setRoles)} /></Grid>
									<Grid xs={3}><Checkbox label="Liq" item="ROLE_LIQ"    {...model(roles, setRoles)} /></Grid>
								</Grid>
							</CardContent>
						</Card>

						<Card className="mt-2">
							<CardContent>
								<Grid container spacing={2}>
									{Object.values(UserSyncicat).map(syndicat => (
										<Grid xs={3} key={syndicat}>
											<Checkbox
												{...model(syndicats, setSyndicats)}
												label={t(`models.user_syndicat.values.${syndicat}`) || syndicat}
												item={syndicat}
											/>
										</Grid>
									))}
								</Grid>
							</CardContent>
						</Card>

						<Card className="mt-2">
							<CardContent>
								<Typography variant="h6">Tags</Typography>
								<Grid className="mt-1" container spacing={2}>
									{tags.map((tag, index) => (
										<Grid key={index}>
											<Chip label={tag} onDelete={() => removeTag(index)} />
										</Grid>
									))}
								</Grid>
								<Grid xs={12} className="mt-2">
									<TextField
										label="Entrez un nouveau tag"
										placeholder="Appuyez sur la virgule pour ajouter un nouveau tag"
										value={currentTag}
										onChange={(value) => setCurrentTag(value)}
										onKeyDown={(e) => {
											if ((e.key === ',' || e.key === 'Enter') && currentTag.trim() !== '') {
												e.preventDefault();
												addTag();
											}
										}}
									/>
								</Grid>
							</CardContent>
						</Card>
					</CardContent>
					<CardActions
						className="u-d-block"
					>
						<Button
							onClick={onClose}
						>
							Fermer
						</Button>
						<LoadingButton
							style={{ float: 'right' }}
							type="submit"
							loading={form.loading}
							variant="contained"
						>
							Enregistrer
						</LoadingButton>
					</CardActions>
				</Card>
			</Form>
		</div>
	)
}
