import React, { useState, useCallback } from 'react';
import './AccompagnementSocial.scss';
import ExcelJS from 'exceljs';
import { State } from '@/stores';
import { model } from '@/libs/candy';
import { useStore } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { toDate, UriHelper } from '@/libs/utils';
import IconDelete from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from '@/libs/redux';
import IconModeEdit from '@mui/icons-material/ModeEdit';
import TooltipElm from '@/components/Tooltip/TooltipElm';
import Confirm from '@/components/dialog/Confirm/Confirm';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import { useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { Button, CircularProgress, Container, Dialog, IconButton, Tooltip } from '@mui/material';

import AppBarPortal from '@/components/AppBarPortal/AppBarPortal';
import accompagnementSocial, { postAccompagnementSocial, deleteAccompagnementSocial } from '@/stores/accompagnementSocial';
import CrudReferentRecordEdit from '@/components/crud/referent/Edit/Edit';
import { CrudReferentSelect } from '@/components/crud/referent/Select/Select';
import { hTriEvalSociale, ResultType, TriEvalSociale, Referent } from '@/models';
import { findTriEvalSociales, setTriEvalSociales } from '@/stores/triEvalSociale';
import CrudTriEvalSocialeFilters from '@/components/crud/triEvalSociale/Filters/Filters';
import type { AccompagnementSocial, HAccompagnementSocial } from '@/models/AccompagnementSocial';
import referent from '@/stores/referent';

export interface PageUsersIndexUserProps {
	item: TriEvalSociale;
	onEdit: (item: TriEvalSociale) => any;
	onRemove: (item: TriEvalSociale) => any;
}

export function PageSaisieAccompagnementSocialItem({ item, onEdit, onRemove }: PageUsersIndexUserProps) {
	const evalSocial = hTriEvalSociale(item);

	return (
		<TableRow>
			<TableCell>
				<TooltipElm message={evalSocial.id || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					-
				</span>} />
			</TableCell>
			<TableCell>
				<TooltipElm message={evalSocial.nomProprietaire || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					-
				</span>} />
			</TableCell>
			<TableCell>
				<TooltipElm message={evalSocial.typeProprietaire || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					-
				</span>} />
			</TableCell>
			<TableCell>
				<div style={{ whiteSpace: 'initial' }}>{evalSocial.presenceSyndicats || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					-
				</span>}
				</div>
			</TableCell>
			<TableCell>
				<div style={{ textAlign: 'center' }}>
					<TooltipElm message={evalSocial.soldeSdc || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						-
					</span>} />
				</div>
			</TableCell>
			<TableCell>
				<div style={{ textAlign: 'center' }}>
					<TooltipElm message={evalSocial.soldeSp || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						-
					</span>} />
				</div>
			</TableCell>
			<TableCell>
				<TooltipElm message={evalSocial.typeAccompagnement || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					-
				</span>} />
			</TableCell>
			<TableCell>
				<TooltipElm message={evalSocial.dateEvalSociale ? toDate(evalSocial.dateEvalSociale) : <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					-
				</span>} />
			</TableCell>
			<TableCell className="u-cell-sticky-right u-d-flex">
				<Tooltip title="Editer">
					<IconButton color="primary" onClick={() => onEdit(item)}>
						<IconModeEdit />
					</IconButton>
				</Tooltip>
				<Tooltip title="Supprimer">
					<IconButton color="secondary" onClick={() => onRemove(evalSocial.id)}>
						<IconDelete />
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	);
}

export default function PageSaisieAccompagnementSocial() {
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const store = useStore();
	const triEvalSociales = useSelector((state: State) => state.triEvalSociale.triEvalSociales);
	const pageable = usePageable();
	const [edit, setEdit] = useState<Nullable<TriEvalSociale>>(null);
	const [selectReferentOpen, setSelectReferentOpen] = useState(false);
	const [selectedReferent, setSelectedReferent] = useState<Nullable<Referent>>(null);
	const [loading, setLoading] = useState(false);
	const [remove, setRemove] = useState<Nullable<TriEvalSociale>>(null);

	const [filters, setFilters] = useState({
		id: '',
		nomProprietaire: '',
		typeProprietaire: '',
		presenceSyndicats: '',
		structure: '',
		typeAccompagnement: '',
		dateEvalSociale: null,
		critereEvalSociale: '',
	});

	const refresh = useCallback(() => {
		(async () => {
			try {
				await dispatch(findTriEvalSociales(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage]);

	useEffectOnChange(() => {
		refresh();
	}, [refresh]);

	const waitReferents = async (): Promise<ResultType<TriEvalSociale>> => {
		const state = store.getState() as State;
		const triEvalSociales = state.triEvalSociale.triEvalSociales;
		if (triEvalSociales) {
			return triEvalSociales;
		}

		await new Promise(r => setTimeout(r, 50));
		return waitReferents();
	};

	const searchPrevPage = async (cPage: Nullable<number> = null) => {
		cPage = cPage !== null ? cPage : pageable.page;
		if (cPage - 1 < 0) {
			pushMessage('Vous avez atteint le premier élément modifiable', 'warning');
		} else {
			dispatch(setTriEvalSociales(null));
			pageable.setPage(cPage - 1);
			const triEvalSociales = await waitReferents();
			let newTriEvalSociale: Nullable<TriEvalSociale> = null;
			for (let i = triEvalSociales.data.length - 1; i >= 0; i--) {
				const item = triEvalSociales.data[i];
				newTriEvalSociale = item;
				break;
			}
			if (newTriEvalSociale) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newTriEvalSociale);
			} else {
				await searchPrevPage(cPage - 1);
			}
		}
	};

	const searchNextPage = async (cPage: Nullable<number> = null) => {
		cPage = cPage !== null ? cPage : pageable.page;
		dispatch(setTriEvalSociales(null));
		pageable.setPage(cPage + 1);
		const triEvalSociales = await waitReferents();
		if (!triEvalSociales?.data?.length) {
			dispatch(setTriEvalSociales(null));
			pageable.setPage(cPage);
			pushMessage('Vous avez atteint le dernier élément modifiable', 'warning');
		} else {
			let newTriEvalSociale: Nullable<TriEvalSociale> = null;
			for (let i = 0; i < triEvalSociales.data.length; i++) {
				const item = triEvalSociales.data[i];
				newTriEvalSociale = item;
				break;
			}
			if (newTriEvalSociale) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newTriEvalSociale);
			} else {
				await searchNextPage(cPage + 1);
			}
		}
	};

	const handlePrev = async () => {
		if (triEvalSociales?.data && edit) {
			const currentIndex = triEvalSociales.data.map(r => r.id).indexOf(edit.id);
			let newTriEvalSociale: Nullable<TriEvalSociale> = null;
			for (let i = currentIndex - 1; i >= 0; i--) {
				const item = triEvalSociales.data[i];
				newTriEvalSociale = item;
				break;
			}

			if (newTriEvalSociale) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newTriEvalSociale);
			} else {
				await searchPrevPage();
			}
		}
	};

	const handleNext = async () => {
		if (triEvalSociales?.data && edit) {
			const currentIndex = triEvalSociales.data.map(r => r.id).indexOf(edit.id);
			let newTriEvalSociale: Nullable<TriEvalSociale> = null;
			for (let i = currentIndex + 1; i < triEvalSociales.data.length; i++) {
				const item = triEvalSociales.data[i];
				newTriEvalSociale = item;
				break;
			}

			if (newTriEvalSociale) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newTriEvalSociale);
			} else {
				await searchNextPage();
			}
		}
	};

	const handleOpenSelectReferent = () => {
		setSelectReferentOpen(true);
	};

	const handleCloseSelectReferent = () => {
		setSelectReferentOpen(false);
	};

	const handleReferentChange = (newReferents: Referent[]) => {
		if (newReferents.length > 0) {
			setSelectedReferent(newReferents[0]);
		}
	};

	const handleCreateAccompagnementSocial = async () => {
		if (selectedReferent) {
			setLoading(true);
			try {
				const accompagnementSocial: Partial<AccompagnementSocial> = {
					referentId: selectedReferent.id,
					nombreDossiersDeposes: "1",
					structureEnCharge: "",
				};
				await dispatch(postAccompagnementSocial(accompagnementSocial));
				pushMessage('Accompagnement social créé avec succès.', 'success');
			} catch (error) {
				console.error(error);
				pushMessage('Une erreur est survenue lors de la création de l\'accompagnement social.', 'error');
			} finally {
				setLoading(false);
				setSelectReferentOpen(false);
				setSelectedReferent(null);
				refresh();
			}
		}
	};

	const token = useSelector(state => state.token.token);

	const href = `${process.env.REACT_APP_CLIENT_API_URI as string}/download/tri-eval-sociale/export?${UriHelper.queries({
		token: token?.id,
		order: pageable.order,
		direction: pageable.direction,
		filters: JSON.stringify(filters),
	})}`;

	return (
		<Container className="p-saisie-AccompagnementSocial">
			<CrudTriEvalSocialeFilters
				{...model(filters, setFilters)}
			/>
			<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
				<div style={{ marginLeft: 'auto' }}>
					{/* Bouton pour redirection */}
					<LoadingButton
						variant="contained"
						style={{
							color: 'white', // Ensure the text is white for contrast
							marginLeft: '10px'
						}}
						size="small"
						onClick={() => window.open(href, '_blank')} // Fonctionnalité de lien
					>
						Exporter sur Excel
					</LoadingButton>
				</div>
			</div>
			<SortableTable
				heads={[
					{ name: 'ID', sort: 'id' },
					{ name: 'Nom propriétaire', sort: 'nomProprietaire' },
					{ name: 'Type propriétaire', sort: 'typeProprietaire' },
					{ name: 'Présence syndicats', sort: 'presenceSyndicats' },
					{ name: 'Solde SDC', sort: 'soldeSdc' },
					{ name: 'Solde SP', sort: 'soldeSp' },
					{ name: 'Type accompagnement', sort: 'typeAccompagnement' },
					{ name: 'Date éval sociale', sort: 'dateEvalSociale' },
					{ name: 'Actions', stickyRight: true },
				]}
				result={triEvalSociales}
				renderItem={(item) => (
					<PageSaisieAccompagnementSocialItem
						item={item}
						onEdit={setEdit}
						onRemove={setRemove}
					/>
				)}
				pageable={pageable}
			/>
			<Confirm
				value={!!remove}
				onChange={() => setRemove(null)}
				title="Suppression d'un accompagnement social"
				message={`Êtes-vous sûr de vouloir supprimer l'accompagnement social de ${remove} ?`}
				onConfirm={async () => {
					if (remove) {
						// console.log("TEST : ", remove)
						await dispatch(deleteAccompagnementSocial(remove));
						refresh();
					}
				}}
			/>
			<Dialog open={!!edit} onClose={() => setEdit(null)} maxWidth={'xl'}>
				<CrudReferentRecordEdit
					item={edit}
					onClose={() => setEdit(null)}
					onUpdate={() => refresh()}
					onPrev={handlePrev}
					onNext={handleNext}
				/>
			</Dialog>

			<AppBarPortal>
				<Button
					style={{ float: 'right' }}
					variant="contained"
					onClick={handleOpenSelectReferent}
				>
					Créer un accompagnement social
				</Button>
			</AppBarPortal>

			<Dialog open={selectReferentOpen} onClose={handleCloseSelectReferent} maxWidth="lg">
				<CrudReferentSelect
					value={selectedReferent ? [selectedReferent] : []}
					onChange={handleReferentChange}
					onClose={handleCloseSelectReferent}
					onSave={handleCreateAccompagnementSocial}  // Passe handleCreateAccompagnementSocial à onSave
				/>
			</Dialog>
		</Container>
	);
}

PageSaisieAccompagnementSocial.getMetadata = () => {
	return {
		rights: ['SAISIE_ACCOMPAGNEMENT_SOCIAL'],
		title: 'Accompagnement social - Liste des Ménages',
	};
};
