import { Card, CardContent } from '@mui/material';
import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useEffectTimeout, usePropState } from '@/libs/hooks';
import { CardProps } from '@mui/material/Card/Card';
import TextField from '@/components/inputs/TextField/TextField';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import DatePicker from '@/components/inputs/DatePicker/DatePicker';

export interface CrudTriEvalSocialeFiltersProps extends CardProps {
	value?: Nullable<any>;
	onChange?: (value: Nullable<any>) => any,
}

export default function CrudTriEvalSocialeFilters(
	{
		value,
		onChange,
		className = '',
		...rest
	}: CrudTriEvalSocialeFiltersProps
) {

	const [filters, setFilters] = usePropState<any>(value, onChange, {
		id: '',
		nomProprietaire: '',
		typeProprietaire: '',
		presenceSyndicats: '',
		structure: '',
		typeAccompagnement: '',
		dateEvalSociale: '',
		critereEvalSociale: '',
	});

	const [open, setOpen] = useState<boolean>(false);
	const [id, setId] = useState<string>(filters.id);
	const [nomProprietaire, setNomProprietaire] = useState<string>(filters.nomProprietaire);
	const [typeProprietaire, setTypeProprietaire] = useState<string>(filters.typeProprietaire);
	const [presenceSyndicats, setPresenceSyndicats] = useState<string>(filters.presenceSyndicats);
	const [structure, setStructure] = useState<string>(filters.structure);
	const [typeAccompagnement, setTypeAccompagnement] = useState<string>(filters.typeAccompagnement);
	const [dateEvalSociale, setDateEvalSociale] = useState<string>(filters.dateEvalSociale);
	const [critereEvalSociale, setCritereEvalSociale] = useState<string>(filters.critereEvalSociale);

	useEffectTimeout(() => {
		if (
			filters.id !== id ||
			filters.nomProprietaire !== nomProprietaire ||
			filters.typeProprietaire !== typeProprietaire ||
			filters.presenceSyndicats !== presenceSyndicats ||
			filters.structure !== structure ||
			filters.typeAccompagnement !== typeAccompagnement ||
			filters.dateEvalSociale !== dateEvalSociale ||
			filters.critereEvalSociale !== critereEvalSociale
		) {
			setFilters({
				id,
				nomProprietaire,
				typeProprietaire,
				presenceSyndicats,
				structure,
				typeAccompagnement,
				dateEvalSociale,
				critereEvalSociale,
			})
		}
	}, 500, [
		id,
		nomProprietaire,
		typeProprietaire,
		presenceSyndicats,
		structure,
		typeAccompagnement,
		dateEvalSociale,
		critereEvalSociale,
	]);
	
	return (
		<Card
			{...rest}
			className={[
				className,
				'mt-2',
				'mb-1',
				'c-Crud-TriEvalSociale-Filters',
				open ? 'c-Crud-TriEvalSociale-Filters--open' : ''
			].join(' ')}
		>
			<CardContent className="p-1">
				<div onClick={() => setOpen(!open)}>
					<h5 className="m-0" style={{cursor: 'pointer'}}>  {open ? <KeyboardArrowUp style={{ verticalAlign: '-7px' }} /> : <KeyboardArrowDown style={{ verticalAlign: '-7px' }} />}<span>Filtres :</span></h5>
				</div>
				{open && (
					<Grid container spacing={1}>
						<Grid xs={12} md={3}> <TextField {...model(id, setId)} clearable size="small" variant="standard" label="ID" /> </Grid>
						<Grid xs={12} md={3}> <TextField {...model(nomProprietaire, setNomProprietaire)} clearable size="small" variant="standard" label="Nom Propriétaire" /> </Grid>
						<Grid xs={12} md={3}> <TextField {...model(typeProprietaire, setTypeProprietaire)} clearable size="small" variant="standard" label="Type Propriétaire" /> </Grid>
						<Grid xs={12} md={3}> <TextField {...model(presenceSyndicats, setPresenceSyndicats)} clearable size="small" variant="standard" label="Présence Syndicats" /> </Grid>
						<Grid xs={12} md={3}> <TextField {...model(structure, setStructure)} clearable size="small" variant="standard" label="Structure" /> </Grid>
						<Grid xs={12} md={3}> <TextField {...model(typeAccompagnement, setTypeAccompagnement)} clearable size="small" variant="standard" label="Type Accompagnement" /> </Grid>
						<Grid xs={12} md={3}> <TextField {...model(critereEvalSociale, setCritereEvalSociale)} clearable size="small" variant="standard" label="Critère Eval Sociale" /> </Grid>
						<Grid xs={12} md={3}> <DatePicker {...model(dateEvalSociale, setDateEvalSociale)} clearable label="Date Eval Sociale" textField={{ variant: 'standard', size: 'small' }} /> </Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	)
}
