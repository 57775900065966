import React, { useCallback, useEffect, useRef } from 'react';
import './Edit.scss';
import { HAdresse, HEmail, HIndividu, HTelephone, Referent, TriDia, TriEvalSociale } from '@/models';
import { findReferentById, setReferent } from '@/stores/referent';
import { dateToDash, formatDate, formatNumber } from '@/libs/utils';
import { hAcquisition, Acquisition, Lot, TriAcqui, hTriAcqui, TriLot, hTriLot, hReferent, HSuiviSocial, hProprietaire, hTriEvalSociale } from '@/models';
import triAcqui, { findTriAcqui, findTriAcquis } from '@/stores/triAcqui';
import triDia, { findTriDia, findTriDias } from '@/stores/triDia';
import { HAcquisitionVisite, hAcquisitionVisite } from '@/models/AcquisitionVisite';
import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, CircularProgress, Dialog, FormControl, Hidden, IconButton, InputAdornment, OutlinedInput, Paper, Table, TableContainer, Tooltip, Typography, debounce } from '@mui/material';
import { useState } from 'react';
import TextField from '@/components/inputs/TextField/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { Form as FormObject, useForm } from '@/libs/form';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import { model } from '@/libs/candy';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from '@/libs/redux';
import acquisition, { deleteAcquisitionDia, findAcquisition, findAcquisitionById, postAcquisition, putAcquisition, setAcquisition } from '@/stores/acquisition';
import { useEffectOnChange, useSnackMessage } from '@/libs/hooks';
import Select from '@/components/inputs/Select/Select';
import DatePicker from '@/components/inputs/DatePicker/DatePicker';
import { HProprietaire } from '@/models/Proprietaire';
import { useValidators } from '@/libs/validators';
import { CrudProprietaireSelect } from '@/components/crud/proprietaire/Select/Select';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import IconDelete from '@mui/icons-material/Delete';
import Confirm from '@/components/dialog/Confirm/Confirm';
import { CrudTriLotSelect } from '@/components/crud/triLot/Select/Select';
import { Propriete } from '@/models/Propriete';
import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconModeEdit from '@mui/icons-material/ModeEdit';
import { CrudAcquisitionVisiteEdit } from '../../acquisitionVisite/Edit/Edit';
import { deleteAcquisitionVisite } from '@/stores/acquisitionVisite';
import { AccountCircle, Label, Visibility, VisibilityOff } from '@mui/icons-material';
import { InputLabel } from '@mui/material';
import { read, stat } from 'fs';
//SaveIcon
import SaveIcon from '@mui/icons-material/Save';
//RefreshIcon
import RefreshIcon from '@mui/icons-material/Refresh';
import { blue } from '@mui/material/colors';
import { on } from 'events';

import CrudAdresseList from '@/components/crud/adresse/List/List';
import { CrudChargesMenageEdit } from '@/components/crud/chargesMenage/Edit/Edit';
import { CrudAdresseEdit } from '../../adresse/Edit/Edit';
import { CrudEmailEdit } from '../../email/Edit/Edit';
import { CrudTelephoneEdit } from '../../telephone/Edit/Edit';
import { User } from '@/models';
import { getMe } from '@/stores/user';
import { deleteAdresse, postAdresse, putAdresse } from '@/stores/adresse';
import { deleteTelephone, postTelephone, putTelephone } from '@/stores/telephone';
import { deleteEmail, postEmail, putEmail } from '@/stores/email';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { HAcquisitionDia, hAcquisitionDia } from '@/models/AcquisitionDia';
import { CrudAcquisitionDiaEdit } from '../../acquisitionDia/Edit/Edit';
import acquisitionDia from '@/stores/acquisitionDia';
import typeLot from '@/stores/typeLot';
import CrudTypeLotSelect from '../../typeLot/Select/Select';
import { findTypeLots } from '@/stores/typeLot';
import { TypeLot } from '@/models/TypeLot';
import { useEffectOnce } from 'react-use';
import { Direction } from '@/models';
import { rgb } from 'polished';
import { fontStyle } from 'html2canvas/dist/types/css/property-descriptors/font-style';
import { dateTimePickerTabsClasses } from '@mui/x-date-pickers';

export interface CrudAcquisitionEditProps {
	onClose: () => any;
	onUpdate: () => any;
	onPrev: () => Promise<any>;
	onNext: () => Promise<any>;
	item: Nullable<TriAcqui>;
}
export interface InformationsProps {
	onRefresh: () => any;
}

const useStyles = {
	readonlyField: {
		backgroundColor: '#fafafa',
	},
	labelReadOnly: {
		color: 'grey', // Changez cette couleur en fonction de vos besoins
		backgroundColor: 'transparent', // Assurez-vous que le fond est transparent
	},
	label: {
		backgroundColor: 'transparent', // Assurez-vous que le fond est transparent
	},
	pageNumber: {
		padding: '4px 8px',
		border: '2px solid #00a19a', // Changer l'épaisseur de la bordure ici
		borderRadius: '4px',
		display: 'inline-block',
		margin: '0 8px',
		fontWeight: 'bold', // Ajouter l'épaisseur du texte ici
	},
	button: {
		color: '#00a19a', // Changez cette couleur en fonction de vos besoins
	},
};

function Part1({ form }: { form: FormObject }) {
	const [selectL, setSelectL] = useState(false);
	const [remove, setRemove] = useState<Nullable<TriLot>>(null);
	const [showContent, setShowContent] = useState(true);
	const [canSelectLot, setCanSelectLot] = useState(false);
	const dispatch = useDispatch();

	// Récupérer les types de lots disponibles
	const typeLots = useSelector((state) => state.typeLot.typeLots);
	useEffectOnce(() => {
		dispatch(findTypeLots(0, '', Direction.ASC, 200, 0));
	});
	useEffect(() => {
		const timer = setTimeout(() => {
			setCanSelectLot(true);
		}, 200);

		return () => clearTimeout(timer);
	}, [dispatch]);

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	const handleLotsChange = (lots: TriLot[]) => {
		form.fields.lots.onChange(lots);
		if (lots.length > 0) {
			const firstProprietaire = lots[0].currentProprietaire || lots[0].proprietes?.[0]?.proprietaire || null;
			form.fields.proprietaire.onChange(firstProprietaire);
		} else {
			form.fields.proprietaire.onChange(null);
		}
	};

	// Fonction pour obtenir le nom du type à partir de typeId
	const getTypeLotName = (typeId: number) => {
		const typeLot = typeLots?.data?.find((typeLot: TypeLot) => typeLot.id === typeId);
		return typeLot ? typeLot.nom : 'Type non défini';
	};

	// Fonction pour retourner un tiret centré si aucune valeur
	const formatValue = (value: any) => {
		return value ? (
			value
		) : (
			<span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
				-
			</span>
		);
	};

	return (
		<Card elevation={4} className="mb-1">
			{/* Rendre tout le CardHeader cliquable */}
			<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
				<CardHeader
					className="py-1 mb-1"
					title="Lots concernés par l'acquisition"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <ExpandLessIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
			</div>
			{showContent && (
				<CardContent className="pt-0">
					<Table className="dense-table">
						<TableHead>
							<TableRow>
								<TableCell>Tranche</TableCell>
								<TableCell>Syndicat</TableCell>
								<TableCell align="center">Numéro</TableCell>
								<TableCell>Type</TableCell>
								<TableCell>Adresse</TableCell>
								<TableCell align="center">Escalier</TableCell>
								<TableCell align="center">Etage</TableCell>
								<TableCell align="center">Position</TableCell>
								<TableCell>Propriétaire</TableCell>
								<TableCell>Nom propriétaire</TableCell>
								<TableCell align="center">Surface</TableCell>
								<TableCell align="center">Nombre pièces</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{form.fields.lots.value.length ? (
								form.fields.lots.value.map((lot: TriLot) => (
									<TableRow key={lot.id}>
										<TableCell>{formatValue(lot.syndicat?.id)}</TableCell>
										<TableCell>{formatValue(lot.syndicat?.nom)}</TableCell>
										<TableCell align="center">{formatValue(lot.numero)}</TableCell>
										<TableCell>{lot.typeId != null ? getTypeLotName(Number(lot.typeId)) : '-'}</TableCell>
										<TableCell>{formatValue(lot.espace?.adresse)}</TableCell>
										<TableCell align="center">{formatValue(lot.escalier)}</TableCell>
										<TableCell align="center">{formatValue(lot.etage)}</TableCell>
										<TableCell align="center">{formatValue(lot.position)}</TableCell>
										<TableCell>{formatValue(lot.currentProprietaire?.civilite)}</TableCell>
										<TableCell>{formatValue(lot.currentProprietaire?.nom)}</TableCell>
										<TableCell align="center">{formatValue(lot.surface)}</TableCell>
										<TableCell align="center">{formatValue(lot.nombrePieces)}</TableCell>
										<TableCell align="center">
											<Tooltip title="Supprimer">
												<IconButton onClick={() => setRemove(lot)}>
													<IconDelete />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={13}>
										<div className="u-t-center">
											<i>Aucun lots sélectionné</i>
										</div>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>

					<div className="mt-2 u-t-right">
						<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
							<Button variant="contained" size="small" onClick={() => setSelectL(true)} disabled={!canSelectLot}>
								+
							</Button>
						</Grid>

						<Dialog open={selectL} onClose={() => setSelectL(false)} maxWidth="lg">
							<CrudTriLotSelect
								proprietaire={form.fields.proprietaire.value}
								{...model(form.fields.lots.value, (v: TriLot[]) => handleLotsChange(v))}
								onClose={() => setSelectL(false)}
							/>
						</Dialog>
					</div>
				</CardContent>
			)}
		</Card>
	);
}

function Part2({ form, onTypeVenteChange }: { form: FormObject, onTypeVenteChange: (value: string) => void }) {
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const acquisition = hAcquisition(useSelector((state) => state.acquisition.acquisition));
	const triAcquiState = useSelector((state) => state.triAcqui);
	const triAcqui = hTriAcqui(triAcquiState.triAcqui);
	const [loading, setLoading] = useState(false);
	const [showContent, setShowContent] = useState(true);
	const [typeVente, setTypeVente] = useState<string>('');

	const toggleContent = () => setShowContent(!showContent);

	// Refresh triAcqui
	const refreshTriAcqui = useCallback(() => {
		setLoading(true);
		dispatch(findTriAcqui(acquisition.id)).finally(() => setLoading(false));
	}, [dispatch, acquisition.id]);

	const saveAcquisitionDetails = useCallback(async () => {
		const newRecord: Partial<Acquisition> = {
			id: acquisition.id,
			typeVente: typeVente,
		};
		try {
			setLoading(true);
			await dispatch(putAcquisition(newRecord));
			refreshTriAcqui();
		} catch (error) {
			// pushMessage('Erreur lors de la mise à jour des données.', 'error');
			console.error('Failed to update acquisition:', error);
		} finally {
			setLoading(false);
		}
	}, [dispatch, typeVente, refreshTriAcqui, pushMessage]);

	const handleSaveRefreshAndReopen = useCallback(() => {
		saveAcquisitionDetails().then(() => {
			setTimeout(refreshTriAcqui, 1000);
		});
	}, [saveAcquisitionDetails, refreshTriAcqui]);

	// Debounced effect
	useEffect(() => {
		const debouncedHandleChange = debounce(() => {
			handleSaveRefreshAndReopen();
		}, 500);

		if (typeVente !== null) {
			debouncedHandleChange();
		}

		return () => {
			debouncedHandleChange.clear();
		};
	}, [typeVente, handleSaveRefreshAndReopen]);

	// Form state
	const formSuiviDossier = useForm({
		handle: async () => {
			await formSuiviDossier.call(async () => {
				await save();
			});
		},
		fields: {
			typeVente: {
				...model(typeVente, setTypeVente),
				label: 'Type de vente',
				items: ['Libre', 'Occupé', 'Alternative'],
			},
		},
	});

	// Save acquisition details
	const save = async () => {
		let newRecord: Partial<Acquisition> = {
			id: acquisition.id,
			typeVente: typeVente,
		} as any;
		if (newRecord.id) {
			newRecord = await dispatch(putAcquisition(newRecord));
		} else {
			newRecord = await dispatch(postAcquisition(newRecord));
		}
		pushMessage(`Mise à jour de la valeur terminée.`);
	};

	useEffect(() => {
		if (acquisition) {
			setTypeVente(acquisition.typeVente || '');
			onTypeVenteChange(acquisition.typeVente || '');
		}
	}, [acquisition?.typeVente, onTypeVenteChange]);

	useEffect(() => {
		if (acquisition) {
			setTypeVente(acquisition.typeVente || '');
			onTypeVenteChange(acquisition.typeVente || '');  // Callback to send value up
		}
	}, [acquisition?.typeVente]);

	const typeV = formSuiviDossier.fields.typeVente.value;

	return (
		<Card elevation={4} className="mb-1">
			<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
				<CardHeader
					className="py-1 mb-1"
					title="Suivi du dossier"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <ExpandLessIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
			</div>
			{showContent && <CardContent className="pt-0">
				<Grid container spacing={1}>
					{/* Other Grids remain unchanged */}
					<Select {...form.fields.typeVente} value={typeV} style={{ display: 'none' }} />
					<Grid xs={12} md={12}>
						<TextField
							className="custom_textfieldAlignL"
							label="Statut du dossier"
							value={triAcqui?.statutDossier || '-'}
							variant="outlined"
							readOnly={true}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
					<Grid xs={12} md={3}>
						<Select {...formSuiviDossier.fields.typeVente}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
					{/* <Grid xs={12} md={4}>
						<Select {...form.fields.statutDossier} />
					</Grid> */}
					<Grid xs={12} md={2}>
						<Select {...form.fields.structure}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
					<Grid xs={12} md={7}>
						<TextField {...form.fields.responsable}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
							inputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid xs={12} md={12}></Grid>
					<Grid xs={12} md={6}>
						<TextField
							{...form.fields.origineSignalement}
							label="Origine signalement"
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
					<Grid xs={12} md={6}>
						<DatePicker
							{...form.fields.dateSignalement}
							// label="Date signalement"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>}
		</Card>
	);
}

function Part3({ form }: { form: FormObject }) {
	const [selectP, setSelectP] = useState(false);
	const [selectL, setSelectL] = useState(false);
	const [remove, setRemove] = useState<Nullable<TriLot>>(null);
	const [showContent, setShowContent] = useState(true); // État initial à false
	const acquisition = hAcquisition(useSelector((state) => state.acquisition.acquisition));
	const triAcquiState = useSelector((state) => state.triAcqui);
	const triAcqui = hTriAcqui(triAcquiState.triAcqui);
	const [editAdresses, setEditAdresses] = useState(false);
	const [editTelephone, setEditTelephone] = useState(false);
	const [editEmail, setEditEmail] = useState(false);
	const referent = hReferent(useSelector(state => state.referent.referent));

	// Fonction pour basculer l'affichage
	const toggleContent = () => {
		setShowContent(!showContent); // Change l'état à chaque clic
	};

	return (
		<Card elevation={4} className="mb-1">
			<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
				<CardHeader
					className="py-1 mb-1"
					title="Propriétaire"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <ExpandLessIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
			</div>
			{showContent && <CardContent className="pt-0">
				<Grid container spacing={1}>
					<>
						<Grid xs={12} md={2}>
							<strong>Id :</strong> <br />
							{triAcqui?.proprietaire || '-'}
						</Grid>
						<Grid xs={12} md={2}>
							<strong>Civilité :</strong> <br />
							{triAcqui?.civiliteProprietaire || '-'}
						</Grid>
						<Grid xs={12} md={5}>
							<strong>Nom :</strong> <br />
							{triAcqui?.nomProprietaire || '-'}
						</Grid>
					</>
					<Grid xs={12} md={3}>
						<strong>N° tel :</strong> <br />
						{triAcqui?.telRefProprietaire || <i>Aucun numéro associé</i>}
					</Grid>
					<Grid xs={12} md={4}>
						<strong>Mail :</strong> <br />
						{triAcqui?.emailRefProprietaire || <i>Aucun email associé</i>}
					</Grid>
					<Grid xs={12} md={8}>
						<strong>Adresse :</strong> <br />
						{triAcqui?.adresseRefProprietaire || <i>Aucune adresse associée</i>}
					</Grid>
				</Grid>

			</CardContent>
			}
		</Card>
	);
}

export interface CompositionsProps {
	onRefresh: () => any;
}
export interface ChargesProps {
	onRefresh: () => any;
}

export interface VisiteProps {
	onRefresh: () => any;
}

function Part4({ onRefresh }: CompositionsProps) {
	const dispatch = useDispatch();
	const [edit, setEdit] = useState<Nullable<HAcquisitionVisite>>(null);
	const [remove, setRemove] = useState<Nullable<HAcquisitionVisite>>(null);
	const acquisition = hAcquisition(useSelector((state) => state.acquisition.acquisition));
	const [showContent, setShowContent] = useState(true);

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	return (
		<>
			<Card elevation={4} className="mb-1">
				<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
					<CardHeader
						className="py-1 mb-1"
						title="Visite(s)"
						titleTypographyProps={{ className: 'u-t-size-19' }}
						action={
							<IconButton onClick={toggleContent} style={{ color: 'white' }}>
								{showContent ? <ExpandLessIcon /> : <AddIcon />}
							</IconButton>
						}
					/>
				</div>
				{showContent && <CardContent className="pt-0">
					<div style={{ overflow: 'auto' }}>
						{acquisition?.acquisitionVisitesl?.length ? (
							<div style={{ overflow: 'auto' }}>
								<table className="c-Crud-Referent-Edit-suiviSocial">
									<thead>
										<tr>
											<th>Date</th>
											<th>Contexte de la visite</th>
											<th>État du logement</th>
											<th>Précisions sur l'occupation</th>
											<th>Commentaire visite</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{acquisition.acquisitionVisites
											.sort((a: HAcquisitionVisite, b: HAcquisitionVisite) => new Date(b.date).getTime() - new Date(a.date).getTime())
											.map((vs: HAcquisitionVisite) => (
												<tr key={vs.id}>
													<td>{new Date(vs.date).toLocaleDateString('fr-FR')}</td>
													<td>{vs.contexteVisite}</td>
													<td>{vs.etatLogement}</td>
													<td>{vs.precisionsOccupation}</td>
													<td style={{ minWidth: '400px' }}>{vs.commentaire}</td>
													<td style={{ minWidth: '110px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
														<Tooltip title="Éditer la visite" arrow>
															<IconButton onClick={() => setEdit(vs)}>
																<IconModeEdit />
															</IconButton>
														</Tooltip>
														<Tooltip title="Supprimer la visite" arrow>
															<IconButton onClick={() => setRemove(vs)}>
																<IconDelete />
															</IconButton>
														</Tooltip>
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						) : (
							<div className="u-t-center">
								<i>Aucune visite pour l'instant</i>
							</div>
						)}
					</div>
					<div className="mt-2 u-t-right" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '16px' }}>
						<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
							<Button
								variant="contained"
								size="small"
								onClick={() =>
									setEdit({
										id: '',
										acquisition: acquisition.id,
										dateVisite: new Date(),
										contexteVisite: '',
										etatLogement: '',
										precisionsOccupation: '',
										nomBanque: '',
										montantDetteBanque: null,
										montantAutresDettes: null,
										commentaire: '',
									})
								}
							>
								+
							</Button>
						</Grid>
					</div>
					<Dialog open={edit !== null} onClose={() => setEdit(null)} maxWidth={'md'}>
						<CrudAcquisitionVisiteEdit
							acquisition={acquisition}
							visiteAcqui={edit}
							onSave={() => {
								setEdit(null);
							}}
							onClose={() => setEdit(null)}
						/>
					</Dialog>
					<Confirm
						value={!!remove}
						onChange={() => setRemove(null)}
						title="Suppression d'une visite"
						message={`Êtes--vous sûr de vouloir supprimer la visite ?`}
						onConfirm={async () => {
							await dispatch(deleteAcquisitionVisite(remove));
						}}
					/>
				</CardContent>}
			</Card>
		</>
	);
}

function Part5({ form, onSurfaceDeclareeChange }: { form: FormObject, onSurfaceDeclareeChange: (value: number) => void }) {
	const dispatch = useDispatch();
	const acquisition = hAcquisition(useSelector(state => state.acquisition.acquisition));
	const triAcquiState = useSelector(state => state.triAcqui);
	const triAcqui = hTriAcqui(triAcquiState.triAcqui);
	const pushMessage = useSnackMessage();
	const [loading, setLoading] = useState(false);
	const [surfaceDeclaree, setSurfaceDeclaree] = useState<Nullable<number>>(acquisition.surfaceDeclaree || null);
	const [showContent, setShowContent] = useState(true);
	const [refreshLock, setRefreshLock] = useState(false);

	const toggleContent = () => setShowContent(!showContent);

	// Refresh triAcqui logic, controlled by refreshLock
	const refreshTriAcqui = useCallback(() => {
		if (refreshLock) {
			setLoading(true);
			dispatch(findTriAcqui(acquisition.id)).finally(() => {
				setLoading(false);
				setRefreshLock(false);  // Ensure to release lock after refresh
			});
		}
	}, [dispatch, acquisition.id, refreshLock]);

	// Save acquisition details only when necessary
	const handleBlur = useCallback(() => {
		if (surfaceDeclaree !== acquisition.surfaceDeclaree) {
			setLoading(true);
			const newRecord = {
				id: acquisition.id,
				surfaceDeclaree: surfaceDeclaree,
			};
			dispatch(postAcquisition(newRecord))
				.then(() => {
					setRefreshLock(true);  // Set lock to trigger a refresh
				})
				.catch((error: any) => {
					console.error('Failed to update acquisition:', error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [dispatch, surfaceDeclaree, acquisition.surfaceDeclaree, acquisition.id]);


	const saveAcquisitionDetails = useCallback(async () => {
		const newRecord = {
			id: acquisition.id,
			surfaceDeclaree: surfaceDeclaree ? parseFloat(surfaceDeclaree.toString()) : null,
		};
		try {
			setLoading(true);
			await dispatch(postAcquisition(newRecord));
			setRefreshLock(true);
		} catch (error) {
			console.error('Failed to update acquisition:', error);
		} finally {
			setLoading(false);
		}
	}, [dispatch, surfaceDeclaree]);

	// Effect to trigger a refresh when needed
	useEffect(() => {
		refreshTriAcqui();
	}, [refreshTriAcqui]);

	// Watch for changes in acquisition details
	useEffect(() => {
		if (acquisition) {
			setSurfaceDeclaree(acquisition.surfaceDeclaree || null);
			onSurfaceDeclareeChange(acquisition.surfaceDeclaree || 0);
		}
	}, [acquisition?.surfaceDeclaree, onSurfaceDeclareeChange]);

	useEffect(() => {
		if (acquisition) {
			setSurfaceDeclaree(acquisition.surfaceDeclaree || null);
			onSurfaceDeclareeChange(acquisition.surfaceDeclaree || 0);  // Callback to send value up
		}
	}, [acquisition?.surfaceDeclaree]);

	const save = async () => {
		let newRecord: Partial<Acquisition> = {
			id: acquisition.id,
			surfaceDeclaree: surfaceDeclaree ? parseFloat(surfaceDeclaree.toString()) : null,
		} as any;
		if (newRecord.id) {
			newRecord = await dispatch(putAcquisition(newRecord));
		} else {
			newRecord = await dispatch(postAcquisition(newRecord));
		}
		pushMessage(`Mise à jour de la valeur terminée.`);
	};
	// Form state
	const formVenteAmiable = useForm({
		handle: async () => {
			await formVenteAmiable.call(async () => {
				await save();
			});
		},
		fields: {
			surfaceDeclaree: {
				...model(surfaceDeclaree, setSurfaceDeclaree),
				label: 'Surface déclarée',
				type: 'number',
			},
		},
	});
	const surfaced = formVenteAmiable.fields.surfaceDeclaree.value;

	return (
		<Card elevation={4} className="mb-1">
			<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
				<CardHeader
					className="py-1 mb-1"
					title="Vente amiable"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <ExpandLessIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
			</div>
			{showContent && <><CardContent className="pt-0">
				<Grid container spacing={1}>
					<Grid xs={12}>
						<h5 className="m-0">DNID & Offre</h5>
					</Grid>
					<Grid xs={12} md={12}></Grid>
					<Grid xs={12} md={1.5}>
						<TextField
							{...model(surfaceDeclaree, setSurfaceDeclaree)}
							label="Surface Déclarée"
							type="number"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
							inputProps={{
								endAdornment: <InputAdornment position="end">m²</InputAdornment>,
							}}
							onWheel={(e) => {
								const target = e.target as HTMLInputElement;
								target.blur();
							}}
							onBlur={() => {
								setSurfaceDeclaree(prev => {
									const newValue = parseFloat(prev?.toString() || '0');
									if (newValue !== acquisition.surfaceDeclaree) {
										saveAcquisitionDetails();  // Assuming this triggers triAcqui indirectly
									}
									return prev;
								});
							}}
						/>
					</Grid>
					<Grid xs={12} md={9}>
						<TextField
							value="💡Une décote de 3m² s'applique dans le calcul du prix dès que la surface déclarée dépasse les 35m²."
							variant="outlined"
							readOnly
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
							inputProps={{
								style: { border: 'none', color: 'black', textAlign: 'left' }, // Retirer les bordures
								disableUnderline: true, // Enlever la ligne en dessous dans certains cas
							}}
							sx={{
								'& .MuiOutlinedInput-root': {
									'& fieldset': {
										border: 'none', // Supprimer l'encadrement
									},
								},
							}}
						/>
					</Grid>
					{/* Transposed table to separate Libre and Occupé fields */}
					<Grid xs={12}>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow sx={{ height: 'auto' }}>
										<TableCell sx={{ padding: '4px', lineHeight: '0.5' }}></TableCell> {/* Empty for column headers */}
										<TableCell align="center" sx={{ padding: '4px', lineHeight: '0.5' }}>Prix / m²</TableCell>
										<TableCell align="center" sx={{ padding: '4px', lineHeight: '0.5' }}>Prix</TableCell>
										<TableCell align="center" sx={{ padding: '4px', lineHeight: '0.5' }}>Indemnité de remploi</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{/* Libre Row */}
									<TableRow sx={{ height: 'auto' }}>
										<TableCell component="th" scope="row" sx={{ padding: '4px', lineHeight: '0.5' }}>Libre</TableCell>
										<TableCell align="center" sx={{ padding: '4px', lineHeight: '0.5' }}>
											<Tooltip title="Prix / m² (libre)" arrow>
												<TextField
													className="custom-textfield"
													value={triAcqui?.prixm2AmiableLibre ? `${triAcqui.prixm2AmiableLibre.toLocaleString()} €` : '—'}
													variant="outlined"
													readOnly
													InputLabelProps={{
														shrink: true,
														style: useStyles.label,
													}}
												/>
											</Tooltip>
										</TableCell>
										<TableCell align="center" sx={{ padding: '4px', lineHeight: '0.5' }}>
											<Tooltip title="Prix (libre)" arrow>
												<TextField
													className="custom-textfield"
													value={triAcqui?.prixAmiableLibre ? `${triAcqui.prixAmiableLibre.toLocaleString()} €` : '—'}
													variant="outlined"
													readOnly
													InputLabelProps={{
														shrink: true,
														style: useStyles.label,
													}}
												/>
											</Tooltip>
										</TableCell>
										<TableCell align="center" sx={{ padding: '4px', lineHeight: '0.5' }}>
											<Tooltip title="Indemnité remploi (libre)" arrow>
												<TextField
													className="custom-textfield"
													value={triAcqui?.indemniteRemploiLibre ? `${triAcqui.indemniteRemploiLibre.toLocaleString()} €` : '—'}
													variant="outlined"
													readOnly
													InputLabelProps={{
														shrink: true,
														style: useStyles.label,
													}}
												/>
											</Tooltip>
										</TableCell>
									</TableRow>
									{/* Occupé Row */}
									<TableRow sx={{ height: 'auto' }}>
										<TableCell component="th" scope="row" sx={{ padding: '4px', lineHeight: '0.5' }}>Occupé</TableCell>
										<TableCell align="center" sx={{ padding: '4px', lineHeight: '0.5' }}>
											<Tooltip title="Prix m² (occupé)" arrow>
												<TextField
													className="custom-textfield"
													value={triAcqui?.prixm2AmiableOccupe ? `${triAcqui.prixm2AmiableOccupe.toLocaleString()} €` : '—'}
													variant="outlined"
													readOnly
													InputLabelProps={{
														shrink: true,
														style: useStyles.label,
													}}
												/>
											</Tooltip>
										</TableCell>
										<TableCell align="center" sx={{ padding: '4px', lineHeight: '0.5' }}>
											<Tooltip title="Prix (occupé)" arrow>
												<TextField
													className="custom-textfield"
													value={triAcqui?.prixAmiableOccupe ? `${triAcqui.prixAmiableOccupe.toLocaleString()} €` : '—'}
													variant="outlined"
													readOnly
													InputLabelProps={{
														shrink: true,
														style: useStyles.label,
													}}
												/>
											</Tooltip>
										</TableCell>
										<TableCell align="center" sx={{ padding: '4px', lineHeight: '0.5' }}>
											<Tooltip title="Indemnité de remploi (occupé)" arrow>
												<TextField
													className="custom-textfield"
													value={triAcqui?.indemniteRemploiOccupe ? `${triAcqui.indemniteRemploiOccupe.toLocaleString()} €` : '—'}
													variant="outlined"
													readOnly
													InputLabelProps={{
														shrink: true,
														style: useStyles.label,
													}}
												/>
											</Tooltip>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>

					{/* forms avec un S et pas form pour forcer la soumission d'un second formulaire */}
					<Grid xs={12} md={3}>
						<TextField
							{...form.fields.surfaceDeclaree}
							value={surfaced} style={{ display: 'none' }}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
					<Grid xs={12} md={12}></Grid>
					<Grid xs={12} md={2}>
						<DatePicker
							{...form.fields.dateOffreOrale}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
					<Grid xs={12} md={2}>
						<DatePicker
							{...form.fields.dateOffreEcrite}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
					<Grid xs={12} md={2}>
						<DatePicker
							{...form.fields.dateOffreEpf}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
					{/* <Grid xs={12} md={2}>
						<DatePicker
							{...form.fields.dateSaisineNotaire}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid> */}
					{/* <Grid xs={12} md={3}><DatePicker {...form.fields.dateSignature} /></Grid> */}
					<Grid xs={4} md={2}>
						<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
							<Select
								{...form.fields.dossierBloque}
								InputLabelProps={{
									shrink: true,
									style: useStyles.label,
								}}
							/>
						</Box>
					</Grid>
					<Grid xs={4} md={2}>
						<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
							<Select
								{...form.fields.aRecontacter}
								InputLabelProps={{
									shrink: true,
									style: useStyles.label,
								}}
							/>
						</Box>
					</Grid>
					<Grid xs={4} md={2}>
						<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
							<Select
								{...form.fields.refusPrix}
								InputLabelProps={{
									shrink: true,
									style: useStyles.label,
								}}
							/>
						</Box>
					</Grid>
				</Grid>
			</CardContent>
				<CardContent className="pt-0">
					<Grid xs={12}>
						<TextField
							multiline
							rows={5}
							{...form.fields.commentaireAmiable}
							placeholder="Vos données de commentaire seront traitées conformément à notre politique de confidentialité et aux réglementations de la RGPD."
							helperText="Cette zone de commentaires est visible sur les fiches copropriétaires et dans les compte-rendus, diffusés aux instances de copropriété : conformément au RGPD, les informations collectées et renseignées sur les personnes doivent être adéquates, pertinentes et non excessives au regard de la finalité du traitement envisagé. Les commentaires ne doivent donc pas être inappropriés, subjectifs et insultants."
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
				</CardContent></>
			}
		</Card>
	);
}

function Part7({ form }: { form: FormObject }) {
	const [showContent, setShowContent] = useState(false); // État initial à false

	// Fonction pour basculer l'affichage
	const toggleContent = () => {
		setShowContent(!showContent); // Change l'état à chaque clic
	};
	return (
		<Card elevation={4} className="mb-1">
			<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
				<CardHeader
					className="py-1 mb-1"
					title="Adjudication"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <ExpandLessIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
			</div>
			{showContent && <CardContent className="pt-0">
				<Grid container spacing={1}>
					<Grid xs={12}>
						<strong>Adjudication</strong>
					</Grid>
					<Grid xs={12} md={2}>
						<DatePicker {...form.fields.dateAudienceAdju} />
					</Grid>
					<Grid xs={12} md={4}>
						<TextField {...form.fields.montantMap}
							inputProps={{
								endAdornment: <InputAdornment position="end">€</InputAdornment>
							}}
							onWheel={(e) => {
								const target = e.target as HTMLInputElement;
								target.blur();
							}}
						/>
					</Grid>
					<Grid xs={12} md={3}>
						<TextField {...form.fields.montantDerniereEnchere}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
							inputProps={{
								endAdornment: <InputAdornment position="end">€</InputAdornment>,
							}}
							onWheel={(e) => {
								const target = e.target as HTMLInputElement;
								target.blur();
							}}
						/>
					</Grid>
					<Grid xs={12} md={3}>
						<Select {...form.fields.recuperationCcvPvd}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>

					</Grid>

					<Grid xs={12} md={3}>
						<TextField {...form.fields.nomPoursuivant}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
							inputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid xs={12} md={3}>
						<TextField {...form.fields.nomAvocatPoursuivant}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
							inputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid xs={12} md={3}>
						<TextField {...form.fields.nomDernierEncherisseur}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
							inputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid xs={12} md={3}>
						<TextField {...form.fields.avocatDernierEncherisseur}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
							inputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
						/>
					</Grid>


					<Grid xs={12}>
						<strong>Décision EPFIF</strong>
					</Grid>
					{/* <Grid xs={12} md={3}>
						<Select {...form.fields.decisionPreemptionEpfAdju}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid> */}
					<Grid xs={12} md={3}>
						<DatePicker {...form.fields.dateJugementAdju}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
					<Grid xs={12} md={3}>
						<DatePicker {...form.fields.dateEnvoiFicheAcqui}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
					<Grid xs={12} md={12}>
						<TextField
							multiline
							rows={5}
							{...form.fields.commentaireAdju}
							placeholder="Vos données de commentaire seront traitées conformément à notre politique de confidentialité et aux réglementations de la RGPD."
							helperText="Cette zone de commentaires est visible sur les fiches copropriétaires et dans les compte-rendus, diffusés aux instances de copropriété : conformément au RGPD, les informations collectées et renseignées sur les personnes doivent être adéquates, pertinentes et non excessives au regard de la finalité du traitement envisagé. Les commentaires ne doivent donc pas être inappropriés, subjectifs et insultants."
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>
			}
		</Card>
	);
}

function Part8({ form }: { form: FormObject }) {
	const [showContent, setShowContent] = useState(false); // État initial à false
	// Fonction pour basculer l'affichage
	const toggleContent = () => {
		setShowContent(!showContent);
	};
	return (
		<Card elevation={4} className="mb-1">
			<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
				<CardHeader
					className="py-1 mb-1"
					title="Acquisition"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <ExpandLessIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
			</div>
			{showContent && <CardContent className="pt-0">
				<Grid container spacing={1}>
					<Grid xs={12} md={2}>
						<Select
							{...form.fields.contexteAcquisition}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
					<Grid xs={12} md={2}>
						<DatePicker
							{...form.fields.dateAcquisition}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
						/>
					</Grid>
					<Grid xs={12} md={4}>
						<TextField
							{...form.fields.prixm2Acquisition}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
							inputProps={{
								endAdornment: <InputAdornment position="end">€</InputAdornment>
							}}
							onWheel={(e) => {
								const target = e.target as HTMLInputElement;
								target.blur();
							}}
						/>
					</Grid>
					<Grid xs={12} md={4}>
						<TextField {...form.fields.surfaceCarrezAcquisition}
							InputLabelProps={{
								shrink: true,
								style: useStyles.label,
							}}
							inputProps={{
								endAdornment: <InputAdornment position="end">m²</InputAdornment>
							}}
							onWheel={(e) => {
								const target = e.target as HTMLInputElement;
								target.blur();
							}}
						/>
					</Grid>
				</Grid>

			</CardContent>
			}
		</Card>
	);
}

export interface CrudReferentEditProps {
	onClose: () => any;
	onUpdate: () => any;
	onPrev: () => Promise<any>;
	onNext: () => Promise<any>;
	item: Nullable<TriEvalSociale>;
}

export interface InformationsProps {
	onRefresh: () => any;
}

function useReferentById(referentId: string) {
	const dispatch = useDispatch();
	const [referent, setReferent] = useState<Nullable<Referent>>(null);
	const [loading, setLoading] = useState(false);
	const fetchReferent = useCallback(async () => {
		if (referentId) {
			setLoading(true);
			try {
				const result = await dispatch(findReferentById(referentId));
				setReferent(result);
			} catch (e) {
				console.error(e);
			}
			setLoading(false);
		}
	}, [dispatch, referentId]);
	useEffect(() => {
		fetchReferent();
	}, [fetchReferent]);
	return { referent, loading, refresh: fetchReferent };
}

function Part9({ onRefresh }: InformationsProps) {
	const dispatch = useDispatch();
	const triAcqui = hTriAcqui(useSelector((state) => state.triAcqui.triAcqui));
	const filteredReferenta = triAcqui?.proprietaire;
	const { referent, loading, refresh } = useReferentById(filteredReferenta);

	const [currentUser, setCurrentUser] = useState<Nullable<User>>(null);

	const [editAdresse, setEditAdresse] = useState<Nullable<HAdresse>>(null);
	const [editTelephone, setEditTelephone] = useState<Nullable<HTelephone>>(null);
	const [editEmail, setEditEmail] = useState<Nullable<HEmail>>(null);
	const [removeAdresse, setRemoveAdresse] = useState<Nullable<HAdresse>>(null);
	const [removeTelephone, setRemoveTelephone] = useState<Nullable<HTelephone>>(null);
	const [removeEmail, setRemoveEmail] = useState<Nullable<HEmail>>(null);

	const [addresses, setAddresses] = useState<HAdresse[]>([]);
	const [emails, setEmails] = useState<HEmail[]>([]);
	const [telephones, setTelephones] = useState<HTelephone[]>([]);
	const [showContent, setShowContent] = useState(false);

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	useEffect(() => {
		refresh();
		dispatch(getMe()).then((user: Nullable<User>) => {
			setCurrentUser(user);
		});
	}, [refresh, dispatch]);

	const getTypeLabel = (type: any) => {
		switch (type) {
			case 1:
				return "Donnée syndic";
			case 2:
				return "Saisie manuelle";
			default:
				return "";
		}
	};

	const concatAddresses = (adresse1: string | null, adresse2: string | null, adresse3: string | null) => {
		return [adresse1, adresse2, adresse3].filter(Boolean).join(' | ');
	};

	const getSource = (type: number, source: string | null) => {
		return source || '';
	};

	const filterAndSortItems = <T extends { type: number; dateMaj: string; pertinence: number; coche: boolean }>(items: T[], key: keyof T) => {
		return items.sort((a, b) => {
			if (a.coche !== b.coche) {
				return (b.coche ? 1 : 0) - (a.coche ? 1 : 0);
			} else if (a.type !== b.type) {
				return b.type - a.type;
			} else if (new Date(a.dateMaj).getTime() !== new Date(b.dateMaj).getTime()) {
				return new Date(b.dateMaj).getTime() - new Date(a.dateMaj).getTime();
			} else {
				return b.pertinence - a.pertinence;
			}
		});
	};

	const submitPertinenceChange = async (newItem: any, previousItem: any, putFunction: any) => {
		try {
			if (previousItem) {
				const updatedPreviousItem = { ...previousItem, pertinence: null };
				await dispatch(putFunction(updatedPreviousItem));
			}

			if (newItem) {
				await dispatch(putFunction(newItem));
			}
		} catch (error) {
			console.error('Error updating item:', error);
		}
	};

	const handleCheckboxChangeForAddress = async (index: number) => {
		const previousRelevantItem = addresses.find(item => item.pertinence === 1);

		const updatedList = addresses.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? 0 : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putAdresse);

		setAddresses(filterAndSortItems(updatedList, 'dateMaj'));
	};

	const handleCheckboxChangeForEmail = async (index: number) => {
		const previousRelevantItem = emails.find(item => item.pertinence === 1);

		const updatedList = emails.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? 0 : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putEmail);

		setEmails(filterAndSortItems(updatedList, 'dateMaj'));
	};

	const handleCheckboxChangeForTelephone = async (index: number) => {
		const previousRelevantItem = telephones.find(item => item.pertinence === 1);

		const updatedList = telephones.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? null : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putTelephone);

		setTelephones(filterAndSortItems(updatedList, 'dateMaj'));
	};

	useEffect(() => {
		if (referent) {
			setAddresses(filterAndSortItems(referent.adresses?.map((adresse: any) => ({ ...adresse, coche: adresse.pertinence === 1, pertinence: adresse.pertinence ?? null })) || [], 'adresse1'));
			setEmails(filterAndSortItems(referent.emails?.map((email: any) => ({ ...email, coche: email.pertinence === 1, pertinence: email.pertinence ?? null })) || [], 'email1'));
			setTelephones(filterAndSortItems(referent.telephones?.map((telephone: any) => ({ ...telephone, coche: telephone.pertinence === 1, pertinence: telephone.pertinence ?? null })) || [], 'telephone1'));
		}
	}, [referent]);

	const columnWidths = {
		type: '10%',
		address: '35%',
		comment: '20%',
		source: '10%',
		dateMaj: '10%',
		actions: '10%',
		reference: '5%'
	};

	return (
		<>
			<Card elevation={4} className="mb-1">
				<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
					<CardHeader
						className="py-1 mb-1"
						title="Coordonnées"
						titleTypographyProps={{ className: 'u-t-size-19' }}
						action={
							<IconButton onClick={toggleContent} style={{ color: 'white' }}>
								{showContent ? <ExpandLessIcon /> : <AddIcon />}
							</IconButton>
						}
					/>
				</div>
				{showContent && (
					<CardContent className="pt-0">
						{/* ------------------------------ ADRESSES --------------------------------------------- */}
						<Grid container spacing={1}>
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Adresse(s) :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.address }}>Adresse</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme adresse de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{addresses.length ? (
												addresses.map((adresse: HAdresse, index) => (
													<Tooltip title={adresse.pertinence === 1 ? "Adresse de référence" : ""} key={adresse.id}>
														<tr style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.address }}>
																{concatAddresses(adresse.adresse1, adresse.adresse2, adresse.adresse3)}
															</td>
															<td style={{ width: columnWidths.comment }}>{adresse.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(adresse.type, adresse.source)}
															</td>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(adresse.type)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(adresse.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<MuiCheckbox
																	checked={Boolean(adresse.coche)}
																	onChange={() => handleCheckboxChangeForAddress(index)}
																	inputProps={{ 'aria-label': 'Adresse de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{adresse.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditAdresse(adresse)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveAdresse(adresse)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>

														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucune adresse</i>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditAdresse({
											id: 0,
											referent: filteredReferenta,
											adresse1: '',
											adresse2: '',
											adresse3: '',
											commentaire: '',
											source: '',
											pertinence: 0, // Ajout de pertinence par défaut pour une nouvelle adresse
											dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour une nouvelle adresse
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editAdresse !== null} onClose={() => setEditAdresse(null)} maxWidth={'md'}>
								{editAdresse && (
									<CrudAdresseEdit
										referent={filteredReferenta}
										value={editAdresse}
										onSave={() => {
											setEditAdresse(null);
											refresh();
										}}
										onClose={() => setEditAdresse(null)}
										currentUser={currentUser} // Passer l'utilisateur actuel à CrudAdresseEdit
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeAdresse}
								onChange={() => setRemoveAdresse(null)}
								title="Suppression d'une adresse"
								message={`Êtes-vous sûr de vouloir supprimer cette adresse ?`}
								onConfirm={async () => {
									if (removeAdresse) {
										await dispatch(deleteAdresse(removeAdresse.id));
										setRemoveAdresse(null);
										refresh();
									}
								}}
							/>
						</Grid>
						{/* ------------------------------ EMAILS ----------------------------------------------- */}
						<Grid container spacing={1}>
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Email(s) :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.address }}>Email</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme email de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{emails.length ? (
												emails.map((email: HEmail, index) => (
													<Tooltip title={email.pertinence === 1 ? "Email de référence" : ""} key={email.id}>
														<tr style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(email.type)}
															</td>
															<td style={{ width: columnWidths.address }}>{email.email1}</td>
															<td style={{ width: columnWidths.comment }}>{email.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(email.type, email.source)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(email.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<MuiCheckbox
																	checked={Boolean(email.coche)}
																	onChange={() => handleCheckboxChangeForEmail(index)}
																	inputProps={{ 'aria-label': 'Email de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{email.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditEmail(email)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveEmail(email)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>
														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucun email</i>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditEmail({
											id: 0,
											referent: filteredReferenta,
											email1: '',
											commentaire: '',
											source: '',
											pertinence: 0, // Ajout de pertinence par défaut pour un nouvel email
											dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour un nouvel email
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editEmail !== null} onClose={() => setEditEmail(null)} maxWidth={'md'}>
								{editEmail && (
									<CrudEmailEdit
										referent={filteredReferenta}
										value={editEmail}
										onSave={() => {
											setEditEmail(null);
											refresh();
										}}
										onClose={() => setEditEmail(null)}
										currentUser={currentUser} // Passer l'utilisateur actuel à CrudEmailEdit
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeEmail}
								onChange={() => setRemoveEmail(null)}
								title="Suppression d'un email"
								message={`Êtes-vous sûr de vouloir supprimer cet email ?`}
								onConfirm={async () => {
									if (removeEmail) {
										await dispatch(deleteEmail(removeEmail.id));
										setRemoveEmail(null);
										refresh();
									}
								}}
							/>
						</Grid>

						{/* ------------------------------ TELEPHONES ------------------------------------------- */}
						<Grid container spacing={1}>
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Numéro(s) de téléphone :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.address }}>Téléphone</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme téléphone de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{telephones.length ? (
												telephones.map((telephone: HTelephone, index) => (
													<Tooltip title={telephone.pertinence === 1 ? "Téléphone de référence" : ""} key={telephone.id}>
														<tr style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(telephone.type)}
															</td>
															<td style={{ width: columnWidths.address }}>{telephone.telephone1}</td>
															<td style={{ width: columnWidths.comment }}>{telephone.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(telephone.type, telephone.source)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(telephone.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<MuiCheckbox
																	checked={Boolean(telephone.coche)}
																	onChange={() => handleCheckboxChangeForTelephone(index)}
																	inputProps={{ 'aria-label': 'Téléphone de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{telephone.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditTelephone(telephone)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveTelephone(telephone)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>
														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucun téléphone</i>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditTelephone({
											id: 0,
											referent: filteredReferenta,
											telephone1: '',
											commentaire: '',
											source: '',
											pertinence: 0, // Ajout de pertinence par défaut pour un nouveau téléphone
											dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour un nouveau téléphone
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editTelephone !== null} onClose={() => setEditTelephone(null)} maxWidth={'md'}>
								{editTelephone && (
									<CrudTelephoneEdit
										referent={filteredReferenta}
										value={editTelephone}
										onSave={() => {
											setEditTelephone(null);
											refresh();
										}}
										onClose={() => setEditTelephone(null)}
										currentUser={currentUser} // Passer l'utilisateur actuel à CrudTelephoneEdit
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeTelephone}
								onChange={() => setRemoveTelephone(null)}
								title="Suppression d'un téléphone"
								message={`Êtes-vous sûr de vouloir supprimer ce téléphone ?`}
								onConfirm={async () => {
									if (removeTelephone) {
										await dispatch(deleteTelephone(removeTelephone.id));
										setRemoveTelephone(null);
										refresh();
									}
								}}
							/>
						</Grid>
					</CardContent>
				)}
			</Card>
		</>
	);
}

// function Part10({ form, onRefresh }: { form: FormObject, onRefresh: () => void }) {
// 	const dispatch = useDispatch();
// 	const pushMessage = useSnackMessage();
// 	const [loading, setLoading] = useState(false);
// 	const [edit, setEdit] = useState<Nullable<HAcquisitionDia>>(null);
// 	const [remove, setRemove] = useState<Nullable<HAcquisitionDia>>(null);
// 	const acquisition = hAcquisition(useSelector((state) => state.acquisition.acquisition));
// 	const triDiaState = useSelector((state) => state.triDia);
// 	const triDia = hTriAcqui(triDiaState.triDia);
// 	const [showContent, setShowContent] = useState(true);
// 	const [currentIndex, setCurrentIndex] = useState(0);
// 	const sortedDias = acquisition?.acquisitionDias?.sort(
// 		(a: HAcquisitionDia, b: HAcquisitionDia) => new Date(b.date).getTime() - new Date(a.date).getTime()
// 	) || [];

// 	const currentDia = sortedDias[currentIndex] || {};
// 	// const currentDia = acquisition?.acquisitionDias?.[currentIndex] || {};

// 	const toggleContent = () => {
// 		setShowContent(!showContent);
// 	};

// 	const handlePrevious = () => {
// 		if (currentIndex > 0) {
// 			setCurrentIndex((prevIndex) => prevIndex - 1);
// 		}
// 	};

// 	const handleNext = () => {
// 		if (acquisition?.acquisitionDias && currentIndex < acquisition.acquisitionDias.length - 1) {
// 			setCurrentIndex((prevIndex) => prevIndex + 1);
// 		}
// 	};

// 	const refreshTriDia = useCallback(() => {
// 		if (currentDia?.id) {
// 			setLoading(true);
// 			dispatch(findTriDia(currentDia.id)).finally(() => {
// 				setLoading(false);
// 			});
// 		}
// 	}, [dispatch, currentDia?.id]);

// 	useEffect(() => {
// 		refreshTriDia();
// 	}, [refreshTriDia]);

// 	return (
// 		<>
// 			<Card elevation={4} className="mb-1">
// 				<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
// 					<CardHeader
// 						className="py-1 mb-1"
// 						title="Préemption"
// 						titleTypographyProps={{ className: 'u-t-size-19' }}
// 						action={
// 							<IconButton onClick={toggleContent} style={{ color: 'white' }}>
// 								{showContent ? <ExpandLessIcon /> : <AddIcon />}
// 							</IconButton>
// 						}
// 					/>
// 				</div>
// 				{showContent && (
// 					<CardContent className="pt-0">
// 						{sortedDias.length ? (
// 							<>
// 								<Box
// 									sx={{
// 										border: '1px solid #ccc',
// 										borderRadius: '8px',
// 										padding: '16px',
// 										marginBottom: '16px',
// 										boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Ajoute de l'ombre
// 									}}
// 								>
// 									<Box
// 										sx={{
// 											display: 'flex',
// 											justifyContent: 'center',  // Centre les boutons et la numérotation
// 											alignItems: 'center',
// 											marginBottom: '16px',
// 											gap: '16px',
// 										}}
// 									>
// 										<Button
// 											onClick={handlePrevious}
// 											disabled={currentIndex === 0}  // Désactive le bouton quand à la première page
// 										>
// 											PRÉCÉDENT
// 										</Button>
// 										<div style={{ padding: '0 16px', border: '1px solid #ccc', borderRadius: '4px' }}>
// 											{currentIndex + 1} / {sortedDias.length}
// 										</div>
// 										<Button
// 											onClick={handleNext}
// 											disabled={currentIndex === sortedDias.length - 1}  // Désactive le bouton quand à la dernière page
// 										>
// 											SUIVANT
// 										</Button>
// 									</Box>
// 									<Grid container spacing={1}>
// 										<Grid xs={12}>
// 											<Typography className="title">Réception DIA</Typography>
// 										</Grid>
// 										<Grid xs={12} md={3}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Date DIA"
// 													value={currentDia.date ? new Date(currentDia.date).toLocaleDateString('fr-FR') : ''}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>
// 										<Grid xs={12} md={3}>
// 											<Tooltip title="Champ calculé automatiquement" arrow>
// 												<TextField
// 													className="custom-textfield"
// 													label="Date limite préemption"
// 													value={formatDate(triDia?.dateLimitePreemption)}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>
// 										<Grid xs={12} md={6}></Grid>
// 										<Grid xs={12} md={3}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Surface Carrez préemption"
// 													value={currentDia.surfaceCarrez}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													inputProps={{
// 														endAdornment: <InputAdornment position="end">m²</InputAdornment>,
// 													}}
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>
// 										<Grid xs={12} md={3}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Prix préemption m²"
// 													value={currentDia.prixm2}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													inputProps={{
// 														endAdornment: <InputAdornment position="end">€/m²</InputAdornment>,
// 													}}
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>
// 										<Grid xs={12} md={3}>
// 											<Tooltip title="Champ calculé automatiquement" arrow>
// 												<TextField
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													className="custom-textfield"
// 													label="Prix (€)"
// 													value={triDia?.prix ? `${triDia.prix} €` : ''}
// 													readOnly
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>

// 										<Grid xs={12} md={3}></Grid>
// 										<Grid xs={12} md={3}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Date d'envoi de la demande unique visite"
// 													value={currentDia.dateDemandeUniqueVisite ? new Date(currentDia.dateDemandeUniqueVisite).toLocaleDateString('fr-FR') : ''}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>
// 										<Grid xs={12} md={3}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Date d'envoi de la demande de doc à l'EPFIF"
// 													value={currentDia.dateDemandeDocs ? new Date(currentDia.dateDemandeDocs).toLocaleDateString('fr-FR') : ''}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>
// 										<Grid xs={12} md={3}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Date de réception de doc"
// 													value={currentDia.dateReceptionDocs ? new Date(currentDia.dateReceptionDocs).toLocaleDateString('fr-FR') : ''}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>
// 										<Grid xs={12} md={3}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Montant Commission Agence"
// 													value={currentDia.montantCommissionAgence}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													inputProps={{
// 														endAdornment: <InputAdornment position="end">€</InputAdornment>,
// 													}}
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>
// 										<Grid xs={12} md={3}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Commission à la charge de"
// 													value={currentDia.chargeCommission}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>
// 										<Grid xs={12} md={3}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Nom de l'acquéreur"
// 													value={currentDia.nomAcquereur}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>
// 										<Grid xs={12} md={3}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Montant"
// 													value={currentDia.montant}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													inputProps={{
// 														endAdornment: <InputAdornment position="end">€</InputAdornment>,
// 													}}
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>
// 										<Grid xs={12}>
// 											<Typography className="title">Décision EPFIF</Typography>
// 										</Grid>
// 										<Grid xs={12} md={4}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Decision Préemption"
// 													value={currentDia.decisionPreemptionEpf}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>
// 										{/* <Grid xs={12} md={4}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Decision Préemption EPF Adju"
// 													value={currentDia.decisionPreemptionEpfAdju}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid> */}
// 										<Grid xs={12} md={4}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Retour Propriétaire"
// 													value={currentDia.retourProprietaire}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>
// 										<Grid xs={12} md={4}>
// 											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
// 												<TextField
// 													label="Date d'envoi du courrier de préemption / Renonciation"
// 													value={currentDia.dateCourrierPreemption ? new Date(currentDia.dateCourrierPreemption).toLocaleDateString('fr-FR') : ''}
// 													InputLabelProps={{
// 														shrink: true,
// 														style: useStyles.labelReadOnly,
// 													}}
// 													readOnly
// 													fullWidth
// 													style={useStyles.readonlyField}
// 												/>
// 											</Tooltip>
// 										</Grid>

// 									</Grid>
// 									<div className="mt-2 u-t-right" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '16px' }}>
// 										<IconButton onClick={() => setRemove(currentDia)} style={{ marginRight: '16px', fontSize: '2rem' }}>
// 											<IconDelete style={{ fontSize: '2rem' }} />
// 										</IconButton>
// 										<IconButton onClick={() => setEdit(currentDia)} style={{ marginRight: '16px', fontSize: '2rem' }}>
// 											<IconModeEdit style={{ fontSize: '2rem' }} />
// 										</IconButton>
// 										<Button
// 											variant="contained"
// 											size="small"
// 											onClick={() =>
// 												setEdit({
// 													id: '',
// 													acquisitionId: acquisition.id,
// 													date: null,
// 													commentaire: '',
// 													montant: null,
// 													prixm2: null,
// 													surfaceCarrez: null,
// 													montantCommissionAgence: null,
// 													chargeCommission: '',
// 													nomAcquereur: '',
// 													dateDemandeUniqueVisite: null,
// 													dateDemandeDocs: null,
// 													dateReceptionDocs: null,
// 													decisionPreemptionEpf: '',
// 													// decisionPreemptionEpfAdju: '',
// 													dateCourrierPreemption: null,
// 													retourProprietaire: '',
// 												})
// 											}
// 										>
// 											+
// 										</Button>
// 									</div>
// 								</Box>
// 								<Box
// 									sx={{
// 										border: '1px solid #ccc',
// 										borderRadius: '8px',
// 										padding: '16px',
// 										marginBottom: '16px',
// 										boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Ajoute de l'ombre
// 									}}
// 								>
// 									<Grid xs={12} md={12}>
// 										<TextField
// 											multiline
// 											rows={5}
// 											{...form.fields.commentairePreemption}
// 											placeholder="Vos données de commentaire seront traitées conformément à notre politique de confidentialité et aux réglementations de la RGPD."
// 											helperText="Cette zone de commentaires est visible sur les fiches copropriétaires et dans les compte-rendus, diffusés aux instances de copropriété : conformément au RGPD, les informations collectées et renseignées sur les personnes doivent être adéquates, pertinentes et non excessives au regard de la finalité du traitement envisagé. Les commentaires ne doivent donc pas être inappropriés, subjectifs et insultants."
// 										/>
// 									</Grid>
// 								</Box>
// 							</>
// 						) : (
// 							<>
// 								<div className="u-t-center">
// 									<i>Aucune DIA pour l'instant</i>
// 								</div>
// 								<div className="u-t-right" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '16px' }}>
// 									<Button
// 										variant="contained"
// 										size="small"
// 										onClick={() =>
// 											setEdit({
// 												id: '',
// 												acquisitionId: acquisition.id,
// 												date: new Date(),
// 												commentaire: '',
// 												montant: null,
// 												prixm2: null,
// 												surfaceCarrez: null,
// 												montantCommissionAgence: null,
// 												chargeCommission: '',
// 												nomAcquereur: '',
// 												dateDemandeUniqueVisite: null,
// 												dateDemandeDocs: null,
// 												dateReceptionDocs: null,
// 												decisionPreemptionEpf: '',
// 												// decisionPreemptionEpfAdju: '',
// 												dateCourrierPreemption: null,
// 												retourProprietaire: '',
// 											})
// 										}
// 									>
// 										+
// 									</Button>
// 								</div>
// 							</>
// 						)}
// 						<Dialog open={edit !== null} onClose={() => setEdit(null)} maxWidth={'md'}>
// 							<CrudAcquisitionDiaEdit
// 								acquisition={acquisition}
// 								diaAcqui={edit}
// 								onSave={() => {
// 									setEdit(null);
// 									onRefresh();
// 								}}
// 								onClose={() => setEdit(null)}
// 							/>
// 						</Dialog>
// 						<Confirm
// 							value={!!remove}
// 							onChange={() => setRemove(null)}
// 							title="Confirmation de suppression"
// 							message={`Voulez-vous vraiment supprimer la DIA ${currentIndex + 1} ?`}
// 							onConfirm={async () => {
// 								await dispatch(deleteAcquisitionDia(remove));
// 								setRemove(null);
// 								onRefresh();
// 							}}
// 						/>
// 					</CardContent>
// 				)}
// 			</Card>
// 		</>
// 	);
// }
function Part10({ form, onRefresh }: { form: FormObject; onRefresh: () => void }) {
	const dispatch = useDispatch();
	const acquisition = hAcquisition(useSelector((state) => state.acquisition.acquisition));
	const triDiaState = useSelector((state) => state.triDia);
	const triDia = triDiaState.triDia;
	const [showContent, setShowContent] = useState(true);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [edit, setEdit] = useState<Nullable<HAcquisitionDia>>(null);
	const [remove, setRemove] = useState<Nullable<HAcquisitionDia>>(null);

	const sortedDias = acquisition?.acquisitionDias?.sort(
		(a: HAcquisitionDia, b: HAcquisitionDia) => new Date(b.date).getTime() - new Date(a.date).getTime()
	) || [];

	const currentDia = sortedDias[currentIndex] || {};

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	const handlePrevious = () => {
		if (currentIndex > 0) {
			setCurrentIndex((prevIndex) => prevIndex - 1);
		}
	};

	const handleNext = () => {
		if (acquisition?.acquisitionDias && currentIndex < acquisition.acquisitionDias.length - 1) {
			setCurrentIndex((prevIndex) => prevIndex + 1);
		}
	};

	const refreshTriDia = useCallback(() => {
		if (currentDia?.id) {
			dispatch(findTriDia(currentDia.id));
		}
	}, [dispatch, currentDia?.id]);

	useEffect(() => {
		refreshTriDia();
	}, [refreshTriDia]);

	return (
		<>
			<Card elevation={4} className="mb-1">
				<div onClick={toggleContent} style={{ cursor: 'pointer' }}>
					<CardHeader
						className="py-1 mb-1"
						title="Préemption"
						titleTypographyProps={{ className: 'u-t-size-19' }}
						action={
							<IconButton onClick={toggleContent} style={{ color: 'white' }}>
								{showContent ? <ExpandLessIcon /> : <AddIcon />}
							</IconButton>
						}
					/>
				</div>
				{showContent && (
					<CardContent className="pt-0">
						{sortedDias.length ? (
							<>
								<Box
									sx={{
										border: '1px solid #ccc',
										borderRadius: '8px',
										padding: '16px',
										marginBottom: '16px',
										boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											marginBottom: '16px',
											gap: '16px',
										}}
									>
										<Button
											onClick={handlePrevious}
											disabled={currentIndex === 0}
										>
											PRÉCÉDENT
										</Button>
										<div style={{ padding: '0 16px', border: '1px solid #ccc', borderRadius: '4px' }}>
											{currentIndex + 1} / {sortedDias.length}
										</div>
										<Button
											onClick={handleNext}
											disabled={currentIndex === sortedDias.length - 1}
										>
											SUIVANT
										</Button>
									</Box>
									<Grid container spacing={1}>
										<Grid xs={12}>
											<Typography className="title">Réception DIA</Typography>
										</Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Date DIA"
													value={currentDia.date ? new Date(currentDia.date).toLocaleDateString('fr-FR') : ''}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Champ calculé automatiquement" arrow>
												<TextField
													className="custom-textfield"
													label="Date limite préemption"
													value={formatDate(triDia?.dateLimitePreemption)}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={6}></Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Surface Carrez préemption"
													value={currentDia.surfaceCarrez}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													inputProps={{
														endAdornment: <InputAdornment position="end">m²</InputAdornment>,
													}}
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Montant"
													value={currentDia.montant}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													inputProps={{
														endAdornment: <InputAdornment position="end">€</InputAdornment>,
													}}
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Champ calculé automatiquement" arrow>
												<TextField
													label="Montant m²"
													value={triDia?.montantm2 ? `${triDia.montantm2}` : ''}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													inputProps={{
														endAdornment: <InputAdornment position="end">€/m²</InputAdornment>,
													}}
													readOnly
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										{/* <Grid xs={12} md={3}></Grid> */}
										<Grid xs={12} md={3}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Date d'envoi de la demande unique visite"
													value={currentDia.dateDemandeUniqueVisite ? new Date(currentDia.dateDemandeUniqueVisite).toLocaleDateString('fr-FR') : ''}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Date d'envoi de la demande de doc à l'EPFIF"
													value={currentDia.dateDemandeDocs ? new Date(currentDia.dateDemandeDocs).toLocaleDateString('fr-FR') : ''}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Date de réception de doc"
													value={currentDia.dateReceptionDocs ? new Date(currentDia.dateReceptionDocs).toLocaleDateString('fr-FR') : ''}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Date saisine notaire"
													value={currentDia.dateSaisineNotaire ? new Date(currentDia.dateSaisineNotaire).toLocaleDateString('fr-FR') : ''}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Montant Commission Agence"
													value={currentDia.montantCommissionAgence}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													inputProps={{
														endAdornment: <InputAdornment position="end">€</InputAdornment>,
													}}
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Commission à la charge de"
													value={currentDia.chargeCommission}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Nom de l'acquéreur"
													value={currentDia.nomAcquereur}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12}>
											<Typography className="title">Décision EPFIF</Typography>
										</Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Décision de préemption"
													value={currentDia.decisionPreemptionEpf}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Prix préemption"
													value={currentDia.prixPreemption}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													inputProps={{
														endAdornment: <InputAdornment position="end">€</InputAdornment>,
													}}
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={3}>
											<Tooltip title="Champ calculé automatiquement" arrow>
												<TextField
													label="Prix préemption m²"
													value={triDia?.prixPreemptionm2 ? `${triDia.prixPreemptionm2}` : ''}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													inputProps={{
														endAdornment: <InputAdornment position="end">€/m²</InputAdornment>,
													}}
													readOnly
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={3}></Grid>
										<Grid xs={12} md={4}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Retour Propriétaire"
													value={currentDia.retourProprietaire}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={12} md={4}>
											<Tooltip title="Pour modifier, appuyez sur le bouton d'édition" arrow>
												<TextField
													label="Date d'envoi du courrier de préemption / Renonciation"
													value={currentDia.dateCourrierPreemption ? new Date(currentDia.dateCourrierPreemption).toLocaleDateString('fr-FR') : ''}
													InputLabelProps={{
														shrink: true,
														style: useStyles.labelReadOnly,
													}}
													readOnly
													fullWidth
													style={useStyles.readonlyField}
												/>
											</Tooltip>
										</Grid>
									</Grid>
									<div
										className="mt-2 u-t-right"
										style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '16px' }}
									>
										<IconButton onClick={() => setRemove(currentDia)} style={{ marginRight: '16px', fontSize: '2rem' }}>
											<IconDelete style={{ fontSize: '2rem' }} />
										</IconButton>
										<IconButton onClick={() => setEdit(currentDia)} style={{ marginRight: '16px', fontSize: '2rem' }}>
											<IconModeEdit style={{ fontSize: '2rem' }} />
										</IconButton>
										<Button
											variant="contained"
											size="small"
											onClick={() =>
												setEdit({
													id: '',
													acquisitionId: acquisition.id,
													date: null,
													montant: null,
													surfaceCarrez: null,
													montantCommissionAgence: null,
													chargeCommission: '',
													nomAcquereur: '',
													dateDemandeUniqueVisite: null,
													dateDemandeDocs: null,
													dateReceptionDocs: null,
													dateSaisineNotaire: null,
													decisionPreemptionEpf: '',
													prixPreemption: null,
													dateCourrierPreemption: null,
													retourProprietaire: '',
												})
											}
										>
											+
										</Button>
									</div>
								</Box>
								<Box
									sx={{
										border: '1px solid #ccc',
										borderRadius: '8px',
										padding: '16px',
										marginBottom: '16px',
										boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
									}}
								>
									<Grid xs={12} md={12}>
										<TextField
											multiline
											rows={5}
											{...form.fields.commentairePreemption}
											placeholder="Vos données de commentaire seront traitées conformément à notre politique de confidentialité et aux réglementations de la RGPD."
											helperText="Cette zone de commentaires est visible sur les fiches copropriétaires et dans les compte-rendus, diffusés aux instances de copropriété : conformément au RGPD, les informations collectées et renseignées sur les personnes doivent être adéquates, pertinentes et non excessives au regard de la finalité du traitement envisagé. Les commentaires ne doivent donc pas être inappropriés, subjectifs et insultants."
										/>
									</Grid>
								</Box>
							</>
						) : (
							<>
								<div className="u-t-center">
									<i>Aucune DIA pour l'instant</i>
								</div>
								<div
									className="u-t-right"
									style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '16px' }}
								>
									<Button
										variant="contained"
										size="small"
										onClick={() =>
											setEdit({
												id: '',
												acquisitionId: acquisition.id,
												date: new Date(),
												montant: null,
												surfaceCarrez: null,
												montantCommissionAgence: null,
												chargeCommission: '',
												nomAcquereur: '',
												dateDemandeUniqueVisite: null,
												dateDemandeDocs: null,
												dateReceptionDocs: null,
												dateSaisineNotaire: null,
												decisionPreemptionEpf: '',
												prixPreemption: null,
												dateCourrierPreemption: null,
												retourProprietaire: '',
											})
										}
									>
										+
									</Button>
								</div>
							</>
						)}
						<Dialog open={edit !== null} onClose={() => setEdit(null)} maxWidth={'md'}>
							<CrudAcquisitionDiaEdit
								acquisition={acquisition}
								diaAcqui={edit}
								onSave={() => {
									setEdit(null);
									onRefresh();
								}}
								onClose={() => setEdit(null)}
							/>
						</Dialog>
						<Confirm
							value={!!remove}
							onChange={() => setRemove(null)}
							title="Confirmation de suppression"
							message={`Voulez-vous vraiment supprimer la DIA ${currentIndex + 1} ?`}
							onConfirm={async () => {
								await dispatch(deleteAcquisitionDia(remove));
								setRemove(null);
								onRefresh();
							}}
						/>
					</CardContent>
				)}
			</Card>
		</>
	);
}




export default function CrudAcquisitionEdit({ onClose, onUpdate, onPrev, onNext, item }: CrudAcquisitionEditProps) {
	const dispatch = useDispatch();
	const validators = useValidators();
	const pushMessage = useSnackMessage();
	const triAcquiState = useSelector((state) => state.triAcqui);
	const triAcqui = hTriAcqui(triAcquiState.triAcqui);
	const acquisition = hAcquisition(useSelector((state) => state.acquisition.acquisition));
	const triLot = hTriLot(useSelector((state) => state.triLot.triLot));
	const acquisitionVisite = hAcquisitionVisite(useSelector((state) => state.acquisitionVisite.acquisitionVisite));
	const acquisitionDia = hAcquisitionDia(useSelector((state) => state.acquisitionDia.acquisitionDia));
	const [referentId, setReferentId] = useState<string>('');
	const [dateCourrierPreemption, setDateCourrierPreemption] = useState<Nullable<Date>>(null);
	const [retourProprietaire, setRetourProprietaire] = useState<string>('');
	const [dateLimitePreemption, setDateLimitePreemption] = useState<Nullable<Date>>(null);
	const [loading, setLoading] = useState(false);
	const [proprietaire, setProprietaire] = useState<Nullable<HProprietaire>>(null);
	const [lots, setLots] = useState<Nullable<TriLot[]>>([]);
	const [triLots, setTriLots] = useState<Nullable<TriLot[]>>([]);
	const [type, setType] = useState<string>('');
	const [typeVente, setTypeVente] = useState<string>('');
	const [situationLhi, setSituationLhi] = useState<string>('');
	const [dateOffreEpf, setDateOffreEpf] = useState<Nullable<Date>>(null);
	// const [dateSaisineNotaire, setDateSaisineNotaire] = useState<Nullable<Date>>(null);
	const [dateSignature, setDateSignature] = useState<Nullable<Date>>(null);
	const [structure, setStructure] = useState<Nullable<string>>(null);
	const [responsable, setResponsable] = useState<Nullable<string>>(null);
	const [contexteAcquisition, setContexteAcquisition] = useState<string>('');
	// const [decisionPreemptionEpfAdju, setDecisionPreemptionEpfAdju] = useState<string>('');
	const [montantMap, setMontantMap] = useState<Nullable<number>>(null);
	const [prixm2Map, setPrixm2Map] = useState<Nullable<number>>(null);
	const [nomPoursuivant, setNomPoursuivant] = useState<string>('');
	const [nomAvocatPoursuivant, setNomAvocatPoursuivant] = useState<string>('');
	// const [dateRapportVille, setDateRapportVille] = useState<Nullable<Date>>(null);
	const [dateAudienceAdju, setDateAudienceAdju] = useState<Nullable<Date>>(null);
	const [nomDernierEncherisseur, setNomDernierEncherisseur] = useState<string>('');
	const [recuperationCcvPvd, setRecuperationCcvPvd] = useState<Nullable<string>>(null);
	const [montantDerniereEnchere, setMontantDerniereEnchere] = useState<Nullable<number>>(null);
	const [avocatDernierEncherisseur, setAvocatDernierEncherisseur] = useState<string>('');
	const [dateEnvoiFicheAcqui, setDateEnvoiFicheAcqui] = useState<Nullable<Date>>(null);
	const [dateJugementAdju, setDateJugementAdju] = useState<Nullable<Date>>(null);
	const [aRecontacter, setaRecontacter] = useState<Nullable<string>>(null);
	const [refusPrix, setRefusPrix] = useState<Nullable<string>>(null);
	const [dossierBloque, setDossierBloque] = useState<Nullable<string>>(null);
	const [commentaireAmiable, setCommentaireAmiable] = useState<string>('');
	const [commentairePreemption, setCommentairePreemption] = useState<string>('');
	const [commentaireAdju, setCommentaireAdju] = useState<string>('');
	const [dateAcquisition, setDateAcquisition] = useState<Nullable<Date>>(null);
	const [prixm2Acquisition, setPrixm2Acquisition] = useState<Nullable<number>>(null);
	const [surfaceCarrezAcquisition, setSurfaceCarrezAcquisition] = useState<Nullable<number>>(null);
	const [surfaceDeclaree, setSurfaceDeclaree] = useState<Nullable<number>>(null);
	// const [dateDemandeDs, setDateDemandeDs] = useState<Nullable<Date>>(null);
	const [contexteVisite, setContexteVisite] = useState<string>('');
	const [dateOffreOrale, setDateOffreOrale] = useState<Nullable<Date>>(null);
	const [dateOffreEcrite, setDateOffreEcrite] = useState<Nullable<Date>>(null);
	const [dateMaj, setDateMaj] = useState<Nullable<Date>>(null);
	const [origineSignalement, setOrigineSignalement] = useState<string>('');
	const [dateSignalement, setDateSignalement] = useState<Nullable<Date>>(null);

	const [editVisites, setEditVisites] = useState(false);
	const [editDias, setEditDias] = useState(false);
	const [selectedLots, setSelectedLots] = useState(acquisition?.lots || []);
	const [isLotSelected, setIsLotSelected] = useState(false);

	// Handle the change from Part5
	const handleSurfaceDeclareeChange = (value: number) => {
		setSurfaceDeclaree(value);
	};
	const handleSurfaceCarrezAcquisitionChange = (value: number) => {
		setSurfaceCarrezAcquisition(value);
	}

	const handleTypeVente = (value: string) => {
		setTypeVente(value);
	}

	const handleSelectLot = (newLot: Lot) => {
		setSelectedLots((prevLots: Lot[]) => [...prevLots, newLot]);
	};

	// Met à jour l'état basé sur la sélection de lot
	useEffectOnChange(() => {
		(async () => {
			setLoading(true);
			try {
				if (item?.id) {
					await dispatch(findAcquisition(item.id));
				} else {
					dispatch(setAcquisition({} as any));
				}
				setProprietaire(item?.proprietaireObj || null);
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue', 'error');
			}
			setLoading(false);
		})();
	}, [item?.id]);


	useEffectOnChange(() => {
		if (acquisition) {
			setReferentId(acquisition.referentId || '');
			setLots(acquisition.lots || []);
			setTriLots(acquisition.triLots || []);
			setType(acquisition.type || '');
			setTypeVente(acquisition.typeVente || '');
			setSituationLhi(acquisition.situationLhi || '');
			setDateOffreEpf(acquisition.dateOffreEpf || null);
			// setDateSaisineNotaire(acquisition.dateSaisineNotaire || null);
			setDateSignature(acquisition.dateSignature || null);
			setStructure(acquisition.structure || null);
			setResponsable(acquisition.responsable || null);
			setContexteAcquisition(acquisition.contexteAcquisition || '');
			// setDecisionPreemptionEpfAdju(acquisition.decisionPreemptionEpfAdju || '');
			setDateAudienceAdju(acquisition.dateAudienceAdju || null);
			setMontantMap(acquisition.montantMap || null);
			setPrixm2Map(acquisition.prixm2Map || null);
			setNomPoursuivant(acquisition.nomPoursuivant || '');
			setNomAvocatPoursuivant(acquisition.nomAvocatPoursuivant || '');
			// setDateRapportVille(acquisition.dateRapportVille || null);
			setNomDernierEncherisseur(acquisition.nomDernierEncherisseur || '');
			setRecuperationCcvPvd(typeof acquisition.recuperationCcvPvd === 'boolean' ? (acquisition.recuperationCcvPvd ? 'Oui' : 'Non') : null);
			setMontantDerniereEnchere(acquisition.montantDerniereEnchere || null);
			setAvocatDernierEncherisseur(acquisition.avocatDernierEncherisseur || '');
			setDateEnvoiFicheAcqui(acquisition.dateEnvoiFicheAcqui || null);
			setDateJugementAdju(acquisition.dateJugementAdju || null);
			setaRecontacter(typeof acquisition.aRecontacter === 'boolean' ? (acquisition.aRecontacter ? 'Oui' : 'Non') : null);
			setRefusPrix(typeof acquisition.refusPrix === 'boolean' ? (acquisition.refusPrix ? 'Oui' : 'Non') : null);
			setDossierBloque(typeof acquisition.dossierBloque === 'boolean' ? (acquisition.dossierBloque ? 'Oui' : 'Non') : null);
			setCommentaireAmiable(acquisition.commentaireAmiable || '');
			setCommentairePreemption(acquisition.commentairePreemption || '');
			setCommentaireAdju(acquisition.commentaireAdju || '');
			setDateAcquisition(acquisition.dateAcquisition || null);
			setPrixm2Acquisition(acquisition.prixm2Acquisition || null);
			setSurfaceCarrezAcquisition(acquisition.surfaceCarrezAcquisition || null);
			// setDateDemandeDs(acquisition.dateDemandeDs || null);
			setSurfaceDeclaree(acquisition.surfaceDeclaree || null);
			setEditVisites(acquisition.editVisites || false);
			setEditDias(acquisition.editDias || false);
			setContexteVisite(acquisition.contexteVisite || '');
			setDateOffreOrale(acquisition.dateOffreOrale || null);
			setDateOffreEcrite(acquisition.dateOffreEcrite || null);
			setDateMaj(acquisition.dateMaj || null);
			setOrigineSignalement(acquisition.origineSignalement || '');
			setDateSignalement(acquisition.dateSignalement || null);
		}
	}, [acquisition?.id]);


	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				await save();
				onUpdate();
				onClose();
			});
		},
		fields: {
			proprietaire: {
				...model(proprietaire, setProprietaire),
				rules: [
					validators.required({
						message: 'Le propriétaire est requis',
					}),
				],
			},
			lots: {
				...model(lots, setLots),
				rules: [
					validators.required({
						message: 'Veuillez sélectionner au moins 1 lot',
					}),
				],
			},
			triLots: {
				...model(triLots, setTriLots),
				rules: [
					validators.required({
						message: 'Veuillez sélectionner au moins 1 lot',
					}),
				],
			},
			type: {
				...model(type, setType),
				label: 'Type',
				items: ['', 'Amiable', 'Préemption', 'Adjudication', 'Expropriation'],
			},
			typeVente: {
				...model(typeVente, setTypeVente),
				label: 'Vente',
				items: ['', 'Libre', 'Occupé', 'Alternative'],
			},
			situationLhi: {
				...model(situationLhi, setSituationLhi),
				label: 'Situations LHI',
				items: ['', 'Insalubrité', 'Suroccupation', 'Surpeuplement', 'Logement à la découpe', 'Autre'],
			},
			dateOffreEpf: {
				...model(dateOffreEpf, setDateOffreEpf),
				label: 'Envoi offre à l\'EPFIF:',
			},
			// dateSaisineNotaire: {
			// 	...model(dateSaisineNotaire, setDateSaisineNotaire),
			// 	label: 'Date saisine notaire',
			// },
			dateSignature: {
				...model(dateSignature, setDateSignature),
				label: 'Date signature',
			},
			structure: {
				...model(structure, setStructure),
				label: 'Structure en charge du suivi',
				items: ['', 'GPA', 'SEGAT'],
			},
			responsable: {
				...model(responsable, setResponsable),
				label: 'Personne en charge du suivi',
			},
			contexteAcquisition: {
				...model(contexteAcquisition, setContexteAcquisition),
				label: 'Contexte Acquisition',
				items: ['', 'Amiable', 'Préemption', 'Adjudication'],
			},
			// decisionPreemptionEpfAdju: {
			// 	...model(decisionPreemptionEpfAdju, setDecisionPreemptionEpfAdju),
			// 	label: 'Décision de préemption EPFIF',
			// 	items: ['', 'Oui', 'Non'],
			// },
			montantMap: {
				...model(montantMap, setMontantMap),
				label: 'Montant mise à prix',
				type: 'number',
			},
			prixm2Map: {
				...model(prixm2Map, setPrixm2Map),
				label: 'Prix m2',
				type: 'number',
			},
			nomPoursuivant: {
				...model(nomPoursuivant, setNomPoursuivant),
				label: 'Nom du poursuivant',
			},
			nomAvocatPoursuivant: {
				...model(nomAvocatPoursuivant, setNomAvocatPoursuivant),
				label: 'Nom de l’avocat du créancier poursuivant',
			},
			// dateRapportVille: {
			// 	...model(dateRapportVille, setDateRapportVille),
			// 	label: 'Date rapport ville',
			// },
			dateAudienceAdju: {
				...model(dateAudienceAdju, setDateAudienceAdju),
				label: 'Date audience adjudication',
			},
			nomDernierEncherisseur: {
				...model(nomDernierEncherisseur, setNomDernierEncherisseur),
				label: 'Nom du dernier enchérisseur',
			},
			recuperationCcvPvd: {
				...model(recuperationCcvPvd, setRecuperationCcvPvd),
				label: 'Recuperation CCV/PVD',
				items: [
					{ value: null, text: <i></i> },
					{ value: 'Oui', text: 'Oui' },
					{ value: 'Non', text: 'Non' },
				],
			},
			montantDerniereEnchere: {
				...model(montantDerniereEnchere, setMontantDerniereEnchere),
				label: 'Montant derniere enchere',
				type: 'number',
			},
			avocatDernierEncherisseur: {
				...model(avocatDernierEncherisseur, setAvocatDernierEncherisseur),
				label: 'Avocat du dernier enchérisseur',
			},
			dateEnvoiFicheAcqui: {
				...model(dateEnvoiFicheAcqui, setDateEnvoiFicheAcqui),
				label: 'Date envoi fiche acquisition EPFIF',
			},
			dateJugementAdju: {
				...model(dateJugementAdju, setDateJugementAdju),
				label: 'Date jugement adjudication',
			},
			dossierBloque: {
				...model(dossierBloque, setDossierBloque),
				label: 'Dossier bloqué',
				items: [
					{ value: null, text: <i></i> },
					{ value: 'Oui', text: 'Oui' },
					{ value: 'Non', text: 'Non' },
				],
			},
			aRecontacter: {
				...model(aRecontacter, setaRecontacter),
				label: 'À recontacter',
				items: [
					{ value: null, text: <i></i> },
					{ value: 'Oui', text: 'Oui' },
					{ value: 'Non', text: 'Non' },
				],
			},
			refusPrix: {
				...model(refusPrix, setRefusPrix),
				label: 'Refus prix',
				items: [
					{ value: null, text: <i></i> },
					{ value: 'Oui', text: 'Oui' },
					{ value: 'Non', text: 'Non' },
				],
			},
			commentaireAmiable: {
				...model(commentaireAmiable, setCommentaireAmiable),
				label: 'Commentaire amiable',
				multiline: true,
				rows: 5,
			},
			commentairePreemption: {
				...model(commentairePreemption, setCommentairePreemption),
				label: 'Commentaire préemption',
				multiline: true,
				rows: 5,
			},
			commentaireAdju: {
				...model(commentaireAdju, setCommentaireAdju),
				label: 'Commentaire adjudication',
				multiline: true,
				rows: 5,
			},
			dateAcquisition: {
				...model(dateAcquisition, setDateAcquisition),
				label: 'Date acquisition',
			},
			prixm2Acquisition: {
				...model(prixm2Acquisition, setPrixm2Acquisition),
				label: 'Prix m² acquisition',
				type: 'number',
			},
			surfaceCarrezAcquisition: {
				...model(surfaceCarrezAcquisition, setSurfaceCarrezAcquisition),
				label: 'Surface Carrez acquisition',
				type: 'number',
			},
			// dateDemandeDs: {
			// 	...model(dateDemandeDs, setDateDemandeDs),
			// 	label: 'Date demande DS',
			// },
			surfaceDeclaree: {
				...model(surfaceDeclaree, setSurfaceDeclaree),
				label: 'Surface déclarée',
				type: 'number',
			},
			editVisites: {
				...model(editVisites, setEditVisites),
				label: 'Edit visites',
			},
			editDias: {
				...model(editDias, setEditDias),
				label: 'Edit dias',
			},
			contexteVisite: {
				...model(contexteVisite, setContexteVisite),
				label: 'Contexte visite',
				multiline: true,
				rows: 5,
			},
			dateOffreOrale: {
				...model(dateOffreOrale, setDateOffreOrale),
				label: 'Date offre orale',
			},
			dateOffreEcrite: {
				...model(dateOffreEcrite, setDateOffreEcrite),
				label: 'Date offre écrite',
			},
			dateMaj: {
				...model(dateMaj, setDateMaj),
				label: 'Date mise à jour Acquisition',
			},
			origineSignalement: {
				...model(origineSignalement, setOrigineSignalement),
				label: 'Origine signalement',
			},
			dateSignalement: {
				...model(dateSignalement, setDateSignalement),
				label: 'Date signalement',
			},
		}
	});

	const datetimeMaj = new Date();
	datetimeMaj.setMinutes(datetimeMaj.getMinutes() - datetimeMaj.getTimezoneOffset());
	const formattedDate = datetimeMaj.toISOString();

	const save = async () => {
		if (!lots?.length) {
			form.addMainError('Veuillez sélectionner au moins un lot');
			throw new Error('Veuillez sélectionner au moins un lot');
		}

		let referentId = form.fields.lots.value[0]?.proprietes?.[0]?.proprietaire?.id;

		// Création de l'objet `newRecord` pour le put (maj des acquisitions)
		let newRecordPost: Partial<Acquisition> = {
			referentId,
			type,
			typeVente,
			situationLhi,
			dateOffreEpf: dateOffreEpf ? dateOffreEpf.toISOString() : null,
			// dateSaisineNotaire: dateSaisineNotaire ? dateSaisineNotaire.toISOString() : null,
			dateSignature: dateSignature ? dateSignature.toISOString() : null,
			structure,
			responsable,
			contexteAcquisition,
			// decisionPreemptionEpfAdju,
			dateAudienceAdju: dateAudienceAdju ? dateAudienceAdju.toISOString() : null,
			montantMap: montantMap ? parseFloat(montantMap.toString()) : null,
			prixm2Map: prixm2Map ? parseFloat(prixm2Map.toString()) : null,
			nomPoursuivant,
			nomAvocatPoursuivant,
			// dateRapportVille: dateRapportVille ? dateRapportVille.toISOString() : null,
			nomDernierEncherisseur,
			recuperationCcvPvd: recuperationCcvPvd === null ? null : (recuperationCcvPvd === 'Oui' ? true : false),
			avocatDernierEncherisseur,
			dateJugementAdju: dateJugementAdju ? dateJugementAdju.toISOString() : null,
			montantDerniereEnchere: montantDerniereEnchere ? parseFloat(montantDerniereEnchere.toString()) : null,
			aRecontacter: aRecontacter === null ? null : (aRecontacter === 'Oui' ? true : false),
			refusPrix: refusPrix === null ? null : (refusPrix === 'Oui' ? true : false),
			dossierBloque: dossierBloque === null ? null : (dossierBloque === 'Oui' ? true : false),
			commentaireAmiable,
			commentairePreemption,
			commentaireAdju,
			dateAcquisition: dateAcquisition ? dateAcquisition.toISOString() : null,
			prixm2Acquisition: prixm2Acquisition ? parseFloat(prixm2Acquisition.toString()) : null,
			surfaceCarrezAcquisition: surfaceCarrezAcquisition ? parseFloat(surfaceCarrezAcquisition.toString()) : null,
			surfaceDeclaree: surfaceDeclaree ? parseFloat(surfaceDeclaree.toString()) : null,
			// dateDemandeDs: dateDemandeDs ? dateDemandeDs.toISOString() : null,
			editVisites,
			editDias,
			contexteVisite,
			dateOffreOrale: dateOffreOrale ? dateOffreOrale.toISOString() : null,
			dateOffreEcrite: dateOffreEcrite ? dateOffreEcrite.toISOString() : null,
			dateMaj: formattedDate,
			origineSignalement,
			dateSignalement: dateSignalement ? dateSignalement.toISOString() : null,
			acquisitionLots: lots.map((lot) => ({
				lot: lot.id,
			})),
		} as any;
		// Création de l'objet `newRecord` pour le put (maj des acquisitions)
		let newRecordPut: Partial<Acquisition> = {
			//referentId,
			type,
			typeVente,
			situationLhi,
			dateOffreEpf: dateOffreEpf ? dateOffreEpf.toISOString() : null,
			// dateSaisineNotaire: dateSaisineNotaire ? dateSaisineNotaire.toISOString() : null,
			dateSignature: dateSignature ? dateSignature.toISOString() : null,
			structure,
			responsable,
			contexteAcquisition,
			// decisionPreemptionEpfAdju,
			dateAudienceAdju: dateAudienceAdju ? dateAudienceAdju.toISOString() : null,
			montantMap: montantMap ? parseFloat(montantMap.toString()) : null,
			prixm2Map: prixm2Map ? parseFloat(prixm2Map.toString()) : null,
			nomPoursuivant,
			nomAvocatPoursuivant,
			// dateRapportVille: dateRapportVille ? dateRapportVille.toISOString() : null,
			nomDernierEncherisseur,
			recuperationCcvPvd: recuperationCcvPvd === null ? null : (recuperationCcvPvd === 'Oui' ? true : false),
			avocatDernierEncherisseur,
			dateJugementAdju: dateJugementAdju ? dateJugementAdju.toISOString() : null,
			montantDerniereEnchere: montantDerniereEnchere ? parseFloat(montantDerniereEnchere.toString()) : null,
			aRecontacter: aRecontacter === null ? null : (aRecontacter === 'Oui' ? true : false),
			refusPrix: refusPrix === null ? null : (refusPrix === 'Oui' ? true : false),
			dossierBloque: dossierBloque === null ? null : (dossierBloque === 'Oui' ? true : false),
			commentaireAmiable,
			commentairePreemption,
			commentaireAdju,
			dateAcquisition: dateAcquisition ? dateAcquisition.toISOString() : null,
			prixm2Acquisition: prixm2Acquisition ? parseFloat(prixm2Acquisition.toString()) : null,
			surfaceCarrezAcquisition: surfaceCarrezAcquisition ? parseFloat(surfaceCarrezAcquisition.toString()) : null,
			surfaceDeclaree: surfaceDeclaree ? parseFloat(surfaceDeclaree.toString()) : null,
			// dateDemandeDs: dateDemandeDs ? dateDemandeDs.toISOString() : null,
			editVisites,
			editDias,
			contexteVisite,
			dateOffreOrale: dateOffreOrale ? dateOffreOrale.toISOString() : null,
			dateOffreEcrite: dateOffreEcrite ? dateOffreEcrite.toISOString() : null,
			dateMaj: formattedDate,
			origineSignalement,
			dateSignalement: dateSignalement ? dateSignalement.toISOString() : null,
			acquisitionLots: lots.map((lot) => ({
				lot: lot.id,
			})),
		} as any;

		if (acquisition?.id) {
			newRecordPut.id = acquisition.id;
			await dispatch(putAcquisition(newRecordPut));
		} else {
			const createdAcquisition = await dispatch(postAcquisition(newRecordPost));
			newRecordPost.id = createdAcquisition.id;
		}
		pushMessage(`Acquisition mise à jour.`);
	};

	const handlePrev = async () => {
		setLoading(true);
		try {
			await save();
			await onPrev();
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur est survenue.', 'error');
		}
		setLoading(false);
	};

	const handleNext = async () => {
		setLoading(true);
		try {
			await save();
			await onNext();
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur est survenue.', 'error');
		}
		setLoading(false);
	};

	const handleSaveAndCloseAndRefresh = async () => {
		setLoading(true);
		try {
			await save();
			window.location.reload(); // Rafraîchir la page après l'enregistrement
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur est survenue lors de la sauvegarde.', 'error');
		}
		setLoading(false);
	};

	const [isFormSaved, setIsFormSaved] = useState(false);
	const [isFormVisible, setIsFormVisible] = useState(true); // État pour contrôler la visibilité du formulaire

	const isFirstStep = !isFormSaved || !isLotSelected;

	// Handler pour simuler l'enregistrement du formulaire
	// Fonction pour enregistrer les données
	const handleSave = async () => {
		setIsFormVisible(false); // Ferme le formulaire avant la sauvegarde
		// Simuler la sauvegarde des données
		await new Promise(resolve => setTimeout(resolve, 1000)); // Simulation d'un appel réseau
		setIsFormVisible(true); // Rouvre le formulaire après la sauvegarde
	};
	// Fonction pour réinitialiser l'état du formulaire
	const resetFormState = () => {
		setIsLotSelected(false);
		setIsFormSaved(false);
		// Réinitialiser d'autres états si nécessaire
	};

	// Gestion de la fermeture avec réinitialisation
	const handleClose = () => {
		resetFormState();
		onClose();  // Appeler la prop onClose pour fermer le formulaire
	};

	// Fonction pour enregistrer et rouvrir le formulaire
	const handleSaveAndCloseAndReopen = async () => {
		setLoading(true);
		try {
			await save();
			pushMessage('Données enregistrées avec succès.', undefined);  // Afficher un message de succès
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur est survenue lors de la sauvegarde.', 'error');
		}
		setLoading(false);

		// Réinitialiser les états ici si nécessaire
		setIsLotSelected(false); // Exemple, ajustez selon votre logique d'application
		setIsFormSaved(false);   // Assurez-vous que cela correspond à votre besoin

		// Gestion de la visibilité du formulaire
		setIsFormVisible(false);
		setTimeout(() => {
			setIsFormVisible(true);
		}, 100);
	};

	// Ajoutez un useEffect pour surveiller les changements nécessaires
	useEffect(() => {
		// Assurez-vous que le formulaire s'affiche correctement en fonction des conditions
		if (acquisition && acquisition.lots.length > 0) {
			setIsLotSelected(true);
			setIsFormSaved(true);  // Ajustez selon la logique de votre application
		}
	}, [acquisition]);
	useEffect(() => {
		setIsLotSelected(form.fields.lots.value.length > 0);
	}, [form.fields.lots.value]);

	const refresh = useCallback(() => {
		(async () => {
			setLoading(true);
			try {
				if (item && item.id) { // Add a null check for 'item'
					await dispatch(findAcquisitionById(item.id.toString())); // Convert item.id to a string
				}
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue', 'error');
			}
			setLoading(false);
		})();
	}, [item?.id])

	useEffectOnChange(() => {
		refresh();
	}, [refresh]);

	return (
		<div className="c-Crud-Acquisition-Edit">
			{isFormVisible && (
				<Form form={form}>
					{acquisition ? (  // Opening of the conditional rendering block based on acquisition

						<Card>
							<CardContent style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
								{isLotSelected && isFormSaved ? (
									<>
										{triAcqui && (
											<Card className="p-1 mb-1">
												<Grid container>
													<Grid xs={12} md={4} lg={5}>
														<b>{triAcqui.nomProprietaire || '-'}
															<br />
															{triAcqui.syndicat} - {triAcqui.nombreLots} lot(s) sélectionné(s)
															<br />
															Statut du dossier : {triAcqui.statutDossier || '-'}</b>
													</Grid>
												</Grid>
											</Card>
										)}

										<FormMainErrors form={form} />
										{/* Lots concernés par l'acquisition */}
										<Part1 form={form} />
										{/* Visite */}
										<Part4 onRefresh={refresh} />
										{/* Suivi du dossier */}
										<Part2 form={form} onTypeVenteChange={handleTypeVente} />
										{/* Propriétaire */}
										<Part3 form={form} />
										{/* Vente amiable */}
										<Part5 form={form} onSurfaceDeclareeChange={handleSurfaceDeclareeChange} />
										{/* Préemption */}
										{/* <Part6 form={form} onSurfaceCarrezPreemptionChange={handleSurfaceCarrezPreemptionChange} onPrixm2PreemptionChange={handlePrixm2PreemptionChange} /> */}
										{/* DIA */}
										<Part10 form={form} onRefresh={refresh} />
										{/* Adjudication */}
										<Part7 form={form} />
										{/* Acquisition */}
										<Part8 form={form} />
										{/* Coordonées 2.0 */}
										<Part9 onRefresh={refresh} />
									</>
								) : (
									<>
										<Alert severity="warning">Pour créer une acquisition, veuillez sélectionner les lots concernés et enregistrer.</Alert>
										<Part1 form={form} />
									</>
								)}
							</CardContent>
							<CardContent style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
								<CardActions className="u-d-block">
									{isFirstStep ? (
										<LoadingButton
											style={{ float: 'right' }}
											type="button"
											loading={form.loading || loading}
											onClick={handleSaveAndCloseAndReopen}
											variant="contained"
										>
											Enregistrer
										</LoadingButton>
									) : (
										<>
											<Button onClick={onClose}>Annuler et Fermer</Button>
											<LoadingButton style={{ float: 'right' }} type="button" loading={form.loading || loading} onClick={handleNext}>
												Enregistrer et suivant
											</LoadingButton>
											<LoadingButton
												style={{ float: 'right' }}
												type="button"
												loading={form.loading || loading}
												onClick={handleSaveAndCloseAndRefresh}
												variant="contained"
											>
												Enregistrer et fermer
											</LoadingButton>
											<LoadingButton style={{ float: 'right' }} type="button" loading={form.loading || loading} onClick={handlePrev}>
												Enregistrer et précédent
											</LoadingButton>
										</>
									)}
								</CardActions>
							</CardContent>
						</Card>
					) : (
						<div className="p-4 u-t-center">
							<CircularProgress size={150} thickness={2} />
						</div>
					)}
				</Form>
			)}

		</div>
	);
}

function setLoading(arg0: boolean) {
	throw new Error('Function not implemented.');
}

