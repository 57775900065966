import './View.scss';
import { Button, Card, Container, Dialog } from '@mui/material';
import { useConnectedPage, useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { useDispatch, useSelector } from '@/libs/redux';
import { useCallback, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { model } from '@/libs/candy';
import CrudLogModerationFilters from '@/components/crud/logModeration/FIlters/Filters';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import TooltipElm from '@/components/Tooltip/TooltipElm';
import { LogModeration } from '@/models/LogModeration';
import { useTranslation } from 'react-i18next';
import { findLogModerations } from '@/stores/logModeration';
import { Direction } from '@/models';

interface PageLogModerationViewItemProps {
	logModeration: LogModeration;
}

export function PageLogModerationViewItem(
	{
		logModeration,
	}: PageLogModerationViewItemProps
) {

	const createdAtDate = new Date(logModeration.createdAt);
	const { t } = useTranslation();
	const [ showDetails, setShowDetails ] = useState(false);

	if (isNaN(createdAtDate.getTime())) {
	  return (
		<TableRow>
		  <TableCell>Error: Invalid Date</TableCell>
		</TableRow>
	  );
	}

	return (
		<TableRow>
			<TableCell>
				{t(`models.log_moderation_type.values.${logModeration.type}`)}
			</TableCell>
			<TableCell>
				<TooltipElm message={logModeration.user?.firstName ?? ''} />
			</TableCell>
			<TableCell>
				{logModeration.data ? (
					<>
						<Button size="small" onClick={() => setShowDetails(true)}>voir details</Button>
						<Dialog
							open={showDetails}
							onClose={() => setShowDetails(false)}
							maxWidth={'md'}
						>
							<Card>
								<pre
									style={{
										overflow: 'auto',
										maxHeight: 'calc(100vh - 100px)',
									}}
								>
									{JSON.stringify(logModeration.data, null, 4)}
								</pre>
							</Card>
						</Dialog>
					</>
				) : <div className="u-t-center">-</div>}
			</TableCell>
			<TableCell>
				{createdAtDate.toLocaleString()} 
			</TableCell>
		</TableRow>
	);
}

export default function PageLogModerationView() {

	useConnectedPage();
	const dispatch = useDispatch();
	const logs = useSelector(state => state.logModeration.logModerations);
	const pushMessage = useSnackMessage();
	const pageable = usePageable({
		direction: Direction.DESC,
		order: 'createdAt'
	});

	const [ filters, setFilters ] = useState({
		type: [],
		user: '',
		createdAt: {
			start: '',
			end: ''
		}
	});

	const refresh = useCallback(() => {
		(async () => {
			try {
				await dispatch(findLogModerations(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
			} catch (e) {
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [ filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage ])

	useEffectOnChange(() => {
		refresh();
	}, [ refresh ]);



	return (
		
		<Container className="p-log-Moderation">
			<h2>Logs de modération</h2>

			<CrudLogModerationFilters
				{...model(filters, setFilters)}
			/>

			<SortableTable
				className="mt-2"
				heads={[
					{ sort: 'type', name: 'Type' },
					{ sort: 'user', name: 'Utilisateur' },
					{ name: 'données supplémentaires' },
					{ sort: 'createdAt', name: 'Date' }
				]}
				result={logs}
				renderItem={(item) => (
					<PageLogModerationViewItem
						logModeration={item}
					/>
				)}
				pageable={pageable}
			/>
		</Container>
	);
}

PageLogModerationView.getMetadata = () => {
	return {
		title: 'Logs de modération',
		rights: [ 'API_GETC_LOG' ]
	};
};
